<template>
  <div data-app>
    <template>
      <v-container fluid class="pa-0">
        <v-card>
          <v-card-title v-if="detailCategory">
          {{detailCategory.name}}
          </v-card-title>
        </v-card>
      </v-container>
      <v-card>
        <v-card-title>
          <v-btn color="primary" width="100" dark class="mb-2" @click="() => this.dialogWord = true"
          >Thêm mới
          </v-btn
          >
          <v-spacer></v-spacer>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Tìm kiếm"
              single-line
              hide-details
          ></v-text-field>
        </v-card-title>
      <v-data-table
          :headers="headers"
          :items="words"
          :search="search"
          class="elevation-1"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          @page-count="pageCount = $event"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-card-title class="text-h5 pa-0">
              Danh sách từ vựng
              <v-spacer></v-spacer>
            </v-card-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:item.image="{ item }" >
         <img class="image-word" :src="item.image"/>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete</v-icon>
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination
            v-model="page"
            :length="pageCount"
        ></v-pagination>
      </div>
      </v-card>
    </template>
    <!-- DIALOG DELETE  -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline"
        >Bạn có chắc muốn xóa không?
        </v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn width="70" color="blue darken-1" text @click="closeDelete"
          >Thoát
          </v-btn
          >
          <v-btn width="70" color="blue darken-1" text @click="deleteItemConfirm"
          >Xóa
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialogWord"
        max-width="800px"
        min-height="600px"
    >
      <v-card>
        <v-card-title class="text-center justify-center">
          <span class="headline">Thêm từ vựng</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="justify-center">
              <v-col cols="3" class="pa-1 pt-2">
                <h5> Tên từ vựng </h5>
              </v-col>
              <v-col cols="9" class="pa-1">
                <ValidationProvider
                    name="word"
                    rules="required"
                    v-slot="{ errors }"
                >
                  <hnr-input
                      :text="word"
                      v-model="word"
                  ></hnr-input>
                  <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="3" class="pa-1 pt-2">
                <h5> Dịch </h5>
              </v-col>
              <v-col cols="9" class="pa-1">
                <ValidationProvider
                    name="translate"
                    rules="required"
                    v-slot="{ errors }"
                >
                  <hnr-input
                      :text="translate"
                      v-model="translate"
                  ></hnr-input>
                  <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row class="justify-center my-3">
              <v-col cols="3" class="pa-1 mt-5">
                <h5> Tải ảnh </h5>
              </v-col>
              <v-col cols="9" class="pa-1">
                <v-file-input
                    v-model="img"
                    label="Tải file ảnh"
                    @change="onChangeImageUpload1"
                    clearable
                ></v-file-input>
                <div >
                  <img :src="image ? image : ''" class="upload-img"/>
                </div>
              </v-col>
            </v-row>
            <v-row class="align-center justify-center ma-0 pt-5">
              <v-btn color="primary" @click="addWord">Thêm mới</v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialogWord1"
        max-width="800px"
        min-height="600px"
    >
      <v-card>
        <v-card-title class="text-center justify-center">
          <span class="headline">Sửa từ vựng</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="justify-center">
              <v-col cols="3" class="pa-1 pt-2">
                <h5> Tên từ vựng </h5>
              </v-col>
              <v-col cols="9" class="pa-1">
                <ValidationProvider
                    name="word"
                    rules="required"
                    v-slot="{ errors }"
                >
                  <hnr-input
                      :text="detailWord.word"
                      v-model="detailWord.word"
                  ></hnr-input>
                  <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="3" class="pa-1 pt-2">
                <h5> Dịch </h5>
              </v-col>
              <v-col cols="9" class="pa-1">
                <ValidationProvider
                    name="translate"
                    rules="required"
                    v-slot="{ errors }"
                >
                  <hnr-input
                      :text="detailWord.translate"
                      v-model="detailWord.translate"
                  ></hnr-input>
                  <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row class="justify-center my-3">
              <v-col cols="3" class="pa-1 mt-5">
                <h5> Tải ảnh </h5>
              </v-col>
              <v-col cols="9" class="pa-1">
                <v-file-input
                    v-model="img"
                    label="Tải file ảnh"
                    @change="onChangeImageUpload1"
                    clearable
                ></v-file-input>
                <div >
                  <img :src="detailWord.image ? detailWord.image : ''" class="upload-img"/>
                </div>
              </v-col>
            </v-row>
            <v-row class="align-center justify-center ma-0 pt-5">
              <v-btn color="primary" @click="updateWord">Cập nhật</v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {DETAIL_LESSON_WORD_CATEGORY,
  DELETE_LESSON_WORD,
  GET_LIST_WORD,
  SAVE_WORD,
  DETAIL_WORD,
  UPDATE_WORD,
  DELETE_WORD
} from "@/store/lesson-words.module";
import {validationMixin} from "vuelidate";
import HnrInput from "@/components/elements/hnr-input";
import {SET_LOADING} from "@/store/loading.module";
import {ERROR,SUCCESS} from "@/store/alert.module";
import {
  SAVE_UPLOAD_LESSON
} from "@/store/lesson.module";


export default {
  mixins: [validationMixin],
  components: {
    HnrInput,
  },
  data: () => ({
    dialogWord:false,
    dialogWord1:false,
    img:null,
    image:null,
    word:'',
    translate:'',
    search:'',
    page: 1,
    itemsPerPage: 10,
    pageCount: 0,
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id",
      },
      {text: "Tên từ vựng", value: "word", width :"30%"},
      {text: "Dịch", value: "translate", width :"20%"},
      {text: "Ảnh", value: "image", width :"20%"},
      {text: "Actions", value: "actions", sortable: false},
    ],

    dialog: false,
    dialogDelete: false,
  }),

  computed: {
    ...mapGetters({
      words: "listWords",
      detailCategory: "detailLessonWordCategory",
      detailWord: "detailWord",
    }),
    total() {
      return this.$store.getters.lessonCount;
    },
  },
  mounted() {
    this.itemsPerPage = parseInt(10, 10)
    this.getLessons();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Từ vựng", route: this.$route.params.id},
    ]);
    this.$store.dispatch(DETAIL_LESSON_WORD_CATEGORY,this.$route.params.id)
  },
  watch: {},
  methods: {
    onChangeImageUpload1() {
      this.cardImg = URL.createObjectURL(this.img);
      this.imageError = "";
      let formData = new FormData();
      formData.append("upload", this.img);
      this.$store.dispatch(SAVE_UPLOAD_LESSON, formData).then((data) => {
        if (data) {
          this.image = data;
          this.detailWord.image = data;
          this.$toasted.success("Tải lên thành công!", {
            position:'top-right',
            duration:3000
          });
        }
      });
    },
    getLessons() {
      var payload = {
        id: this.$route.params.id,
      };
      this.$store.dispatch(SET_LOADING, true);
      this.$store
          .dispatch(GET_LIST_WORD, this.$route.params.id)
          .then(() => {
            this.$store.dispatch(SET_LOADING, false);
          })
          .catch(() => {
            this.$store.dispatch(SET_LOADING, false);
          });
    },

    addWord() {
      let payload = {
        word:this.word,
        translate:this.translate,
        image:this.image,
        category_id: this.detailCategory.id
      }
      this.$store.dispatch(SAVE_WORD, payload).then(data=> {
        if(data && data.status) {
          this.$store.dispatch(SUCCESS, 'Thêm mới thành công!',{
            root: true,
          });
          this.$store.dispatch(GET_LIST_WORD,this.$route.params.id)
          this.dialogWord = false
        }
      })
    },
    editItem(item) {
      this.dialogWord1 = true;
      this.$store.dispatch(DETAIL_WORD,item.id)
    },
    updateWord() {
      let payload = {
        word:this.detailWord.word,
        translate:this.detailWord.translate,
        image:this.detailWord.image,
        category_id: this.detailCategory.id,
        id: this.detailWord.id
      }
      this.$store.dispatch(UPDATE_WORD, payload).then(data=> {
        if(data && data.status) {
          this.$store.dispatch(SUCCESS, 'Cập nhật thành công!',{
            root: true,
          });
          this.$store.dispatch(GET_LIST_WORD,this.$route.params.id)
          this.dialogWord1 = false
        }
      })
    },
    deleteItem(item) {
      this.editedIndex = this.words.indexOf(item);
      this.editedId = item.id;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.$store.dispatch(DELETE_WORD, this.editedId).then((data) => {
        if (data.status) {
          this.words.splice(this.editedIndex, 1);
        } else {
          if(data.errorCode == 424){
            this.$store.dispatch(ERROR, data.message, {
              root: true,
            });
          }else {
            this.$store.dispatch(ERROR, "Xóa thất bại!", {
              root: true,
            });
          }

        }
      });

      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
    },

    addNewItem() {
      let routeData = this.$router.resolve({
        name: "lesson-word-add",
      });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
.image-word {
  max-width: 200px;
}
.upload-img {
  max-width: 300px;
}
</style>
