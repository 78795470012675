import Vue from "vue";
import Vuex from "vuex";

import alert  from "./alert.module";
import auth from "./auth.module";
import subject from "./subject.module";
import lesson from "./lesson.module";
import user from "./user.module";
import newscategory from "./newscategory.module";
import configfrontend from "./configfrontend.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import loading from './loading.module';
import topic from './topic.module';
import grade from './grade.module';
import image from './image.module';
import ask from './ask.module';
import answer from './answer.module';
import lessonWord from './lesson-words.module';
import notification from './notification.module';
import report from './report.module';
import contact from './contacts.module';
import download from './download.module';
import news from './news.module';
import followAction from './follow-action.module';
import google from './google.module';
import book from './book.module';
import order from './order.module';
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    alert,
    auth,
    subject,
    lesson,
    user,
    news,
    newscategory,
    htmlClass,
    config,
    configfrontend,
    breadcrumbs,
    loading,
    topic,
    grade,
    ask,
    answer,
    notification,
    report,
    image,
    contact,
    download,
    lessonWord,
    followAction,
    google,
    book,
    order
  }
});
