<template>
  <v-container>
    <v-row class="align-center justify-center">
      <v-col cols="12" class="pa-1">
        <v-data-table
            :headers="headers"
            :items="detailLesson.lesson_word_categories"
            class="elevation-1"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            @page-count="pageCount = $event"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-card-title class="text-h5 pa-0">
                Danh sách chủ đề
                <v-spacer></v-spacer>
              </v-card-title>
              <v-spacer></v-spacer>
              <v-btn color="primary" width="100" dark class="mb-2" @click="openDialog"
              >Thêm mới
              </v-btn
              >
            </v-toolbar>
          </template>
          <template v-slot:item.type="{ item }">
            <span v-if="item.type==1">Text</span>
            <span v-if="item.type==2">Image</span>
          </template>
          <template v-slot:item.image="{ item }">
            <img :src="item.image ? item.image :''" class="image-content"/>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="viewItem(item)">
              mdi-eye
            </v-icon>
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete</v-icon>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              Your search for "{{ search }}" found no results.
            </v-alert>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
              v-model="page"
              :length="pageCount"
          ></v-pagination>
          <!--        <v-text-field-->
          <!--            :value="itemsPerPage"-->
          <!--            label="Items per page"-->
          <!--            type="number"-->
          <!--            min="-1"-->
          <!--            max="15"-->
          <!--            @input="itemsPerPage = parseInt($event, 10)"-->
          <!--        ></v-text-field>-->
        </div>
      </v-col>
    </v-row>
    <v-dialog
        v-model="dialog"
        max-width="800px"
        min-height="600px"
    >
      <v-card>
        <v-card-title class="text-center justify-center">
          <span class="headline">Thêm chủ đề</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="justify-center">
              <v-col cols="3" class="pa-1 pt-2">
                <h5> Tên chủ đề </h5>
              </v-col>
              <v-col cols="9" class="pa-1">
                <ValidationProvider
                    name="name"
                    rules="required"
                    v-slot="{ errors }"
                >
                  <hnr-input
                      :text="name"
                      v-model="name"
                  ></hnr-input>
                  <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row class="">
              <v-col cols="3" class="pa-1">
                <span> Loại từ </span>
              </v-col>
              <v-col cols="5" class="pa-1">
                <v-select
                    clearable
                    v-model="type"
                    :items="type_word"
                    outlined
                    :item-text="'name'"
                    :item-value="'id'"
                    placeholder="Loại từ"
                    required
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="3" class="pa-1 pt-2">
                <h5> Nội dung </h5>
              </v-col>
              <v-col cols="9" class="pa-1">
                <ValidationProvider
                    name="name"
                    rules="required"
                    v-slot="{ errors }"
                >
                  <ck-content
                      id="lesson_content"
                      :content="description"
                      :slug="slug"
                      @getData="updateContent"
                      v-model="description"
                  ></ck-content>
                  <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row class="justify-center my-3">
              <v-col cols="3" class="pa-1 mt-5">
                <h5> Tải ảnh </h5>
              </v-col>
              <v-col cols="9" class="pa-1">
                <v-file-input
                    v-model="img"
                    label="Tải file ảnh"
                    @change="onChangeImageUpload"
                    clearable
                ></v-file-input>
                <div >
                  <img :src="image ? image : ''" class="upload-img"/>
                </div>
              </v-col>
            </v-row>
            <v-row class="align-center justify-center ma-0 pt-5">
              <v-btn color="primary" @click="saveNewsCategory">Lưu chủ đề</v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialog1"
        max-width="800px"
        min-height="600px"
    >
      <v-card>
        <v-card-title class="text-center justify-center">
          <span class="headline">Sửa chủ đề</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="justify-center">
              <v-col cols="3" class="pa-1 pt-2">
                <h5> Tên chủ đề </h5>
              </v-col>
              <v-col cols="9" class="pa-1">
                <ValidationProvider
                    name="name"
                    rules="required"
                    v-slot="{ errors }"
                >
                  <hnr-input
                      :text="detailCategory.name"
                      v-model="detailCategory.name"
                  ></hnr-input>
                  <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="pa-1">
                <span> Loại từ </span>
              </v-col>
              <v-col cols="5" class="pa-1">
                <v-select
                    clearable
                    v-model="detailCategory.type"
                    :items="type_word"
                    outlined
                    :item-text="'name'"
                    :item-value="'id'"
                    placeholder="Loại từ"
                    required
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="3" class="pa-1 pt-2">
                <h5> Nội dung </h5>
              </v-col>
              <v-col cols="9" class="pa-1">
                <ValidationProvider
                    name="name"
                    rules="required"
                    v-slot="{ errors }"
                >
                  <ck-content
                      id="lesson_content"
                      :content="detailCategory.description"
                      :slug="slug"
                      @getData="updateContent"
                      v-model="detailCategory.description"
                  ></ck-content>
                  <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row class="justify-center my-3">
              <v-col cols="3" class="pa-1 mt-5">
                <h5> Tải ảnh </h5>
              </v-col>
              <v-col cols="9" class="pa-1">
                <v-file-input
                    v-model="img"
                    label="Tải file ảnh"
                    @change="onChangeImageUpload1"
                    clearable
                ></v-file-input>
                <div >
                  <img :src="detailCategory.image ? detailCategory.image : ''" class="upload-img"/>
                </div>
              </v-col>
            </v-row>
            <v-row class="align-center justify-center ma-0 pt-5">
              <v-btn color="primary" @click="updateNewsCategory">Cập nhật chủ đề</v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline"
        >Bạn có chắc muốn xóa không?
        </v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn width="70" color="blue darken-1" text @click="closeDelete"
          >Thoát
          </v-btn
          >
          <v-btn width="70" color="blue darken-1" text @click="deleteItemConfirm"
          >Xóa
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import HnrInput from '@/components/elements/hnr-input'
import HnrAreaInput from '@/components/elements/hnr-area-input'
import CkContent from "@/components/ck-content";
import {
  SAVE_UPLOAD_LESSON
} from "@/store/lesson.module";
import {
  ADD_CATEGORY_LESSON_WORD,
  DETAIL_LESSON_WORD,
  DETAIL_LESSON_WORD_CATEGORY,
  UPDATE_LESSON_WORD_CATEGORY,
  DELETE_CATEGORY_LESSON_WORD
} from "@/store/lesson-words.module";
import {ERROR} from "@/store/alert.module";
import { mapGetters } from "vuex";
export default {
  props: {
    lesson: Object,
  },
  computed: {
    ...mapGetters({
      detailCategory: "detailLessonWordCategory",
    })
  },
  watch: {
    lesson: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.detailLesson = newVal;
      }
    }
  },
  created() {
    this.itemsPerPage = parseInt(10, 10)
  },
  data() {
    return {
      type_word: [
        {id:1,name:"Text"},
        {id:2,name:"Image"}
      ],
      dialogDelete:false,
      img:{},
      page: 1,
      itemsPerPage: 10,
      pageCount: 0,
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        {text: "Tên chủ đề", value: "name"},
        {text: "Loại", value: "type"},
        {text: "Ảnh", value: "image"},
        {text: "Actions", value: "actions", sortable: false},
      ],
      detailLesson: {},
      dialog:false,
      dialog1:false,
      slug:this.$route.params.slug,
      name:'',
      type:'',
      description:'',
      image:null,
    };
  },
  components: {
    HnrInput,
    CkContent,
    HnrAreaInput
  },
  methods: {
    editItem(item) {
      this.detailCategory.name =''
      this.detailCategory.description =''
      this.detailCategory.image =''
      this.$store.dispatch(DETAIL_LESSON_WORD_CATEGORY, item.id).then(data => {
        if(data && data.status == 1) {
          this.dialog1 = true
        }
      })
    },
    viewItem(item){
      this.$router.push('/lesson-word/list-word/'+item.id)
    },
    updateNewsCategory(){
      let payload = {
        id: this.detailCategory.id,
        name: this.detailCategory.name,
        type: this.detailCategory.type,
        description: this.detailCategory.description,
        image:this.detailCategory.image,
        lesson_word_id: this.detailLesson.id
      }
      this.$store.dispatch(UPDATE_LESSON_WORD_CATEGORY, payload).then((data) => {
        if (data && data.status == 1) {
          this.$store.dispatch(DETAIL_LESSON_WORD, {slug: this.slug})
          this.dialog1 = false
          this.$toasted.success("Cập nhật chủ đề thành công!", {
            position:'top-right',
            duration:3000
          });
        }
      });
    },

    deleteItem(item) {
      this.editedIndex = this.detailLesson.lesson_word_categories.indexOf(item);
      this.editedId = item.id;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.$store.dispatch(DELETE_CATEGORY_LESSON_WORD, this.editedId).then((data) => {
        if (data.status) {
          this.detailLesson.lesson_word_categories.splice(this.editedIndex, 1);
        } else {
          if(data.errorCode == 424){
            this.$store.dispatch(ERROR, data.message, {
              root: true,
            });
          }else {
            this.$store.dispatch(ERROR, "Xóa thất bại!", {
              root: true,
            });
          }

        }
      });

      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    saveNewsCategory(){
      let payload = {
        name: this.name,
        description: this.description,
        image:this.image,
        type: this.type,
        lesson_word_id: this.detailLesson.id
      }
      this.$store.dispatch(ADD_CATEGORY_LESSON_WORD, payload).then((data) => {
        if (data && data.status == 1) {
          this.$store.dispatch(DETAIL_LESSON_WORD, {slug: this.slug})
          this.dialog = false
          this.$toasted.success("Thêm chủ đề thành công!", {
            position:'top-right',
            duration:3000
          });
        }
      });
    },
    onChangeImageUpload1() {
      this.cardImg = URL.createObjectURL(this.img);
      this.imageError = "";
      let formData = new FormData();
      formData.append("upload", this.img);
      this.$store.dispatch(SAVE_UPLOAD_LESSON, formData).then((data) => {
        if (data) {
          this.detailCategory.image = data;
          this.$toasted.success("Tải lên thành công!", {
            position:'top-right',
            duration:3000
          });
        }
      });
    },
    onChangeImageUpload() {
      this.cardImg = URL.createObjectURL(this.img);
      this.imageError = "";
      let formData = new FormData();
      formData.append("upload", this.img);
      this.$store.dispatch(SAVE_UPLOAD_LESSON, formData).then((data) => {
        if (data) {
          this.image = data;
          this.$toasted.success("Tải lên thành công!", {
            position:'top-right',
            duration:3000
          });
        }
      });
    },
    updateContent(val) {
      this.description = val;
    },
    openDialog() {
      this.name =''
      this.description =''
      this.image =''
      this.img =null
      this.dialog = true;
    },
    onClick() {
    },
    update() {
      this.$emit("input");
    },
  },
};
</script>

<style lang="scss">
.image-content {
  max-width: 200px;
}
</style>
