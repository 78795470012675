<template>
  <v-container>
    <v-row v-if="!isNew">
      <v-col cols="2" class="pa-1">
        <span>Câu hỏi  </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <div v-if="detailAnswer.ask" v-html="detailAnswer.ask.content"></div>
      </v-col>
    </v-row>
    <v-row v-if="isNew">
      <v-col cols="2" class="pa-1">
        <span>Câu hỏi(Copy slug) </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input
            :text="detailAnswer.ask_slug"
            v-model="detailAnswer.ask_slug"
        ></hnr-input>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Trả lời </span>
      </v-col>
      <v-col cols="12" class="pa-1">
        <ValidationProvider
            name="content"
            rules="required"
            v-slot="{ errors }"
        >
          <ck-content
              :content="detailAnswer.content"
              @getData="updateContent"
              v-model="detailAnswer.content"
          ></ck-content>
          <math-jax-editor
              :formula_prop="formula"
              @addFormula="addFormula"
          ></math-jax-editor>
          <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
        </ValidationProvider>

      </v-col>
    </v-row>


    <v-row v-if="isNew" class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Tài khoản người dùng (email) </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider
            name="email"
            rules="required"
            v-slot="{ errors }"
        >
          <hnr-input
              :text="detailAnswer.email"
              v-model="detailAnswer.email"
          ></hnr-input>
          <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
        </ValidationProvider>

      </v-col>
    </v-row>
    <v-row v-if="!isNew" class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Đăng bởi </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <span v-if="detailAnswer.user">{{ detailAnswer.user.email }}</span>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Kích hoạt </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <v-switch v-if="detailAnswer" v-model="detailAnswer.status"></v-switch>
      </v-col>
    </v-row>
    <v-row >
      <v-col cols="3" class="pa-1">
        <span> Điểm bình chọn </span>
      </v-col>
      <v-col cols="3" class="pa-1">
        <hnr-input
            v-if="detailAnswer.vote_summary"
            :text="detailAnswer.vote_summary.score+''"
            v-model="detailAnswer.vote_summary.score"
        ></hnr-input>
      </v-col>
      <v-col cols="3" class="pa-1">
        <span> Số lượt bình chọn </span>
      </v-col>
      <v-col cols="3" class="pa-1">
        <hnr-input
            v-if="detailAnswer.vote_summary"
            :text="detailAnswer.vote_summary.number+''"
            v-model="detailAnswer.vote_summary.number"
        ></hnr-input>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import {mapGetters} from "vuex";

import HnrInput from "@/components/elements/hnr-input";
// import HnrAreaInput from "@/components/elements/hnr-area-input";
// import HnrDatePicker from "@/components/elements/hnr-date-picker";
// import {SET_LOADING} from "@/store/loading.module";
import MathJaxEditor from "@/components/math/MathJaxEditor";
import CkContent from "@/components/ck-content";
export default {
  data() {
    return {
      formula:"",
      dialog: false,
      detailAnswer: {},
      selected: [],
      value: 0,
      search: null,
      singleSelect: true,
      suggestNewCategories: [],
      headers: [
        {
          text: "id",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
      ],
    };
  },
  props: {
    answer: Object,
    subjects: Array,
    grades: Array,
    isNew: {type: Boolean, default: false},
    slug_message: {type: String},
  },
  watch: {
    answer: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.detailAnswer = newVal;
      },
    },
  },
  components: {
    HnrInput,
    // HnrAreaInput,
    // HnrDatePicker,
    CkContent,
    MathJaxEditor
  },
  methods: {
    addFormula(value){
      this.detailAnswer.content += value;
    },
    saveNewsCategory() {
      if (this.selected && this.selected.length > 0) {
        this.detailAnswer.topics = {
          id: this.selected[0].id,
          name: this.selected[0].name,
        };
      } else {
        this.detailAnswer.topics = {
          id: 0,
          name: null,
        };
      }
      this.dialog = false;
    },
    getPicker(val) {
      this.detailAnswer.created_at = val
    },
    updateName() {
      //generate slug
      this.$emit("input");
    },
    updateContent(val) {
      this.detailAnswer.content = val;
    },
    updateParent() {
      this.dialog = true;
    },
    update() {
      this.$emit("input");
    },
    isRealValue(obj) {
      return obj && obj !== 'null' && obj !== 'undefined';
    }
    ,
    convertNumberToString(number) {
      if (this.isRealValue(number)) {
        return number.toString();
      }
      return "0";
    }
    ,
    stringToSlug(str) {
      str = str.replace(/^\s+|\s+$/g, ""); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      var from = "ăâđêơôư·/_,:;";
      var to = "aadeoou------";
      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      str = str
          .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
          .replace(/\s+/g, "-") // collapse whitespace and replace by -
          .replace(/-+/g, "-"); // collapse dashes

      return str;
    }
    ,
  }
  ,
}
;
</script>

<style lang="scss">
</style>
