import ApiService from "@/common/api.service";

// action types
export const GOOGLE_SEARCH_INDEXING = "/google/search/indexing";

const state = {};

const getters = {};

const actions = {
    [GOOGLE_SEARCH_INDEXING](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(GOOGLE_SEARCH_INDEXING, payload)
            .then(data => {
                resolve(data);
            })
            .catch(({ response }) => {
                reject(response);
            });
        });
    }
};

const mutations = {};

export default {
    state,
    actions,
    mutations,
    getters
};
