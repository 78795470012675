<template>
  <div data-app>
    <template>
      <v-container fluid>
        <v-row class="ma-0">
          <v-col class="pb-4 pl-0">
            <span class="text-h5">Tìm kiếm</span>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="6" class="">
            <v-row class="ma-0 pt-3">
              <v-col cols="3" class="pa-0">
                <span class="text-body-1">Tên bài viết</span>
              </v-col>
              <v-col cols="9" class="pa-0">
                <hnr-input @active="search" v-model="lesson_name"></hnr-input>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="">
            <v-row class="align-start ma-0">
              <v-col cols="1"></v-col>
              <v-col cols="3" class="pa-0 mt-3">
                <span class="text-body-1">Ngày tạo</span>
              </v-col>
              <v-col cols="4" class="pa-0">
                <v-menu
                    v-model="startDialog"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="startDate"
                        label="Thời gian bắt đầu"
                        prepend-icon="mdi-calendar"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="startDate"
                      @input="startDialog = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="">
            <v-row class="ma-0 pt-3">
              <v-col cols="3" class="pa-0">
                <span class="text-body-1">Người tạo</span>
              </v-col>
              <v-col cols="9" class="pa-0">
                <v-select
                    clearable
                    v-model="publish_by"
                    :items="users"
                    outlined
                    :item-text="'nick_name'"
                    :item-value="'nick_name'"
                    label="Người tạo"
                    required
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="">
            <v-row class="ma-0 pt-3">
              <v-col cols="3" class="pa-0">
                <span class="text-body-1">Trạng thái</span>
              </v-col>
              <v-col cols="9" class="pa-0">
                <v-select
                    clearable
                    v-model="status"
                    :items="listStatus"
                    outlined
                    :item-text="'text'"
                    :item-value="'value'"
                    label="Trạng thái"
                    required
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
<!--          <v-col cols="6" class="">-->
<!--            <v-row class="ma-0 pt-3">-->
<!--              <v-col cols="3" class="pa-0">-->
<!--                <span class="text-body-1">Trạng thái nhận cha</span>-->
<!--              </v-col>-->
<!--              <v-col cols="9" class="pa-0">-->
<!--                <v-select-->
<!--                    clearable-->
<!--                    v-model="status_parent"-->
<!--                    :items="listStatusParent"-->
<!--                    outlined-->
<!--                    :item-text="'text'"-->
<!--                    :item-value="'value'"-->
<!--                    placeholder="Trạng thái"-->
<!--                    required-->
<!--                ></v-select>-->
<!--              </v-col>-->
<!--            </v-row>-->
<!--          </v-col>-->
          <v-col cols="6" class="">
            <v-row class="ma-0 pt-3">
              <v-col cols="3" class="pa-0">
                <span class="text-body-1">Chọn môn học</span>
              </v-col>
              <v-col cols="9" class="pa-0">
                <v-select
                    clearable
                    v-model="subject_id"
                    :items="subjects"
                    outlined
                    :item-text="'name'"
                    :item-value="'id'"
                    placeholder="Môn học"
                    required
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="">
            <v-row class="ma-0 pt-3">
              <v-col cols="3" class="pa-0">
                <span class="text-body-1">Sắp xếp theo</span>
              </v-col>
              <v-col cols="9" class="pa-0">
                <v-select
                    clearable
                    v-model="select"
                    :items="listSelect"
                    outlined
                    :item-text="'text'"
                    :item-value="'value'"
                    label="Số thứ tự, ngày tạo, ngày cập nhật"
                    required
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="align-center justify-center ma-0">
          <v-col cols="6" class="pa-0">
            <v-btn color="primary" width="100" @click="search">Tìm kiếm</v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-data-table
          :headers="headers"
          :items="lessons"
          class="elevation-1"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          :server-items-length="total"
          @update:items-per-page="updateItemPerPage"
          @update:page="updatePage"
          @update:server-items-length="total"
          :footer-props="{ itemsPerPageOptions: [5, 10, 15, 20] }"
          @page-count="pageCount = $event"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-card-title class="text-h5 pa-0">
              Danh sách bài học
              <v-spacer></v-spacer>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-btn color="primary" width="100" dark class="mb-2" to="/lesson/add" target="_blank"
            >Thêm mới
            </v-btn
            >
          </v-toolbar>
        </template>
        <template v-slot:item.open_in_new="{ item }">
          <v-icon small class="mr-2" @click="openInNew(item)">
            mdi-open-in-new
          </v-icon>
        </template>
        <template v-slot:item.status="{ item }">
          <v-switch v-model="item.status" :disabled="true"></v-switch>
        </template>
        <template v-slot:item.updated_at="{ item }">
          <span>{{formatDateTime(item.updated_at)}}</span>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete</v-icon>
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="7"
        ></v-pagination>
      </div>
    </template>
    <!-- DIALOG DELETE  -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline"
        >Bạn có chắc muốn xóa không?
        </v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn width="70" color="blue darken-1" text @click="closeDelete"
          >Thoát
          </v-btn
          >
          <v-btn width="70" color="blue darken-1" text @click="deleteItemConfirm"
          >Xóa
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {COUNT_LESSON, GET_LIST_LESSONS, DELETE_LESSON} from "@/store/lesson.module";
import {GET_LIST_SUBJECTS} from "@/store/subject.module";
import {GET_LIST_USERS} from "@/store/user.module";
import {validationMixin} from "vuelidate";
import HnrInput from "@/components/elements/hnr-input";
import {SET_LOADING} from "@/store/loading.module";
import {ERROR} from "@/store/alert.module";

export default {
  mixins: [validationMixin],
  components: {
    HnrInput,
  },
  data: () => ({
    page: 1,
    itemsPerPage: 10,
    pageCount: 0,
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id",
      },
      {text: "Tên bài viết", value: "name", width :"30%"},
      {text: "STT", value: "order", width :"5%"},
      {text: "Slug", value: "slug", width :"20%"},
      {text: "", value: "open_in_new"},
      {text: "Người tạo", value: "publish_by"},
      {text: "Active", value: "status"},
      {text: "Ngày tạo", value: "created_at",width :"10%"},
      {text: "Ngày cập nhật", value: "updated_at",width :"10%"},
      {text: "Actions", value: "actions", sortable: false},
    ],
    publish_by: "",
    lesson_name: "",
    category: "",
    startDate: "",
    endDate: "",
    startDialog: "",
    endDialog: "",
    status: null,
    subject_id:null,
    select:null,
    listStatus:[
      {value : 0,text : 'Chưa kích hoạt'},
      {value : 1,text : 'kích hoạt'},
    ],
    listSelect:[
      {value : 0,text : 'Số thứ tự'},
      {value : 1,text : 'Ngày tạo'},
      {value : 2,text : 'Ngày cập nhật'},
    ],
    hasNotYetCategorySearch: false,
    dialog: false,
    dialogDelete: false,
    listStatusParent:[
      {value : 0,text : 'chưa nhận thư mục cha'},
      {value : 1,text : 'Đã nhận thư mục cha'},
    ],
    status_parent: null,
  }),

  computed: {
    ...mapGetters({
      lessons: "listLessons",
      users: "users",
      subjects: "listSubjects",
    }),
    total() {
      return this.$store.getters.lessonCount;
    },
  },
  mounted() {
    this.count();
    this.getLessons();
    this.$store.dispatch(GET_LIST_USERS)
    this.$store.dispatch(GET_LIST_SUBJECTS)
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Bài học", route: "Bài học"},
    ]);
  },
  watch: {},
  methods: {
    formatDateTime(date) {
      if (!date) return null
      const newDate = new Date(date)
      const [year, month, day] = [newDate.getFullYear(), newDate.getMonth() + 1, newDate.getDate()]
      return `${day}/${month}/${year}`
    },
    count() {
      var payload = {
        publish_by: this.publish_by,
        category: this.category,
        lesson: this.lesson_name,
        start_date: this.startDate,
        end_date: this.endDate,
        status_parent: this.status_parent,
        has_category: this.hasNotYetCategorySearch,
        status: this.status,
        subject: this.subject_id,
      };
      this.$store.dispatch(COUNT_LESSON, payload).then(data => {
      });
    },
    search() {
      this.count();
      this.getLessons();
    },
    getLessons() {
      var payload = {
        publish_by: this.publish_by,
        lesson: this.lesson_name,
        category: this.category,
        start_date: this.startDate,
        end_date: this.startDate,
        has_category: this.hasNotYetCategorySearch,
        page: this.page,
        status: this.status,
        select: this.select ? this.select : 0,
        status_parent: this.status_parent,
        page_count: this.itemsPerPage,
        subject: this.subject_id,
      };
      this.$store.dispatch(SET_LOADING, true);
      this.$store
          .dispatch(GET_LIST_LESSONS, payload)
          .then(() => {
            this.$store.dispatch(SET_LOADING, false);
          })
          .catch(() => {
            this.$store.dispatch(SET_LOADING, false);
          });
    },

    updateItemPerPage($event) {
      this.itemsPerPage = $event;
      console.log(this.itemsPerPage,'this.total1')
      this.getLessons();
    },

    updatePage($event) {
      this.page = $event;
      console.log(this.page,'this.total2')
      this.getLessons();
    },
    updateTotal($event) {
      this.total = $event;
      console.log(this.total,'this.total3')
      this.getLessons();
    },

    editItem(item) {
      let routeData = this.$router.resolve({
        name: "lession-detail",
        params: {slug: item.slug},
      });
      window.open(routeData.href, '_blank');
    },

    deleteItem(item) {
      this.editedIndex = this.lessons.indexOf(item);
      this.editedId = item.id;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.$store.dispatch(DELETE_LESSON, this.editedId).then((data) => {
        if (data.status) {
          this.lessons.splice(this.editedIndex, 1);
        } else {
          if(data.errorCode == 424){
            this.$store.dispatch(ERROR, data.message, {
              root: true,
            });
          }else {
            this.$store.dispatch(ERROR, "Xóa thất bại!", {
              root: true,
            });
          }

        }
      });

      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
    },

    addNewItem() {
      // this.$router.push({name: "lession-add"});
      let routeData = this.$router.resolve({
        name: "lession-add",
      });
      window.open(routeData.href, '_blank');
    },
    openInNew(lesson) {
      if (lesson) {
        window.open(process.env.VUE_APP_FRONT_URL + "/" + lesson.slug, '_blank')
      }
    }
  },
};
</script>
