import ApiService from "@/common/api.service";

// action types
export const DETAIL_LESSON_WORD = "/lesson_words/detail";
export const DETAIL_LESSON_WORD_CATEGORY = "/lesson_words/detail-category";
export const SAVE_LESSON_WORD = "/lesson_words/create";
export const ADD_CATEGORY_LESSON_WORD = "/lesson_words/create-category";
export const DELETE_CATEGORY_LESSON_WORD = "/lesson_words/delete-category";
export const UPDATE_LESSON_WORD = "/lesson_words/update";
export const UPDATE_LESSON_WORD_CATEGORY = "/lesson_words/update-category";
export const GET_LIST_LESSONS_WORD = "/lesson_words/list";
export const DELETE_LESSON_WORD = "/lesson_words/delete";
export const GET_LIST_WORD = "/lesson_words/list-word";
export const SAVE_WORD = "/lesson_words/add-word";
export const DETAIL_WORD = "/lesson_words/detail-word";
export const UPDATE_WORD = "/lesson_words/update-word";
export const DELETE_WORD = "/lesson_words/delete-word";


// mutation types
export const SET_LESSON_WORD = "setLesson";
export const SET_LIST_WORD = "setListWord";
export const SET_DETAIL_WORD = "setDetailWord";
export const SET_LESSON_RELATED_WORD = "setLessonRelated";
export const SET_NAME_LESSON_WORD = "setNameLesson";
export const SET_DETAIL_LESSON_WORD = "setDetailLesson";
export const SET_DETAIL_LESSON_WORD_CATEGORY = "setDetailLessonCategory";
export const SET_COUNT_LESSON_WORD = "setCountLesson";
export const SET_ERROR = "setError";

const state = {
    errors: null,
    lesson_words: [],
    words: [],
    word: {},
    lesson_relateds: [],
    detailLesson: {},
    detail_category: {},
    lesson_count: 0,
    images: [],
    src: [],
    name_of_lessons: [],
};

const getters = {
    listLessonWords(state) {
        return state.lesson_words;
    },
    detailLessonWord(state) {
        return state.detailLesson;
    },
    detailLessonWordCategory(state) {
        return state.detail_category;
    },
    listWords(state) {
        return state.words;
    },
    detailWord(state) {
        return state.word;
    },
};

const actions = {
    [GET_LIST_LESSONS_WORD](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_LIST_LESSONS_WORD, payload)
                .then(data => {
                    if (data.status) {
                        context.commit(SET_LESSON_WORD, data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                    context.commit(SET_ERROR, response.data.errors);
                });
        });
    },
    [GET_LIST_WORD](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(GET_LIST_WORD, payload)
                .then(data => {
                    if (data.status) {
                        context.commit(SET_LIST_WORD, data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                    context.commit(SET_ERROR, response.data.errors);
                });
        });
    },
    [DETAIL_WORD](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(DETAIL_WORD, payload)
                .then(data => {
                    if (data.status) {
                        context.commit(SET_DETAIL_WORD, data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                    context.commit(SET_ERROR, response.data.errors);
                });
        });
    },
    [SAVE_WORD](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(SAVE_WORD, payload)
                .then(data => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                    reject(response);
                });
        });
    },
    [UPDATE_WORD](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(UPDATE_WORD, payload)
                .then(data => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                    reject(response);
                });
        });
    },
    [DETAIL_LESSON_WORD](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(DETAIL_LESSON_WORD, payload)
                .then(data => {
                    if (data.status) {
                        context.commit(SET_DETAIL_LESSON_WORD, data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                    reject(response);
                });
        });
    },
    [DETAIL_LESSON_WORD_CATEGORY](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(DETAIL_LESSON_WORD_CATEGORY, payload)
                .then(data => {
                    if (data.status) {
                        context.commit(SET_DETAIL_LESSON_WORD_CATEGORY, data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                    reject(response);
                });
        });
    },
    [SAVE_LESSON_WORD](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(SAVE_LESSON_WORD, payload)
                .then(data => {
                    // if (data.status) {
                    //   context.commit(SET_LESSON, data);
                    // } else {
                    //   context.commit(SET_ERROR, data.message);
                    // }
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                    reject(response);
                });
        });
    },
    [ADD_CATEGORY_LESSON_WORD](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(ADD_CATEGORY_LESSON_WORD, payload)
                .then(data => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                    reject(response);
                });
        });
    },
    [UPDATE_LESSON_WORD](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(UPDATE_LESSON_WORD, payload)
                .then( data => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [UPDATE_LESSON_WORD_CATEGORY](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(UPDATE_LESSON_WORD_CATEGORY, payload)
                .then( data => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [DELETE_LESSON_WORD](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(DELETE_LESSON_WORD, id)
                .then(data => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                    reject(response);
                });
        });
    },
    [DELETE_WORD](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(DELETE_WORD, id)
                .then(data => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                    reject(response);
                });
        });
    },
    [DELETE_CATEGORY_LESSON_WORD](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(DELETE_CATEGORY_LESSON_WORD, id)
                .then(data => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                    reject(response);
                });
        });
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_LESSON_WORD](state, data) {
        state.lesson_words = data.data;
        state.errors = {};
    },
    [SET_DETAIL_WORD](state, data) {
        state.word = data.data;
        state.errors = {};
    },
    [SET_LIST_WORD](state, data) {
        state.words = data.data;
        state.errors = {};
    },
    [SET_LESSON_RELATED_WORD](state, data) {
        state.lesson_relateds = data.data;
        state.errors = {};
    },
    [SET_DETAIL_LESSON_WORD](state, data) {
        state.detailLesson = data.data;
        state.errors = {};
    },
    [SET_DETAIL_LESSON_WORD_CATEGORY](state, data) {
        state.detail_category = data.data;
        state.errors = {};
    },
    [SET_COUNT_LESSON_WORD](state, data) {
        state.lesson_count = data.data;
    },
    [UPDATE_LESSON_WORD](state, data) {
        state.lesson_words.push(data.data);
        state.errors = {};
    },
    [SET_NAME_LESSON_WORD](state, data) {
        state.name_of_lessons = data;
        state.errors = {};
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
