<template>
  <v-container>
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-tabs v-model="tabIndex">
        <v-tab v-for="item in items" :key="item.id" :disabled="isCreate && item.id == 3 ? true : false">{{ item.tab }}</v-tab>
      </v-tabs>
      <v-tabs-items class="tab-content " v-model="tabIndex">
        <v-tab-item active>
          <lesson-word-content class="tab-detail" :lesson="detailLesson" :isNew="isCreate"></lesson-word-content>
        </v-tab-item>
        <v-tab-item>
          <lesson-word-seo class="tab-detail" :lesson="detailLesson"></lesson-word-seo>
        </v-tab-item>
        <v-tab-item>
          <lesson-word-seo-en class="tab-detail" :lesson="detailLesson"></lesson-word-seo-en>
        </v-tab-item>
        <v-tab-item>
          <lesson-word-category class="tab-detail" :lesson="detailLesson"></lesson-word-category>
        </v-tab-item>
      </v-tabs-items>
      <v-row v-if="isCreate" class="align-center justify-center">
        <v-btn v-if="tabIndex != 3" color="primary" width="100" @click="handleSubmit(saveLesson)">Lưu bài học</v-btn>
      </v-row>
      <v-row v-else class="align-center justify-center">
        <v-btn v-if="tabIndex != 3" color="primary" width="130" @click="handleSubmit(updateLesson)">Cập nhật bài học</v-btn>
      </v-row>

    </ValidationObserver>
  </v-container>
</template>
<script>
import { mapState } from "vuex";

// stores
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { SET_LOADING } from "@/store/loading.module";
import { SAVE_LESSON, UPDATE_LESSON, DETAIL_LESSON, ADD_CATEGORY_LESSON, DELETE_CATEGORY_LESSON,SEARCH_LESSON_BY_NAME } from "@/store/lesson.module";
import { SAVE_LESSON_WORD,DETAIL_LESSON_WORD ,UPDATE_LESSON_WORD} from "@/store/lesson-words.module";
import { SUCCESS, ERROR } from "@/store/alert.module";
// components
import LessonWordContent from "@/components/lesson-word/LessonWordContent";
import LessonWordSeo from "@/components/lesson-word/LessonWordSeo";
import LessonWordSeoEn from "@/components/lesson-word/LessonWordSeoEn";
import LessonWordCategory from "@/components/lesson-word/LessonWordCategory";
import HnrInput from '@/components/elements/hnr-input'
import HnrEditTable from '@/components/elements/hnr-edit-table'


export default {
  data() {
    return {
      detailLesson: {
        id: '',
        name: "",
        slug: "",
        file_audio: {},
        order:0,
        subject_id: null,
        video:"",
        audio:"",
        image:"",
        type:1,
        status:null,
        short_description: "",
        description: "",
        count_view:"",
        seo_title: "",
        seo_description: "",
        seo_key_en: "",
        seo_title_en: "",
        seo_description_en: "",
        seo_key: "",
      },
      slug: null,
      isCreate: false,

      isRedirect:true,
      tabIndex: 0,
      items: [
        { id: 0, tab: "Thông tin" },
        { id: 1, tab: "Cấu hình SEO" },
        { id: 2, tab: "Cấu hình SEO tiếng anh" },
        { id: 3, tab: "Chủ đề bài học" },
      ],
      categories: [],
      dialog: false,
      list_lesson_related:[],
      lesson_related:[],
      suggestNewCategories: [],
      // selectedCategories: [],
      value: 0,
      search: null,
      singleSelect: true,
      selected: [],
      headers: [
        {
          text: 'id',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name' }
      ],

    };
  },
  components: {
    LessonWordCategory,
    LessonWordSeoEn,
    LessonWordSeo,
    LessonWordContent,
    HnrInput,
    HnrEditTable
  },
  computed: {
    ...mapState({
      lesson_state: (state) => state.lesson.detailLesson,
      searchLesson: `listNameLesson`,
    }),
    lesson:function (){
      let data= Object.assign({},this.lesson_state);
      data.content= this.regexImageUrl(data.content);
      return data;
    }
  },
  watch: {
    lesson: {
      deep: true,
      handler(newVal) {
        this.detailLesson = newVal;
        this.getListOfNewsCategory(this.detailLesson.lesson_related);
      },
    },
    lesson_related: {
      deep: true,
      handler(newVal) {
        if (newVal && newVal.length > 20) {
          this.suggestNewCategories = newVal.slice(0, 30);
        }
        else {
          this.suggestNewCategories = newVal;
        }
      },
    }
  },
  created() {
    this.slug = this.$route.params.slug;
    if (this.slug) {
      this.isCreate = false;
      this.$store.dispatch(SET_BREADCRUMB, [{ title: this.slug }]);
      this.$store.dispatch(SET_LOADING, true);
      this.$store.dispatch(DETAIL_LESSON_WORD, { slug: this.slug }).then((data) => {
        if(data){
          this.list_lesson_related = data.data.lesson_related;
        }
        this.$store.dispatch(SET_LOADING, false);
      }).catch(() => {
        this.$store.dispatch(SET_LOADING, false);
      });
    }
    else {
      this.isCreate = true;
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Thêm bài học" }]);
    }

  },
  methods: {
    regexImageUrl(content) {
      if (!content) {
        return null;
      }
      content = content.replace(
          /<img([\w\W]+?)>/gi,
          function (match) {
            match = match.replace(
                /src\s*=\s*"/g,
                function () {
                  return 'src="' + process.env.VUE_APP_BASE_URL + "/";
                }
            );
            return match;
          }
      );
      return content;
    },
    searchCategoriesByName() {
      if (this.search) {
        this.$store.dispatch(SET_LOADING, true);
        this.$store.dispatch(SEARCH_LESSON_BY_NAME, {name: this.search}).then((data) => {
          this.suggestNewCategories = data
          this.$store.dispatch(SET_LOADING, false);
        }).catch(() => {
          this.$store.dispatch(SET_LOADING, false);
        });
      }
    },
    saveLesson() {
      this.isRedirect = false;
      if(!this.detailLesson.subject_id) {
        this.$store.dispatch(ERROR, "Vui lòng chọn môn học !", { root: true });
      }
      else {
        let payload = {
          name: this.detailLesson.name,
          status: this.detailLesson.status,
          subject_id: this.detailLesson.subject_id,
          slug: this.detailLesson.slug,
          order: this.detailLesson.order ? this.detailLesson.order : 0,
          image: this.detailLesson.image,
          type: this.detailLesson.type,
          audio: this.detailLesson.audio,
          description:this.detailLesson.description,
          short_description: this.detailLesson.short_description,
          seo_title: this.detailLesson.seo_title,
          seo_description: this.detailLesson.seo_description,
          seo_key: this.detailLesson.seo_key,
          created_at: this.detailLesson.created_at,
          video:this.detailLesson.video,
          seo_title_en: this.detailLesson.seo_title_en,
          seo_description_en: this.detailLesson.seo_description_en,
          seo_key_en: this.detailLesson.seo_key_en,
        };
        this.$store.dispatch(SET_LOADING, true);
        this.$store.dispatch(SAVE_LESSON_WORD, payload).then(data => {
          if (data.status) {
            this.$store.dispatch(SUCCESS, "Thêm bài viết thành công!", { root: true });
            this.$router.push('/lesson-word');
          }
          else {
            this.$store.dispatch(ERROR, data.message, { root: true });
          }
          this.$store.dispatch(SET_LOADING, false);
        }).catch(() => {
          this.$store.dispatch(ERROR, "Thêm bài viết thất bại!", { root: true });
          this.$store.dispatch(SET_LOADING, false);
        });
      }

    },
    updateLesson() {
      this.isRedirect = false;
      if(!this.detailLesson.subject_id) {
        this.$store.dispatch(ERROR, "Vui lòng chọn môn học !", { root: true });
      }
      else {
        let payload = {
          id: this.detailLesson.id,
          name: this.detailLesson.name,
          status: this.detailLesson.status,
          subject_id: this.detailLesson.subject_id,
          slug: this.detailLesson.slug,
          type: this.detailLesson.type,
          order: this.detailLesson.order ? this.detailLesson.order : 0,
          has_change_slug: this.detailLesson.slug !== this.lesson_state.slug,
          image: this.detailLesson.image,
          audio: this.detailLesson.audio,
          description:  this.updateImageUrl(this.detailLesson.description),
          short_description: this.detailLesson.short_description,
          seo_title: this.detailLesson.seo_title,
          seo_description: this.detailLesson.seo_description,
          seo_key: this.detailLesson.seo_key,
          created_at: this.detailLesson.created_at,
          video:this.detailLesson.video,
          seo_title_en: this.detailLesson.seo_title_en,
          seo_description_en: this.detailLesson.seo_description_en,
          seo_key_en: this.detailLesson.seo_key_en,
        };
        this.$store.dispatch(SET_LOADING, true);
        this.$store.dispatch(UPDATE_LESSON_WORD, payload).then(data => {
          if (data.status) {
            this.$store.dispatch(SUCCESS, "Cập nhật bài viết thành công!", {root: true});
            this.$router.push('/lesson-word');
          } else {
            if (data.errorCode == 424) {
              this.$store.dispatch(ERROR, data.message, {
                root: true,
              });
            } else {
              this.$store.dispatch(ERROR, data.message, {
                root: true,
              });
            }
          }
          this.$store.dispatch(SET_LOADING, false);
        }).catch(() => {
          this.$store.dispatch(ERROR, "Thêm bài viết thất bại!", {root: true});
          this.$store.dispatch(SET_LOADING, false);
        });
      }
    },
    updateImageUrl(content){
      if(!content){
        return null;
      }
      content = content.replace(
          /<img([\w\W]+?)>/gi,
          function (match) {
            match = match.replace(
                /src="([^"]+)"/g,
                function (match_child) {
                  let base_url= process.env.VUE_APP_BASE_URL
                  var regex = new RegExp(base_url+"/(.*)", "");
                  let relative_path = "";
                  console.log(base_url+"/(.*)");
                  let relative_path_arr =match_child.match(regex)
                  if(relative_path_arr && relative_path_arr.length>0){
                    relative_path=relative_path_arr[1];
                    return 'src="'+relative_path;
                  }
                }
            );
            return match;
          }
      );
      return content;
    },

    addRow() {
      this.dialog = true;
    },
    updateItemTable(payload) {
      var index = this.detailLesson.lesson_related.findIndex(item => item.id == payload.id);
      this.detailLesson.lesson_related[index] = payload;
    },
    deleteItem(item) {
      if (confirm("Bạn có chắc muốn xóa không?")) {
        let payload = { lesson_id: this.detailLesson.id, lesson_related_id: item.id };

        this.$store.dispatch(SET_LOADING, true);
        this.$store.dispatch(DELETE_CATEGORY_LESSON, payload).then(data => {
          if (data.status) {
            this.$store.dispatch(SUCCESS, "Xóa chủ đề thành công!", { root: true });
            this.$store.dispatch(DETAIL_LESSON_WORD, { slug: this.slug }).then((data) => {
              if(data){
                this.list_lesson_related = data.data.lesson_related;
              }
            })
          }
          else {
            this.$store.dispatch(ERROR, "Xóa chủ đề thất bại!", { root: true });
          }
          this.$store.dispatch(SET_LOADING, false);
        }).catch(() => {
          this.$store.dispatch(ERROR, "Xóa chủ đề thất bại!", { root: true });
          this.$store.dispatch(SET_LOADING, false);
        });
      }
    },

    getListOfNewsCategory(news2Categories) {
      let newCategroies = [];
      // if (news2Categories) {
      //   news2Categories.forEach(element => {
      //       let category = {};
      //       category.id = element.category_id;
      //       category.name = element.news_category.name;
      //       category.class = element.news_category.procategory.name;
      //       newCategroies.push(category);
      //   });
      // }
      this.categories = newCategroies;
    },
    saveNewsCategory() {
      if (this.selected && this.selected.length > 0) {
        this.value = this.selected[0].id;
      }
      if (this.value) {
        let payload = { lesson_id: this.detailLesson.id, lesson_related_id: this.value };
        this.$store.dispatch(SET_LOADING, true);
        this.$store.dispatch(ADD_CATEGORY_LESSON, payload).then(data => {
          if (data.status) {
            this.slug = this.$route.params.slug;
            this.$store.dispatch(DETAIL_LESSON_WORD, { slug: this.slug }).then((data) => {
              if(data){
                this.list_lesson_related = data.data.lesson_related;
              }
              this.$store.dispatch(SET_LOADING, false);
            })
            this.$store.dispatch(SUCCESS, "Thêm chủ đề thành công!", { root: true });
          }
          else {
            this.$store.dispatch(ERROR, data.message, {
              root: true,
            });
          }
          this.$store.dispatch(SET_LOADING, false);
          this.dialog = false;
        }).catch(() => {
          this.$store.dispatch(ERROR, "Thêm chủ đề thất bại!", { root: true });
          this.$store.dispatch(SET_LOADING, false);
        });
      }
    },

  },
  beforeRouteLeave (to, from, next) {
    if(this.isRedirect){
      if (confirm("Bạn chưa lưu bài viết.Bạn vẫn muốn rời đi chứ?")) {
        next()
      }else{
        next(false)
      }
    }else{
      next();
    }
  },
};
</script>


<style lang="scss">
.tab-detail{
  margin-top:15px;
}
</style>
