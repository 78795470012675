<template>
  <v-container>
    <v-row class="justify-center">
      <v-col cols="2" class="pa-1">
        <span> Tên bài học </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider
            name="name"
            rules="required"
            v-slot="{ errors }"
        >
          <hnr-input
              :text="detailLesson.name"
              @onKeyUp="onKeyUp"
              v-model="detailLesson.name"
          ></hnr-input>
          <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="2" class="pa-1">
        <span> Slug </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input
            :text="detailLesson.slug"
            v-model="detailLesson.slug"
        ></hnr-input>
        <span class="validate-error text-danger text-danger">
            {{ slug_message }}
          </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2" class="pa-1">
        <span> Loại từ </span>
      </v-col>
      <v-col cols="6" class="pa-1">
        <v-select
            clearable
            v-model="detailLesson.type"
            :items="type_word"
            outlined
            :item-text="'name'"
            :item-value="'id'"
            placeholder="Loại từ"
            required
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="2" class="pa-1">
        <span> Sắp xếp </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input
            :text="detailLesson.order"
            v-model="detailLesson.order"
        ></hnr-input>
        <span class="validate-error text-danger text-danger">
            {{ slug_message }}
          </span>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="2" class="pa-1">
        <span> Kích hoạt </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <v-switch v-model="detailLesson.status"></v-switch>
      </v-col>
    </v-row>

    <v-row class="justify-center my-3">
      <v-col cols="2" class="pa-1">
        <span> Tải file audio </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <v-file-input
            v-model="audio"
            label="Tải file audio"
            @change="onChangeFileAudio"
            clearable
        ></v-file-input>
        <div>
          <audio controls muted id="audio" :src="url_demo ? url_demo : urlAudio">
            <source :src="url_demo ? url_demo : urlAudio" type="audio/mpeg">
          </audio>
        </div>
        <span class="validate-error text-danger text-danger">
            {{ slug_message }}
          </span>
      </v-col>
    </v-row>
    <v-row class="justify-center my-3">
      <v-col cols="2" class="pa-1">
        <span> Tải ảnh </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <v-file-input
            v-model="img"
            label="Tải file ảnh"
            @change="onChangeImageUpload"
            clearable
        ></v-file-input>
        <div >
          <img :src="detailLesson.image ? detailLesson.image : ''" class="upload-img"/>
        </div>
        <span class="validate-error text-danger text-danger">
            {{ slug_message }}
          </span>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="2" class="pa-1">
        <span> Link youtube</span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input
            :text="detailLesson.video"
            @input="update"
            v-model="detailLesson.video"
        ></hnr-input>
      </v-col>
    </v-row>
    <v-row class="mt-2 mb-2">
      <v-col cols="2" class="pa-1">
        <span>Chọn môn học</span>
      </v-col>
      <v-col cols="6" class="pa-1">
        <v-select
            clearable
            v-model="detailLesson.subject_id"
            :items="subjects"
            outlined
            :item-text="'name'"
            :item-value="'id'"
            placeholder="Môn học"
            required
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="2" class="pa-1">
        <span> Mô tả ngắn</span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input
            :text="detailLesson.short_description"
            @input="update"
            v-model="detailLesson.short_description"
        ></hnr-input>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col cols="2" class="pa-1">
        <span> Nội dung </span>
      </v-col>
      <v-col cols="12" class="pa-1">
        <v-row class="justify-center my-3">
          <v-col cols="3" class="pl-3">
            <span> Tải file audio thêm vào ckeditor</span>
          </v-col>
          <v-col cols="9" class="pa-1">
            <v-file-input
                v-model="audioCk"
                label="Tải file audio"
                @change="onChangeFileAudioCkeditor"
                clearable
            ></v-file-input>
            <div class="d-flex">
              <audio controls muted id="audio" :src="url_demo_ck ? url_demo_ck : ''" style="z-index: 20">
                <source :src="url_demo_ck ? url_demo_ck : ''" type="audio/mpeg">
              </audio>
              <input type="text" class="mt-1 ml-5" style="position: absolute;z-index: 1" ref="input"/>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      class="mt-2 ml-3"
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click="copy"
                  >
                    Copy
                  </v-btn>
                </template>
                <span>Copy xong hãy thêm vào Insert Html</span>
              </v-tooltip>

            </div>
            <span class="validate-error text-danger text-danger">
            {{ slug_message }}
          </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="pa-1">
          <ck-content
              id="lesson_content"
              :content="detailLesson.description"
              :slug="slug"
              @getData="updateContent"
              v-model="detailLesson.description"
          ></ck-content>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import HnrInput from "@/components/elements/hnr-input";
import HnrAreaInput from "@/components/elements/hnr-area-input";
// import HnrDatePicker from "@/components/elements/hnr-date-picker";
import CkContent from "@/components/ck-content";
import {SET_LOADING} from "@/store/loading.module";
import {SEARCH_TOPIC_BY_NAME} from "@/store/topic.module";

import {mapGetters, mapState} from "vuex";
import mixin from "@/store/mixin";
import {
  SAVE_UPLOAD_LESSON
} from "@/store/lesson.module";
import {
  SAVE_AUDIO
} from "@/store/image.module";
import {GET_LIST_SUBJECTS} from "@/store/subject.module";
export default {
  mixins:[mixin],
  data() {
    return {
      subject_id:null,
      url:'',
      url_demo:'',
      url_demo_ck:'',
      src: {},
      img:{},
      audio:{},
      audioCk:{},
      dialog: false,
      detailLesson: {},
      selected: [],
      value: 0,
      search: null,
      singleSelect: true,
      suggestNewCategories: [],
      type_word: [
        {id:1,name:"Text"},
        {id:2,name:"Text + Title"},
        {id:3,name:"Text + Main Image"},
        {id:4,name:"Image"},
      ],
      headers: [
        {
          text: "id",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
      ],
      slug:this.$route.params.slug
    };
  },
  props: {
    lesson: Object,
    isNew: {type: Boolean, default: false},
    slug_message: {type: String},
  },
  computed: {
    ...mapState({
      nameOfNewsCategories: (state) => state.newscategory.nameOfNewscategories,
    }),
    ...mapGetters({
      subjects: "listSubjects",
    }),
    urlAudio(){
      let url = process.env.VUE_APP_API_URL
      let replace = url.replace('admin','')
      this.detailLesson = this.lesson;
      let path = this.detailLesson && this.detailLesson.file_audio && this.detailLesson.file_audio.path ?this.detailLesson.file_audio.path:'';
      if(path) {
        return replace + path
      }
    }
  },
  created() {
    this.$store.dispatch(GET_LIST_SUBJECTS)
  },
  watch: {
    lesson: {
      deep: true,
      immediate: true,
      handler(newVal) {
        let url = process.env.VUE_APP_API_URL
        let replace = url.replace('admin','')
        this.detailLesson = newVal;
        let path = this.detailLesson && this.detailLesson.file_audio && this.detailLesson.file_audio.path ?this.detailLesson.file_audio.path:'';
        if(path) {
          this.url = replace + path
        }

      },
    },
    nameOfNewsCategories: {
      deep: true,
      handler(newVal) {
        this.suggestNewCategories = newVal;
      },
    },
  },
  components: {
    HnrInput,
    HnrAreaInput,
    CkContent,
    // HnrDatePicker,
  },

  methods: {
    onChangeFileAudio() {
      this.cardImg = URL.createObjectURL(this.audio);
      this.imageError = "";
      let formData = new FormData();
      formData.append("file", this.audio);
      this.$store.dispatch(SAVE_AUDIO, formData).then((data) => {
        if (data) {
          let url = process.env.VUE_APP_API_URL
          let replace = url.replace('admin','')
          this.detailLesson.audio = data.id;
          this.url_demo = replace + data.path;
          this.$toasted.success("Tải lên thành công!", {
            position:'top-right',
            duration:3000
          });
        }
      });
    },
    onChangeFileAudioCkeditor() {
      this.cardImg = URL.createObjectURL(this.audioCk);
      this.imageError = "";
      let formData = new FormData();
      formData.append("file", this.audioCk);
      this.$store.dispatch(SAVE_AUDIO, formData).then((data) => {
        if (data) {
          let url = process.env.VUE_APP_API_URL
          let replace = url.replace('admin','')
          this.url_demo_ck = replace + data.path;
          this.$toasted.success("Tải lên thành công!", {
            position:'top-right',
            duration:3000
          });
        }
      });
    },
    copy() {
      this.text =(
          '<audio controls>' +
          `<source src="${this.url_demo_ck?this.url_demo_ck:''}" type="audio/mpeg">`+
          '</audio>'
      )

      console.log(this.text)
      let copyText = this.$refs.input;
      copyText.value=this.text
      copyText.select();
      document.execCommand('copy');
    },
    onChangeFileUpload() {
      this.cardImg = URL.createObjectURL(this.src);
      this.imageError = "";
      let formData = new FormData();
      formData.append("upload", this.src);
      this.$store.dispatch(SAVE_UPLOAD_LESSON, formData).then((data) => {
        if (data) {
          this.detailLesson.src = data;
          this.$toasted.success("Tải lên thành công!", {
            position:'top-right',
            duration:3000
          });
        }
      });
    },
    onChangeImageUpload() {
      this.cardImg = URL.createObjectURL(this.img);
      this.imageError = "";
      let formData = new FormData();
      formData.append("upload", this.img);
      this.$store.dispatch(SAVE_UPLOAD_LESSON, formData).then((data) => {
        if (data) {
          this.detailLesson.image = data;
          this.$toasted.success("Tải lên thành công!", {
            position:'top-right',
            duration:3000
          });
        }
      });
    },
    clearImage() {
      this.detailLesson.src = "";
      this.show = false;
    },
    changeSlug(){
      this.detailLesson.slugHasChanged = true;
    },
    searchCategoriesByName() {
      if (this.search) {
        this.$store.dispatch(SET_LOADING, true);
        this.$store
            .dispatch(SEARCH_TOPIC_BY_NAME, {name: this.search})
            .then(() => {
              this.$store.dispatch(SET_LOADING, false);
            })
            .catch(() => {
              this.$store.dispatch(SET_LOADING, false);
            });
      }
    },
    saveNewsCategory() {
      if (this.selected && this.selected.length > 0) {
        this.detailLesson.topics = {
          id: this.selected[0].id,
          name: this.selected[0].name,
        };
      } else {
        this.detailLesson.topics = {
          id: 0,
          name: null,
        };
      }
      this.dialog = false;
    },
    getPicker(val) {
      this.detailLesson.created_at = val
    },
    updateName() {
      //generate slug
      this.$emit("input");
    },
    updateContent(val) {
      this.detailLesson.description = val;
    },
    updateParent() {
      this.dialog = true;
    },
    update() {
      this.$emit("input");
    },
    isRealValue(obj) {
      return obj && obj !== 'null' && obj !== 'undefined';
    }
    ,
    convertNumberToString(number) {
      if (this.isRealValue(number)) {
        return number.toString();
      }
      return "0";
    },
    onKeyUp(value){
      this.detailLesson.slug = this.sanitizeTitle(value);
    }
  }
}
;
</script>

<style lang="scss">
.upload-img {
  max-width: 300px; max-height: 300px;object-fit: contain
}
</style>
