import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "/auth/login";
export const LOGOUT = "/auth/logout";
export const REGISTER = "/auth/register";
export const UPDATE_USER = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setToken";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post(LOGIN, credentials)
        .then(data => {
          if (data.status) {
            context.commit(SET_AUTH, data);
          } else {
            context.commit(SET_ERROR, data.message);
          }
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
    [LOGOUT](context) {
      context.commit(PURGE_AUTH);
    },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post(REGISTER, credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [VERIFY_AUTH]() {
    ApiService.setHeader();
    // if (JwtService.getToken()) {
    //   ApiService.setHeader();
    //   //   ApiService.get("verify")
    //   //     .then(({ data }) => {
    //   //       context.commit(SET_AUTH, data);
    //   //     })
    //   //     .catch(({ response }) => {
    //   //       context.commit(SET_ERROR, response.data.errors);
    //   //     });
    // } else {
    //   // context.commit(PURGE_AUTH);
    // }
  },
  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload;
    const user = { email, username, bio, image };
    if (password) {
      user.password = password;
    }

    return ApiService.put("user", user).then(({ data }) => {
      context.commit(SET_AUTH, data);
      return data;
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, data) {
    state.isAuthenticated = true;
    state.errors = {};
    JwtService.saveToken(data.data.remember_token);
    JwtService.saveAuthority(data.data.authority);
    JwtService.saveFullName(data.data.full_name);
  },
  [PURGE_AUTH]() {
    // state.isAuthenticated = false;
    // state.user = {};
    // state.errors = {};
    // JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
