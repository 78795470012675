<template>
  <v-container>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Tên bài viết </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider
            name="name"
            rules="required"
            v-slot="{ errors }"
        >
          <hnr-input
              :text="detailNews.name"
              @onKeyUp="onKeyUp"
              v-model="detailNews.name"
          ></hnr-input>
          <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Slug </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input
            :text="detailNews.slug"
            v-model="detailNews.slug"
        ></hnr-input>
        <span class="validate-error text-danger text-danger">
            {{ slug_message }}
          </span>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Kích hoạt </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <v-switch v-model="detailNews.status"></v-switch>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Highlight </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <v-switch v-model="detailNews.highlight"></v-switch>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center my-3">
      <v-col cols="2" class="pa-1">
        <span> Tải ảnh </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <v-file-input
            v-model="img"
            label="Tải file ảnh"
            @change="onChangeImageUpload"
            clearable
        ></v-file-input>
        <div >
          <img :src="detailNews.photo_url ? detailNews.photo_url : ''" class="upload-img"/>
        </div>
        <span class="validate-error text-danger text-danger">
            {{ slug_message }}
          </span>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Mô tả ngắn</span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input
            :text="detailNews.short_description"
            @input="update"
            v-model="detailNews.short_description"
        ></hnr-input>
      </v-col>
    </v-row>

    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Nội dung </span>
      </v-col>
      <v-col cols="12" class="pa-1">
        <ValidationProvider
            name="content"
            rules="required"
            v-slot="{ errors }"
        >
          <ck-content
              id="news_content"
              :content="detailNews.description"
              :slug="slug"
              @getData="updateContent"
              v-model="detailNews.description"
          ></ck-content>
          <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
        </ValidationProvider>

      </v-col>
    </v-row>
    <v-row v-if="!isNew" class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Số lượt xem </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <span> {{ detailNews.count_view }}</span>
      </v-col>
    </v-row>

    <v-row v-if="!isNew" class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Đăng bởi </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <span v-if="detailNews.author">{{ detailNews.author }}</span>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import HnrInput from "@/components/elements/hnr-input";
import HnrAreaInput from "@/components/elements/hnr-area-input";
import CkContent from "@/components/ck-content";
import mixin from "@/store/mixin";

import {
  SAVE_UPLOAD_LESSON
} from "@/store/lesson.module";


export default {
  mixins:[mixin],
  data() {
    return {
      img:{},
      dialog: false,
      detailNews: {},
      selected: [],
      value: 0,
      slug:this.$route.params.slug
    };
  },
  props: {
    new: Object,
    isNew: {type: Boolean, default: false},
    slug_message: {type: String},
  },

  watch: {
    new: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.detailNews = newVal;
      },
    },
  },
  components: {
    HnrInput,
    HnrAreaInput,
    CkContent,
    // HnrDatePicker,
  },

  methods: {
    onChangeImageUpload() {
      this.cardImg = URL.createObjectURL(this.img);
      this.imageError = "";
      let formData = new FormData();
      formData.append("upload", this.img);
      this.$store.dispatch(SAVE_UPLOAD_LESSON, formData).then((data) => {
        if (data) {
          this.detailNews.photo_url = data;
          this.$toasted.success("Tải lên thành công!", {
            position:'top-right',
            duration:3000
          });
        }
      });
    },
    clearImage() {
      this.detailNews.photo_url = "";
      this.show = false;
    },
    changeSlug(){
      this.detailNews.slugHasChanged = true;
    },

    updateName() {
      //generate slug
      this.$emit("input");
    },
    updateContent(val) {
      this.detailNews.description = val;
    },
    update() {
      this.$emit("input");
    },

    onKeyUp(value){
      this.detailNews.slug = this.sanitizeTitle(value);
    }
  }
}
;
</script>

<style lang="scss">
.upload-img {
  max-width: 300px; max-height: 300px;object-fit: contain
}
</style>
