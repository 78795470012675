<template>
  <v-container>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Từ khóa SEO </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input :text="detailLesson.seo_key" :blur="triggerSeo" @input="update" v-model="detailLesson.seo_key"></hnr-input>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
        <v-col cols="2" class="pa-1">
            <span> Tiêu đề SEO</span>
        </v-col>
        <v-col cols="10" class="pa-1">
            <hnr-input :text="detailLesson.seo_title" :blur="triggerSeo" @input="update" v-model="detailLesson.seo_title"></hnr-input>
        </v-col>
    </v-row>

    <v-row class="align-center justify-center">
        <v-col cols="2" class="pa-1">
            <span> Mô tả SEO</span>
        </v-col>
        <v-col cols="10" class="pa-1">
<!--            <hnr-area-input :text="detailLesson.seo_description" @input="update" v-model="detailLesson.seo_description"></hnr-area-input>-->
          <ck-content
              id="lesson_content"
              :content="detailLesson.seo_description"
              @getData="updateContent"
              v-model="detailLesson.seo_description"
          ></ck-content>
        </v-col>
    </v-row>
    <v-row class="align-center justify-center my-3">
      <v-col cols="2" class="pa-1">
        <span> SEO ảnh </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <v-file-input
            v-model="img"
            label="Tải file ảnh"
            @change="onChangeImageUpload"
            clearable
        ></v-file-input>
        <div >
          <img :src="detailLesson.seo_image ? detailLesson.seo_image : ''" class="upload-img"/>
        </div>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
      </v-col>
      <v-col cols="10" class="pa-1">
        <seo
            @getKeywords="getKeywords"
            :trigger_seo="count_trigger_seo"
            :text_seo="text_seo"
            :meta_title="detailLesson.seo_title"
            :content="detailLesson.description"
            :slug="detailLesson.slug"
            :meta_description="detailLesson.seo_description"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HnrInput from '@/components/elements/hnr-input'
import HnrAreaInput from '@/components/elements/hnr-area-input'
import CkContent from "@/components/ck-content";
import Seo from "@/components/seo";
import {SAVE_UPLOAD_LESSON} from "@/store/lesson.module";
export default {
  props: {
    lesson: Object,
  },
  watch: {
      lesson: {
          deep: true,
          immediate: true,
          handler(newVal) {
              this.detailLesson = newVal;
          }
      }
  },
  data() {
    return {
      detailLesson: {},
      count_trigger_seo: 0,
      text_seo: "",
      img:null,
    };
  },
  components: {
      HnrInput,
      HnrAreaInput,
    CkContent,
    Seo
  },
  methods: {
    onChangeImageUpload() {
      this.cardImg = URL.createObjectURL(this.img);
      this.imageError = "";
      let formData = new FormData();
      formData.append("upload", this.img);
      this.$store.dispatch(SAVE_UPLOAD_LESSON, formData).then((data) => {
        if (data) {
          this.detailLesson.seo_image = data;
          console.log(this.detailLesson.seo_image )
          this.$toasted.success("Tải lên thành công!", {
            position:'top-right',
            duration:3000
          });
        }
      });
    },
    triggerSeo(){
      this.count_trigger_seo++;
    },
    getKeywords(keywords){
      this.detailLesson.seo_key = keywords
    },
    updateContent(val) {
      this.detailLesson.seo_description = val;
    },
    onClick() {
    },
    update() {
        this.$emit("input");
    },
  },
};
</script>

<style lang="scss">
</style>
