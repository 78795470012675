import ApiService from "@/common/api.service";

// action types
export const SAVE_GRADE = "/grade/save";
export const UPDATE_GRADE = "/grade/update";

export const GET_LIST_GRADE = "/grade/list";
export const GET_DETAIL_GRADE = "/grade/detail";
export const DELETE_GRADE = "/grade/delete";

export const SET_DEFAULT_GRADE = "setDefaultGrade";


// mutation types
export const SET_GRADE_LIST = "setGradeList";
export const SET_GRADE_DETAIL = "setGradeDetail";
export const SET_ERROR = "setError";

const state = {
      errors: null,
      grades: [],
      grade: {},
      images: [],
};

const getters = {
      detailGrade(state) {
            return state.grade;
      },
      listGrade(state) {
            return state.grades;
      },
      // listImages(state) {
      //       return state.images;
      // }
};

const actions = {
      [GET_LIST_GRADE](context, payload) {
            return new Promise((resolve, reject) => {
                  ApiService.query(GET_LIST_GRADE, payload)
                        .then(({ data }) => {
                              context.commit(SET_GRADE_LIST, data);
                              resolve(data);
                        })
                        .catch(({ response }) => {
                              context.commit(SET_ERROR, response);
                              reject(response)
                        });
            });
      },
      [GET_DETAIL_GRADE](context, payload) {
            return new Promise((resolve, reject) => {
                  ApiService.get(GET_DETAIL_GRADE, payload)
                        .then(({ data }) => {
                              context.commit(SET_GRADE_DETAIL, data);
                              resolve(data);
                        })
                        .catch(({ response }) => {
                              context.commit(SET_ERROR, response);
                              reject(response)
                        });
            });
      },
      [SAVE_GRADE](context, payload) {
            return new Promise((resolve, reject) => {
                  ApiService.post(SAVE_GRADE, payload)
                        .then(({ data }) => {
                              resolve(data);
                        })
                        .catch(({ response }) => {
                              context.commit(SET_ERROR, response.data.errors);
                              reject(response);
                        });
            });
      },
      [UPDATE_GRADE](context, payload) {
            return new Promise((resolve, reject) => {
                  ApiService.post(UPDATE_GRADE, payload)
                        .then(data => {
                              resolve(data);
                        })
                        .catch(({ response }) => {
                              reject(response);
                        });
            });
      },
      [DELETE_GRADE](context, payload) {
            return new Promise((resolve, reject) => {
                  ApiService.delete(DELETE_GRADE, payload)
                        .then((data) => {
                              resolve(data);
                        })
                        .catch(({ response }) => {
                              context.commit(SET_ERROR, response.data.errors);
                              reject(response);
                        });
            });
      },
      [SET_DEFAULT_GRADE](context) {
            context.commit(SET_GRADE_DETAIL, {});
      },
};

const mutations = {
      [SET_ERROR](state, error) {
            state.errors = error;
      },
      [SET_GRADE_LIST](state, data) {
            state.grades = data;
            if (!state.grades) {
                  return null;
            }
      },
      [SET_GRADE_DETAIL](state, data) {
            state.grade = data;
      },


};

export default {
      state,
      actions,
      mutations,
      getters
};
