import ApiService from "@/common/api.service";

// action types
export const GET_LIST_FOLLOW_ACTION = "/follow-action/list";
export const UPDATE_FOLLOW_ACTION = "/follow-action/update-index";

// mutation types
export const SET_FOLLOW_ACTION = "setFollowAction";
export const SET_ERROR = "setError";

const state = {
    errors: null,
    follow_actions: []
};

const getters = {
    listFollow(state) {
        return state.follow_actions;
    }
};

const actions = {
    [GET_LIST_FOLLOW_ACTION](context,payload) {
        return new Promise(resolve => {
            ApiService.query(GET_LIST_FOLLOW_ACTION,payload)
                .then(({ data }) => {
                    if (data) {
                        context.commit(SET_FOLLOW_ACTION, data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                });
        });
    },
    [UPDATE_FOLLOW_ACTION](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(UPDATE_FOLLOW_ACTION, payload)
                .then(data => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_FOLLOW_ACTION](state, data) {
        state.follow_actions = data;
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
