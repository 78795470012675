<template>
  <v-container>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Tên tiếng anh</span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input :text="subject.name_en" @input="update" v-model="subject.name_en"></hnr-input>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Slug SEO tiếng anh</span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input :text="subject.seo_slug_en" @input="update" v-model="subject.seo_slug_en"></hnr-input>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Từ khóa SEO tiếng anh</span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input :text="detailSubject.seo_key_en" @input="update" v-model="detailSubject.seo_key_en"></hnr-input>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Tiêu đề SEO tiếng anh</span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input :text="detailSubject.seo_name_en" @input="update" v-model="detailSubject.seo_name_en"></hnr-input>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Nội dung SEO tiếng anh</span>
      </v-col>
      <v-col cols="10" class="pa-1 mb-5">
        <ck-content
            id="lesson_content"
            :content="detailSubject.seo_description_en"
            @getData="updateContent"
            v-model="detailSubject.seo_description_en"
        ></ck-content>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import HnrInput from '@/components/elements/hnr-input'
import HnrAreaInput from '@/components/elements/hnr-area-input'
import CkContent from "@/components/ck-content";
export default {
  props: {
    subject: Object,
  },
  data() {
    return {
      detailSubject: {}
    };
  },
  watch: {
    subject: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.detailSubject = newVal;
      }
    }
  },
  components: {
    HnrInput,
    HnrAreaInput,
    CkContent
  },
  methods: {
    updateContent(val) {
      this.detailSubject.seo_description_en = val;
    },
    onClick() {
    },
    update() {
      this.$emit("input");
    },
  },
};
</script>

<style lang="scss">
</style>
