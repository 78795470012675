<template>
  <div data-app>
    <template>
      <v-data-table
          :headers="headers"
          :items="orders"
          class="elevation-1"
          :footer-props="{ itemsPerPageOptions: [5, 10, 15, 20] }"
          show-select
          @input="onRowSelected"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-card-title class="text-h5">
              <v-row class="mt-7 px-0 mx-0">
                <v-col cols="2" class="my-0 py-0">
                  <v-text-field
                      outlined
                      dense
                      placeholder="Họ tên"
                      v-model="name"
                  ></v-text-field>
                </v-col>
                <v-col cols="2" class="my-0 py-0">
                  <v-text-field
                      dense
                      outlined
                      placeholder="Email"
                      v-model="email"
                  ></v-text-field>
                </v-col>
                <v-col cols="2" class="my-0 py-0">
                  <v-text-field
                      dense
                      outlined
                      placeholder="SĐT"
                      v-model="phone"
                  ></v-text-field>
                </v-col>
                <v-col cols="2" class="my-0 py-0">
                  <v-select
                      :items="items"
                      placeholder="Trạng thái"
                      outlined
                      dense
                      item-text="value"
                      item-value="id"
                      clearable
                      v-model="status"
                  ></v-select>
                </v-col>
                <v-col cols="2" class="my-0 py-0">
                  <v-select
                      :items="countries"
                      placeholder="Quốc gia"
                      outlined
                      dense
                      item-text="value"
                      item-value="id"
                      clearable
                      v-model="country"
                  ></v-select>
                </v-col>
                <v-col cols="2" class="ma-0 pa-0" style="width: 100%">
                  <v-btn color="primary" @click="getOrders" class="btn-export">Tìm kiếm</v-btn>
                  <v-btn class="ml-2 btn-export" :disabled="isShowExport" color="primary" @click="exportOrder">Export</v-btn>
                </v-col>
              </v-row>
            </v-card-title>
          </v-toolbar>
        </template>
        <template v-slot:item.total_price="{ item }">
          {{item.country == 0 ? formatCurrency(item.total_price) : formatCurrencyKr(item.total_price)}}
        </template>
        <template v-slot:item.payment="{ item }">
          {{item.payment == 0 ? 'Bank' : 'COD'}}
        </template>
        <template v-slot:item.status="{ item }">
          <span v-if="item.status == 0" class="font-weight-bold">Chưa thanh toán</span>
          <span v-else class="font-weight-bold" style="color: #00E676">Đã thanh toán</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </template>
      </v-data-table>
      <!-- DIALOG DELETE  -->
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline"
          >Bạn có chắc muốn xóa không?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn width="70" color="blue darken-1" text @click="closeDelete"
            >Thoát</v-btn
            >
            <v-btn width="70" color="blue darken-1" text @click="deleteItemConfirm"
            >Xóa</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { SET_LOADING } from "@/store/loading.module";
import { GET_LIST_ORDER, DELETE_ORDER_BOOK, EXPORT_ORDER} from "@/store/order.module";
import { ERROR } from "@/store/alert.module";

export default {
  data: () => ({
    headers: [
      {
        text: "id",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Tên khách hàng", value: "user_name" },
      { text: "Email", value: "email" },
      { text: "SĐT", value: "phone" },
      { text: "Tổng giá tiền", value: "total_price" },
      { text: "Địa chỉ", value: "address" },
      { text: "Thanh toán", value: "payment" },
      { text: "Trạng thái", value: "status" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedId: -1,
    dialogDelete: false,
    items: [
      {id: 0, value: 'Chưa thanh toán'},
      {id: 1, value: 'Đã thanh toán'},
    ],
    countries: [
      {id: 0, value: 'Việt Nam'},
      {id: 1, value: 'Hàn Quốc'},
    ],
    name:"",
    email:"",
    phone:"",
    status:"",
    country:null,
    selectedRows: [],
    isShowExport: true
  }),

  computed: {
    ...mapGetters({
      orders: "listOrder",
    }),
  },
  mounted() {
    this.getOrders();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Đơn hàng", route: "/list-book" },
    ]);
  },
  watch: {
    selectedRows: {
      immediate: true,
      deep: true,
      handler(val) {
        if(val && val.length > 0) this.isShowExport = false;
        else  this.isShowExport = true;
      }
    }
  },
  methods: {
    onRowSelected(selectedItems) {
      this.selectedRows = selectedItems.map(item => item.id);
    },
    exportOrder() {
      let ids = JSON.stringify(this.selectedRows)
      this.$store.dispatch(SET_LOADING, true);
      this.$store.dispatch(EXPORT_ORDER, {ids: ids})
          .then((response) => {
            if (response) {
              const link = document.createElement('a');
              link.href = response.url;
              link.setAttribute('download','order_export.xlsx');
              document.body.appendChild(link);
              link.click();
              this.$toasted.success("Export thành công!", {
                position: 'top-right',
                duration: 3000
              });
            }
            else {
              this.$toasted.error("Không thể export file!", {
                position: 'top-right',
                duration: 3000
              });
            }
            this.$store.dispatch(SET_LOADING, false);
          })
          .catch(() => {
            this.$store.dispatch(SET_LOADING, false);
          });
    },
    formatCurrency(price) {
      const formatter = new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
      return formatter.format(parseInt(price));
    },
    formatCurrencyKr(price) {
      const formatter = new Intl.NumberFormat('ko-Kr', {
        style: 'currency',
        currency: 'KRW',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
      return formatter.format(parseInt(price));
    },
    getOrders() {
      let payload = {
        name: this.name,
        email: this.email,
        status: this.status,
        phone: this.phone,
        country: this.country,
      }

      this.$store.dispatch(SET_LOADING, true);
      this.$store
          .dispatch(GET_LIST_ORDER, payload)
          .then(() => {
            this.$store.dispatch(SET_LOADING, false);
          })
          .catch(() => {
            this.$store.dispatch(SET_LOADING, false);
          });
    },
    addNewItem() {
      this.$router.push({ name: "add-book" });
    },

    editItem(item) {
      this.$router.push({ name: "update-order", params: { id: item.id }});
    },

    deleteItem(item) {
      this.editedId = item.id;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.$store.dispatch(DELETE_ORDER_BOOK, this.editedId).then((data) => {
        if (data.status) {
          this.getOrders()
        } else {
          if(data.errorCode == 424){
            this.$store.dispatch(ERROR, data.message, {root: true});
          } else{
            this.$store.dispatch(ERROR, "Xóa thất bại!", {
              root: true,
            });
          }
        }
      });

      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },
  },
};
</script>
<style>
#my-strictly-unique-vue-upload-multiple-image {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
.btn-export {
  width: 75px !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
