
<template>
  <v-container>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span>Tên tiếng anh</span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input :text="detailCategory.name_en" @input="update" v-model="detailCategory.name_en"></hnr-input>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span>Tiêu đề SEO tiếng anh</span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input :text="detailCategory.seo_title" @input="update" v-model="detailCategory.seo_title"></hnr-input>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span>Mô tả SEO tiếng anh</span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-area-input :text="detailCategory.seo_description" @input="update" v-model="detailCategory.seo_description"></hnr-area-input>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span>Từ khóa SEO tiếng anh</span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-area-input :text="detailCategory.seo_key" @input="update" v-model="detailCategory.seo_key"></hnr-area-input>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HnrInput from '@/components/elements/hnr-input'
import HnrAreaInput from '@/components/elements/hnr-area-input'
export default {
  props: {
    category: Object,
  },
  watch: {
    category: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.detailCategory = newVal;
      }
    }
  },
  data() {
    return {
      detailCategory: {}
    };
  },
  components: {
    HnrInput,
    HnrAreaInput
  },
  methods: {
    onClick() {
    },
    update() {
      this.$emit("input");
    },
  },
};
</script>

<style lang="scss">
</style>
