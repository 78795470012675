<template>
  <div>
    <v-row class="align-end justify-end ma-2">
      <v-btn color="accent" light @click="addExam">Thêm bài thực hành</v-btn>
    </v-row>
    <v-data-table
        :headers="headers"
        :items="exam_related"
        class="elevation-1"
        :page.sync="page"
        :items-per-page="itemsPerPage"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-card-title class="text-h5 pa-0">
            Danh sách bài thực hành liên quan
            <v-spacer></v-spacer>
          </v-card-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:item.open_in_new="{ item }">
        <v-icon small class="mr-2" @click="openInNew(item)">
          mdi-open-in-new
        </v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-results>
        <v-alert :value="true" color="error" icon="warning">
          Your search found no results.
        </v-alert>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {ERROR, SUCCESS} from "@/store/alert.module";
import {DELETE_EXAM_RELATED, GET_LIST_EXAM_RELATED} from "@/store/lesson.module";

export default {

  props: {
    lesson: Object,
  },
  data: () => ({
    page: 1,
    itemsPerPage: 10,
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id"
      },
      {text: "Tên bài thi", value: "exam_name"},
      {text: "Slug", value: "exam_slug"},
      {text: "", value: "open_in_new"},
      {text: "Xóa", value: "actions"},
    ],
    total: 0,
    keyword : ''
  }),

  computed: {
    ...mapGetters({
      exam_related: "listExam",
    }),
  },

  created() {
   this.$store.dispatch(GET_LIST_EXAM_RELATED,{lesson_id: this.lesson.id})
  },
  methods: {
    addExam() {
      this.$emit('examDialog')
    },

    updateItemPerPage($event) {
      this.itemsPerPage = $event;

    },
    updatePage($event) {
      this.page = $event;
    },
    openInNew(item) {
      if (item) {
        window.open(process.env.VUE_APP_FRONT_URL + "/thuc-hanh-tieng-han/" + item.exam_slug, '_blank')
      }
    },
    deleteItem(item) {
      const index = this.exam_related.indexOf(item);
      confirm("Bạn có chắc muốn xóa không?") &&
      this.$store.dispatch(DELETE_EXAM_RELATED, item.id).then((data) => {
        if (data.status) {
          this.exam_related.splice(index, 1);
        } else {
          if (data.errorCode == 424) {
            this.$store.dispatch(ERROR, data.message, {root: true});
          } else {
            this.$store.dispatch(ERROR, "Xóa thất bại!", {
              root: true,
            });
          }
        }
      });

    },
  }
};

</script>