<template>
  <v-container>
    <v-row class="align-center justify-center">
        <v-col cols="2" class="pa-1">
            <span> Tiêu đề SEO</span>
        </v-col>
        <v-col cols="10" class="pa-1">
            <hnr-input :text="grade.seo_title" :blur="triggerSeo" @input="update" v-model="grade.seo_title"></hnr-input>
        </v-col>
    </v-row>
    <v-row class="align-center justify-center">
        <v-col cols="2" class="pa-1">
            <span> Từ khóa SEO </span>
        </v-col>
        <v-col cols="10" class="pa-1">
            <hnr-area-input :text="grade.seo_key" :blur="triggerSeo" @input="update" v-model="grade.seo_key"></hnr-area-input>
        </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Mô tả SEO</span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ck-content
            id="lesson_content"
            :content="grade.seo_description"
            @getData="updateContent"
            @getSeo="triggerSeo"
            v-model="grade.seo_description"
        ></ck-content>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
      </v-col>
      <v-col cols="10" class="pa-1">
        <seo
            @getKeywords="getKeywords"
            :trigger_seo="count_trigger_seo"
            :text_seo="text_seo"
            :meta_title="grade.seo_title"
            :content="grade.seo_description"
            :slug="grade.slug"
            :meta_description="grade.seo_description"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HnrInput from '@/components/elements/hnr-input'
import HnrAreaInput from '@/components/elements/hnr-area-input'
import Seo from "@/components/seo";
import CkContent from "@/components/ck-content";

export default {
  props: {
    grade: Object,
  },
  watch: {
      grade: {
          deep: true,
          immediate: true,
          handler(newVal) {
              this.grade = newVal;
          }
      }
  },
  data() {
    return {
      count_trigger_seo: 0,
      text_seo: "",
    }
  },
  components: {
      HnrInput,
      HnrAreaInput,
    Seo,
    CkContent
  },
  methods: {
    triggerSeo(){
      this.count_trigger_seo++;
    },
    getKeywords(keywords){
      this.grade.seo_key = keywords
    },
    updateContent(val) {
      this.grade.seo_description = val;
    },
    onClick() {
    },
    update() {
        this.$emit("input");
    },
  },
};
</script>

<style lang="scss">
</style>
