<template>
  <v-container>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Từ khóa SEO </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input :text="detailSubject.seo_key"  :blur="triggerSeo" @input="update" v-model="detailSubject.seo_key"></hnr-input>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
        <v-col cols="2" class="pa-1">
            <span> Tiêu đề SEO</span>
        </v-col>
        <v-col cols="10" class="pa-1">
            <hnr-input :text="detailSubject.seo_name"  :blur="triggerSeo" @input="update" v-model="detailSubject.seo_name"></hnr-input>
        </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Nội dung SEO</span>
      </v-col>
      <v-col cols="10" class="pa-1 mb-5">
        <ck-content
            id="lesson_content"
            :content="detailSubject.seo_description"
            @getData="updateContent"
            @getSeo="triggerSeo"
            v-model="detailSubject.seo_description"
        ></ck-content>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
      </v-col>
      <v-col cols="10" class="pa-1">
        <seo
            @getKeywords="getKeywords"
            :trigger_seo="count_trigger_seo"
            :text_seo="text_seo"
            :meta_title="detailSubject.seo_name"
            :content="detailSubject.seo_description"
            :slug="detailSubject.slug"
            :meta_description="detailSubject.seo_description"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HnrInput from '@/components/elements/hnr-input'
import HnrAreaInput from '@/components/elements/hnr-area-input'
import CkContent from "@/components/ck-content";
import Seo from "@/components/seo";
export default {
  props: {
    subject: Object,
  },
  data() {
    return {
      detailSubject: {},
      count_trigger_seo: 0,
      text_seo: "",
    };
  },
  watch: {
      subject: {
          deep: true,
          immediate: true,
          handler(newVal) {
              this.detailSubject = newVal;
          }
      }
  },
  components: {
      HnrInput,
      HnrAreaInput,
    CkContent,
    Seo
  },
  methods: {
    triggerSeo(){
      this.count_trigger_seo++;
    },
    getKeywords(keywords){
      this.detailSubject.seo_key = keywords
    },
    updateContent(val) {
      this.subject.seo_description = val;
    },
    onClick() {
    },
    update() {
        this.$emit("input");
    },
  },
};
</script>

<style lang="scss">
</style>
