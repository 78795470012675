<template>
  <v-container>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Tên bài viết </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider
            name="name"
            rules="required"
            v-slot="{ errors }"
        >
          <hnr-input
              :text="detailLesson.name"
              @onKeyUp="onKeyUp"
              v-model="detailLesson.name"
          ></hnr-input>
          <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Slug </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input
            :text="detailLesson.slug"
            v-model="detailLesson.slug"
        ></hnr-input>
        <span class="validate-error text-danger text-danger">
            {{ slug_message }}
          </span>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Kích hoạt </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <v-switch v-model="detailLesson.status"></v-switch>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Sắp xếp </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider
            name="order"
            rules="number"
            v-slot="{ errors }"
        >
          <hnr-input
              :text="convertNumberToString(detailLesson.order)"
              @input="update"
              v-model="detailLesson.order"
          ></hnr-input>
          <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
        </ValidationProvider>

      </v-col>
    </v-row>
    <v-row class="align-center justify-center my-3">
      <v-col cols="2" class="pa-1">
        <span> Tải tài liệu </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <v-file-input
            v-model="src"
            label="Tải file tài liệu"
            @change="onChangeFileUpload"
            @click:clear="clearImage"
        ></v-file-input>
        <div>
          <a :href="detailLesson.src" target="_blank">{{detailLesson.src}}</a>
        </div>
        <span class="validate-error text-danger text-danger">
            {{ slug_message }}
          </span>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center my-3">
      <v-col cols="2" class="pa-1">
        <span> Tải file audio </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <v-file-input
            v-model="audio"
            label="Tải file audio"
            @change="onChangeFileAudio"
            clearable
        ></v-file-input>
        <div>
          <audio controls muted id="audio" :src="url_demo ? url_demo : urlAudio">
            <source :src="url_demo ? url_demo : urlAudio" type="audio/mpeg">
          </audio>
        </div>
        <span class="validate-error text-danger text-danger">
            {{ slug_message }}
          </span>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center my-3">
      <v-col cols="2" class="pa-1">
        <span> Tải ảnh </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <v-file-input
            v-model="img"
            label="Tải file ảnh"
            @change="onChangeImageUpload"
            clearable
        ></v-file-input>
        <div >
          <img :src="detailLesson.img ? detailLesson.img : ''" class="upload-img"/>
        </div>
        <span class="validate-error text-danger text-danger">
            {{ slug_message }}
          </span>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Link youtube</span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input
            :text="detailLesson.link_yt"
            @input="update"
            v-model="detailLesson.link_yt"
        ></hnr-input>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center mt-2 mb-2">
      <v-col cols="2" class="pa-1">
        <span> Chủ đề cha </span>
      </v-col>
      <v-col cols="8" class="pa-1">
          <span>
            {{ detailLesson.topics ? detailLesson.topics.name : "" }}
          </span>
      </v-col>

      <v-col cols="2" class="pa-1">
        <v-btn color="primary" @click="updateParent"
        >Cập nhật chủ đề cha
        </v-btn
        >
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Mô tả ngắn</span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input
            :text="detailLesson.short_content"
            @input="update"
            v-model="detailLesson.short_content"
        ></hnr-input>
      </v-col>
    </v-row>

    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Nội dung </span>
      </v-col>
      <v-col cols="12" class="pa-1">
        <v-row class="align-center justify-center my-3">
          <v-col cols="3" class="pl-3">
            <span> Tải file audio thêm vào ckeditor</span>
          </v-col>
          <v-col cols="9" class="pa-1">
            <v-file-input
                v-model="audioCk"
                label="Tải file audio"
                @change="onChangeFileAudioCkeditor"
                clearable
            ></v-file-input>
            <div class="d-flex">
              <audio controls muted id="audio" :src="url_demo_ck ? url_demo_ck : ''" style="z-index: 20">
                <source :src="url_demo_ck ? url_demo_ck : ''" type="audio/mpeg">
              </audio>
              <input type="text" class="mt-1 ml-5" style="position: absolute;z-index: 1" ref="input"/>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      class="mt-2 ml-3"
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click="copy"
                  >
                    Copy
                  </v-btn>
                </template>
                <span>Copy xong hãy thêm vào Insert Html</span>
              </v-tooltip>

            </div>
            <span class="validate-error text-danger text-danger">
            {{ slug_message }}
          </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="pa-1">
        <ValidationProvider
            name="content"
            rules="required"
            v-slot="{ errors }"
        >
          <ck-content
              id="lesson_content"
              :content="detailLesson.content"
              :slug="slug"
              @getData="updateContent"
              v-model="detailLesson.content"
          ></ck-content>
          <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
        </ValidationProvider>

      </v-col>
    </v-row>

    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Tóm tắt bài học </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-area-input
            :text="detailLesson.summary"
            @input="update"
            v-model="detailLesson.summary"
        ></hnr-area-input>
      </v-col>
    </v-row>

    <v-row v-if="!isNew" class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Số lượt xem </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <span> {{ detailLesson.count_view }}</span>
      </v-col>
    </v-row>

    <v-row v-if="!isNew" class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Đăng bởi </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <span v-if="detailLesson.publish_by">{{ detailLesson.publish_by }}</span>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="600px" min-height="600px">
      <v-card>
        <v-card-title class="text-center">
          <span class="headline">Thay đổi chủ đề cha</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="align-start justify-center ma-0">
              <v-col cols="8">
                <hnr-input :text="search" v-model="search" @active="searchCategoriesByName"></hnr-input>
              </v-col>
              <v-col cols="4">
                <v-btn color="primary" width="100" @click="searchCategoriesByName"
                >Tìm kiếm
                </v-btn
                >
              </v-col>

            </v-row>
            <v-row class="align-start justify-center ma-0">
              <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :items="suggestNewCategories"
                  :single-select="singleSelect"
                  item-key="id"
                  show-select
                  class="elevation-1"
              >
              </v-data-table>
            </v-row>
            <v-row class="align-center justify-center ma-0 pt-5">
              <v-btn color="primary" @click="saveNewsCategory"
              >Lưu chủ đề cha
              </v-btn
              >
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import HnrInput from "@/components/elements/hnr-input";
import HnrAreaInput from "@/components/elements/hnr-area-input";
// import HnrDatePicker from "@/components/elements/hnr-date-picker";
import CkContent from "@/components/ck-content";
import {SET_LOADING} from "@/store/loading.module";
import {SEARCH_TOPIC_BY_NAME} from "@/store/topic.module";

import {mapState} from "vuex";
import mixin from "@/store/mixin";
import {
  SAVE_UPLOAD_LESSON
} from "@/store/lesson.module";
import {
  SAVE_AUDIO
} from "@/store/image.module";

export default {
  mixins:[mixin],
  data() {
    return {
      url:'',
      url_demo:'',
      url_demo_ck:'',
      src: {},
      img:{},
      audio:{},
      audioCk:{},
      dialog: false,
      detailLesson: {},
      selected: [],
      value: 0,
      search: null,
      singleSelect: true,
      suggestNewCategories: [],
      headers: [
        {
          text: "id",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
      ],
      slug:this.$route.params.slug
    };
  },
  props: {
    lesson: Object,
    isNew: {type: Boolean, default: false},
    slug_message: {type: String},
  },
  computed: {
    ...mapState({
      nameOfNewsCategories: (state) => state.newscategory.nameOfNewscategories,
    }),
    urlAudio(){
      let url = process.env.VUE_APP_API_URL
      let replace = url.replace('admin','')
      this.detailLesson = this.lesson;
      let path = this.detailLesson && this.detailLesson.file_audio && this.detailLesson.file_audio.path ?this.detailLesson.file_audio.path:'';
      if(path) {
        return replace + path
      }
    }
  },
  watch: {
    lesson: {
      deep: true,
      immediate: true,
      handler(newVal) {
        let url = process.env.VUE_APP_API_URL
        let replace = url.replace('admin','')
        this.detailLesson = newVal;
        let path = this.detailLesson && this.detailLesson.file_audio && this.detailLesson.file_audio.path ?this.detailLesson.file_audio.path:'';
        if(path) {
          this.url = replace + path
        }

      },
    },
    nameOfNewsCategories: {
      deep: true,
      handler(newVal) {
        this.suggestNewCategories = newVal;
      },
    },
  },
  components: {
    HnrInput,
    HnrAreaInput,
    CkContent,
    // HnrDatePicker,
  },

  methods: {
    onChangeFileAudio() {
      this.cardImg = URL.createObjectURL(this.audio);
      this.imageError = "";
      let formData = new FormData();
      formData.append("file", this.audio);
      this.$store.dispatch(SAVE_AUDIO, formData).then((data) => {
        if (data) {
          let url = process.env.VUE_APP_API_URL
          let replace = url.replace('admin','')
          this.detailLesson.audio = data.id;
          this.url_demo = replace + data.path;
          this.$toasted.success("Tải lên thành công!", {
            position:'top-right',
            duration:3000
          });
        }
      });
    },
    onChangeFileAudioCkeditor() {
      this.cardImg = URL.createObjectURL(this.audioCk);
      this.imageError = "";
      let formData = new FormData();
      formData.append("file", this.audioCk);
      this.$store.dispatch(SAVE_AUDIO, formData).then((data) => {
        if (data) {
          let url = process.env.VUE_APP_API_URL
          let replace = url.replace('admin','')
          this.url_demo_ck = replace + data.path;
          this.$toasted.success("Tải lên thành công!", {
            position:'top-right',
            duration:3000
          });
        }
      });
    },
    copy() {
      this.text =(
          '<audio controls>' +
              `<source src="${this.url_demo_ck?this.url_demo_ck:''}" type="audio/mpeg">`+
          '</audio>'
      )

      console.log(this.text)
      let copyText = this.$refs.input;
      copyText.value=this.text
      copyText.select();
      document.execCommand('copy');
    },
    onChangeFileUpload() {
      this.cardImg = URL.createObjectURL(this.src);
      this.imageError = "";
      let formData = new FormData();
      formData.append("upload", this.src);
      this.$store.dispatch(SAVE_UPLOAD_LESSON, formData).then((data) => {
        if (data) {
          this.detailLesson.src = data;
          this.$toasted.success("Tải lên thành công!", {
            position:'top-right',
            duration:3000
          });
        }
      });
    },
    onChangeImageUpload() {
      this.cardImg = URL.createObjectURL(this.img);
      this.imageError = "";
      let formData = new FormData();
      formData.append("upload", this.img);
      this.$store.dispatch(SAVE_UPLOAD_LESSON, formData).then((data) => {
        if (data) {
          this.detailLesson.img = data;
          this.$toasted.success("Tải lên thành công!", {
            position:'top-right',
            duration:3000
          });
        }
      });
    },
    clearImage() {
      this.detailLesson.src = "";
      this.show = false;
    },
    changeSlug(){
      this.detailLesson.slugHasChanged = true;
    },
    searchCategoriesByName() {
      if (this.search) {
        this.$store.dispatch(SET_LOADING, true);
        this.$store
            .dispatch(SEARCH_TOPIC_BY_NAME, {name: this.search})
            .then(() => {
              this.$store.dispatch(SET_LOADING, false);
            })
            .catch(() => {
              this.$store.dispatch(SET_LOADING, false);
            });
      }
    },
    saveNewsCategory() {
      if (this.selected && this.selected.length > 0) {
        this.detailLesson.topics = {
          id: this.selected[0].id,
          name: this.selected[0].name,
        };
      } else {
        this.detailLesson.topics = {
          id: 0,
          name: null,
        };
      }
      this.dialog = false;
    },
    getPicker(val) {
      this.detailLesson.created_at = val
    },
    updateName() {
      //generate slug
      this.$emit("input");
    },
    updateContent(val) {
      this.detailLesson.content = val;
    },
    updateParent() {
      this.dialog = true;
    },
    update() {
      this.$emit("input");
    },
    isRealValue(obj) {
      return obj && obj !== 'null' && obj !== 'undefined';
    }
    ,
    convertNumberToString(number) {
      if (this.isRealValue(number)) {
        return number.toString();
      }
      return "0";
    },
    onKeyUp(value){
      this.detailLesson.slug = this.sanitizeTitle(value);
    }
  }
}
;
</script>

<style lang="scss">
.upload-img {
  max-width: 300px; max-height: 300px;object-fit: contain
}
</style>
