<template>
  <div data-app>
    <template>
      <v-container fluid>
        <v-row class="ma-0">
          <v-col class="pb-4 pl-0">
            <span class="text-h5">Tìm kiếm</span>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="6" class="pa-0">
            <v-row class="ma-0 pt-3">
              <v-col cols="3" class="pa-0">
                <span class="text-body-1">Tên bài viết</span>
              </v-col>
              <v-col cols="9" class="pa-0">
                <hnr-input @active="search" v-model="keyword"></hnr-input>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="align-center justify-center ma-0">
          <v-col cols="6" class="pa-0">
            <v-btn color="primary" width="100" @click="search">Tìm kiếm</v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-data-table
          :headers="headers"
          :items="answers"
          class="elevation-1"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          :server-items-length="total"
          @update:items-per-page="updateItemPerPage"
          @update:page="updatePage"
          :footer-props="{ itemsPerPageOptions: [5, 10, 15, 20] }"
          @page-count="pageCount = $event"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-card-title class="text-h5 pa-0">
              Danh sách câu trả lời
              <v-spacer></v-spacer>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-btn color="primary" width="100" dark class="mb-2" to="/answer/detail" target="_blank"
            >Thêm mới
            </v-btn
            >
          </v-toolbar>
        </template>
        <template v-slot:item.content="{ item }">
          <div v-html="item.content" class="content-ask"></div>
        </template>
          <template v-slot:item.ask="{ item }">
            <div v-if="item.ask" v-html="item.ask.content" class="content-ask"></div>
          </template>
        <template v-slot:item.status="{ item }">
          <v-switch v-model="item.status" :disabled="true"></v-switch>
        </template>
        <template v-slot:item.act="{ item }">
          <v-switch v-model="item.act" :disabled="true"></v-switch>
        </template>
        <template v-slot:item.vote_summary="{ item }">
            <div v-if="!item.vote_summary || (item.vote_summary && !item.vote_summary.score)">Chưa bình chọn</div>
           <div v-else>{{item.vote_summary.score}}</div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete</v-icon>
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="7"
        ></v-pagination>
      </div>
    </template>

    <!-- DIALOG DELETE  -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline"
        >Bạn có chắc muốn xóa không?
        </v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn width="70" color="blue darken-1" text @click="closeDelete"
          >Thoát
          </v-btn
          >
          <v-btn width="71" color="blue darken-1" text @click="deleteItemConfirm"
          >Xóa
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {validationMixin} from "vuelidate";
import HnrInput from "@/components/elements/hnr-input";
import {SET_LOADING} from "@/store/loading.module";
import {ERROR} from "@/store/alert.module";

import {COUNT_ANSWER, LIST_ANSWERS, DELETE_ANSWER} from "@/store/answer.module";
import MathJaxPreview from "@/components/math/MathJaxPreview";


export default {
  mixins: [validationMixin],
  components: {
    MathJaxPreview,
    HnrInput,
  },
  data: () => ({
    page: 1,
    itemsPerPage: 10,
    pageCount: 0,
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id",
      },
      {text: "Câu trả lời", value: "content"},
      {text: "Câu hỏi", value: "ask"},
      {text: "Người đăng", value: "user.email"},
      {text: "Điểm bình chọn", value: "vote_summary"},
      {text: "Actions", value: "actions", sortable: false},
    ],
    publish_by: "",
    keyword: "",
    category: "",
    startDate: "",
    endDate: "",
    startDialog: "",
    endDialog: "",
    dialog: false,
    dialogDelete: false,
  }),

  computed: {
    ...mapGetters({
      answers: "listAnswer",
      total:"totalAnswer"
    }),
  },
  mounted() {
    this.count();
    this.getAnswers();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Câu trả lời", route: "Câu trả lời"},
    ]);
  },
  watch: {},
  methods: {
    count() {
      var payload = {
        answer: this.keyword,
      };
      this.$store.dispatch(COUNT_ANSWER, payload);
    },
    search() {
      this.count();
      this.getAnswers();
    },
    getAnswers() {
      var payload = {
        answer: this.keyword,
        page: this.page,
        page_count: this.itemsPerPage,
      };
      this.$store.dispatch(SET_LOADING, true);
      this.$store
          .dispatch(LIST_ANSWERS, payload)
          .then(() => {
            this.$store.dispatch(SET_LOADING, false);
          })
          .catch(() => {
            this.$store.dispatch(SET_LOADING, false);
          });
    },

    updateItemPerPage($event) {
      this.itemsPerPage = $event;
      this.getAnswers();
    },

    updatePage($event) {
      this.page = $event;
      this.getAnswers();
    },

    editItem(item) {
      let routeData = this.$router.resolve({
        name: "answer-edit",
        params: {id: item.id},
      });
      window.open(routeData.href, '_blank');
    },

    deleteItem(item) {
      this.editedIndex = this.answers.indexOf(item);
      this.editedId = item.id;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.$store.dispatch(DELETE_ANSWER, this.editedId).then((data) => {
        if (data.status) {
          this.answers.splice(this.editedIndex, 1);
        } else {
          if(data.errorCode == 424){
            this.$store.dispatch(ERROR, data.message, {
              root: true,
            });
          }else {
            this.$store.dispatch(ERROR, "Xóa thất bại!", {
              root: true,
            });
          }

        }
      });

      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
    },

    addNewItem() {
      this.$router.push({name: "answer-detail"});
    },
  },
};
</script>
<style scoped lang="scss">
.content-ask {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}
</style>
