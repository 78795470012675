<template>
  <v-container class="test-form px-14 pa-14">
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-form
          @submit.prevent="handleSubmit(saveData)"
          method="post"
          id="check-login-form"
      >
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            {{ language.title
            }}<span class="red--text font-weight-normal">(*)</span>
          </v-col>
          <v-col cols="10">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <HnrInput v-model="download.name" :text="name.name" @onKeyUp="onKeyUp"></HnrInput>
              <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            {{ language.slug }}
          </v-col>
          <v-col cols="10">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <HnrInput v-model="download.slug" :text="download.slug"></HnrInput>
              <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            {{ language.user_full_name }}
            <span class="red--text font-weight-normal">(*)</span>
          </v-col>
          <v-col cols="10">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <v-file-input
                  v-model="src"
                  label="Tải file"
                  @change="onChangeFileUpload"
                  @click:clear="clearImage"
              ></v-file-input>
              <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            {{ language.content
            }}<span class="red--text font-weight-normal">(*)</span>
            <div class="text-h6">
              {{ language.picture }}
            </div>
          </v-col>
          <v-col cols="10">
            <HnrInput
                v-model="download.content"
                :text="download.content"
                class="d-none"
            ></HnrInput>
            <CkContent :content="download.content" @getData="getContent"></CkContent>
            <span class="mt-4 validate-error text-danger text-danger">
              {{ contentErrorMessage }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            {{ language.seo_name }}
          </v-col>
          <v-col cols="10">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <HnrInput v-model="download.seo_title" :text="download.seo_title"></HnrInput>
              <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            {{ language.seo_key }}
          </v-col>
          <v-col cols="10">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <HnrInput v-model="download.seo_key" :text="download.seo_key"></HnrInput>
              <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row class="align-center justify-center">
          <v-col cols="2" class="h4 font-weight-black">
            {{ language.seo_des }}
          </v-col>
          <v-col cols="10" class="pa-1">
            <hnr-area-input
                :text="download.seo_description"
                @input="update"
                v-model="download.seo_description"
            ></hnr-area-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="h4 font-weight-black mt-5">
            Kích hoạt
          </v-col>
          <v-col cols="10">
            <v-switch v-model="download.status"></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12 text-center">
            <span class="validate-error text-danger text-danger float-left">
              (*) : Mục cần nhập
            </span>
            <v-btn
                color="primary"
                rounded
                outlined
                width="100"
                type="submit"
                form="check-login-form"
            >
              Lưu
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>

<script>
import CkContent from "@/components/ck-content";
import {
  SAVE_DOCUMENT_DOWNLOAD,SAVE_UPLOAD_DOCUMENT
} from "@/store/download.module";
import HnrInput from "@/components/elements/hnr-input";
import HnrButton from "@/components/elements/hnr-button";
import { mapGetters } from "vuex";
import mixin from "@/store/mixin";
import HnrAreaInput from "@/components/elements/hnr-area-input";

export default {
  // name: "form",
  mixins:[mixin],
  //form to create
  data: () => {
    return {
      alerts: true,
      language: {
        title: "Tên tài liệu",
        slug: "slug",
        content: "Nội dung bài viết",
        image: "Ảnh tiêu đề",
        seoTitle: "SEO Title",
        seoDescription: "SEO Description",
        seoKey: "SEO Keyword",
        user_full_name: "File download",
        imageDisplay: "(547px x 1102px)",
        picture: "Ảnh (764px x 638px)",
        categories: "Mục lục",
        seo_name:"SEO tên",
        seo_key:"SEO từ khóa",
        seo_des:"SEO nội dung",
      },
      src: {},
      file: null,
      cardImg: "",
      name: "",
      content: "",
      slug: "",
      user_id: "",
      seoDescription: "",
      seoTitle: "",
      seoKey: "",
      check: "",
      image: {},
      categories: 0,
      contentErrorMessage: "",
      img_error_msg:""
    };
  },

  components: {
    CkContent,
    HnrInput,
    HnrButton,
    HnrAreaInput
  },
  mounted() {

  },
  computed: {
    ...mapGetters({
      download: "listDownload"
    }),
    replace_image() {
      if (this.download) {
        return process.env.VUE_APP_BASE_URL + "/" + this.download.src;
      }
      return "";
    },
  },
  watch: {
    detail: function(val) {
      this.name = val.name;
      this.content = val.content;
      this.slug = val.slug;
      this.src = val.src;

    },
    content: function(val) {
      if (val.length > 0) {
        this.contentErrorMessage = "";
      } else {
        this.contentErrorMessage = "Vui lòng nhập nội dung của bài viết";
      }
    }
  },

  methods: {
    update() {
      this.$emit("input");
    },
    onKeyUp(value){
      this.download.slug = this.sanitizeTitle(value);
    },
    onChangeFileUpload() {
      this.cardImg = URL.createObjectURL(this.src);
      this.imageError = "";
      let formData = new FormData();
      formData.append("upload", this.src);
      this.$store.dispatch(SAVE_UPLOAD_DOCUMENT, formData).then((data) => {
        if (data) {
          this.download.src = data;
          this.$toasted.success("Tải lên thành công!", {
            position:'top-right',
            duration:3000
          });
        }
      });
    },
    clearImage() {
      this.download.src = "";
      this.show = false;
    },
   
    getContent(value) {
      this.download.content = value;
    },

    getSeoDescription(value) {
      this.seoDescription = value;
    },
    //save news
    async saveData() {
      if (this.download.content.length == 0) {
        this.contentErrorMessage = "Vui lòng nhập nội dung của bài viết";
      } else {
        this.contentErrorMessage = "";
       this.download.status = this.download.status == true ? 1 : 0;
        var data = {
          status: this.download.status,
          name: this.download.name,
          content: this.download.content,
          slug: this.download.slug,
          src: this.download.src,
        };
        await this.$store.dispatch(SAVE_DOCUMENT_DOWNLOAD, data).then((res) => {
          if (res && res.status) {
            console.log(res)
            this.$toasted.success("Thêm mới thành công!", {
              position:'top-right',
              duration:3000
            });
            this.$router.push('/download-document')
          }
          else {
            this.$toasted.error(res.message, {
              position:'top-right',
              duration:3000
            });
          }
        });
      }
    }
  },
  getCurrentDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + "-" + mm + "-" + yyyy;

    return today;
  }

};
</script>

<style></style>
