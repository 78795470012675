<template>
  <v-container class="pt-0 pb-0 pl-0 pr-0">
    <v-text-field
      class="hnr-input"
      :value="text"
      outlined
      single-line
      dense
      @input="changeContent"
      @keyup.enter="active"
      :label="label"
      :name="name"
      :readonly="readonly"
      @keyup = "onKeyUp"
    ></v-text-field>
  </v-container>
</template>

<script>
export default {
  props: {
    name: { type: String, default: "" },
    blur:  Function,
    color: { type: String, default: "" },
    text: { type: String, default: null },
    label: { type: String, default: null },
    validate: { type: Object, default: null },
    formElement: { type: String, default: "" },
    readonly: { type: Boolean, default: false }
  },
  data() {
    return {
      submitted: false
      // value: ""
    };
  },
  watch: {
    // text() {
    //   this.value = this.text;
    // }
  },
  components: {},
  methods: {
    changeContent(value) {
      this.$emit("input", value);
    },
    active(){
      this.$emit("active");
    },
    onKeyUp($event){
      this.$emit("onKeyUp",$event.target.value);
    }
  }
};
</script>

<style lang="scss">
legend {
  display: none;
}

.hnr-input {
  font-size: 14px;
}
</style>
