<template>
  <v-container>
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-tabs v-model="tabIndex">
        <v-tab v-for="item in items" :key="item.id" :disabled="isCreate && item.id == 3 ? true : false">{{ item.tab }}</v-tab>
      </v-tabs>
      <v-tabs-items class="tab-content " v-model="tabIndex">
        <v-tab-item active>
          <t-news-content class="tab-detail" :new="detailNews" :isNew="isCreate"></t-news-content>
        </v-tab-item>
        <v-tab-item>
          <t-news-seo class="tab-detail" :new="detailNews"></t-news-seo>
        </v-tab-item>
        <v-tab-item>
          <t-news-seo-en class="tab-detail" :new="detailNews"></t-news-seo-en>
        </v-tab-item>
      </v-tabs-items>
      <v-row v-if="isCreate" class="align-center justify-center mt-4">
        <v-btn v-if="tabIndex != 3" color="primary" width="100" @click="handleSubmit(saveNews)">Lưu bài học</v-btn>
      </v-row>
      <v-row v-else class="align-center justify-center">
        <v-btn v-if="tabIndex != 3" color="primary" width="130" @click="handleSubmit(updateLesson)">Cập nhật bài học</v-btn>
      </v-row>

    </ValidationObserver>
  </v-container>
</template>
<script>
import { mapState } from "vuex";

// stores
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { SET_LOADING } from "@/store/loading.module";
import { SAVE_NEWS,DETAIL_NEWS,UPDATE_NEWS } from "@/store/news.module";
import { SUCCESS, ERROR } from "@/store/alert.module";
// components
import TNewsContent from "@/components/news/TNewsContent";
import TNewsSeoEn from "@/components/news/TNewsSeoEn";
import TNewsSeo from "@/components/news/TNewsSeo";
import HnrInput from '@/components/elements/hnr-input'
import HnrEditTable from '@/components/elements/hnr-edit-table'

export default {
  data() {
    return {
      detailNews: {
        id: '',
        name: "",
        slug: "",
        photo_url: "",
        short_description: "",
        description: "",
        count_view:"",
        name_en:"",
        description_en: "",
        seo_description: "",
        seo_key_en: "",
        seo_name_en: "",
        seo_description_en: "",
        seo_key: "",
        author:"",
        highlight:"",
      },
      slug: null,
      isCreate: false,
      isRedirect:true,
      tabIndex: 0,
      items: [
        { id: 0, tab: "Thông tin" },
        { id: 1, tab: "Cấu hình SEO" },
        { id: 2, tab: "Cấu hình SEO tiếng anh" },
      ],
    };
  },
  components: {
    TNewsSeoEn,
    HnrInput,
    TNewsContent,
    TNewsSeo,
    HnrEditTable
  },

  created() {
    this.slug = this.$route.params.slug;
    if (this.slug) {
      this.isCreate = false;
      this.$store.dispatch(SET_BREADCRUMB, [{ title: this.slug }]);
      this.$store.dispatch(SET_LOADING, true);
      this.$store.dispatch(DETAIL_NEWS, { slug: this.slug }).then((data) => {
        if(data){
          this.detailNews = data
          this.$store.dispatch(SET_LOADING, false);
        }
      }).catch(() => {
        this.$store.dispatch(SET_LOADING, false);
      });
    }
    else {
      this.isCreate = true;
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Thêm bài viết" }]);
    }

  },
  methods: {
    regexImageUrl(content) {
      if (!content) {
        return null;
      }
      // content = content.replace(
      //     /<img([\w\W]+?)>/gi,
      //     function (match) {
      //       match = match.replace(
      //           /src\s*=\s*"/g,
      //           function () {
      //             return 'src="' + process.env.VUE_APP_BASE_URL + "/";
      //           }
      //       );
      //       return match;
      //     }
      // );
      return content;
    },

    saveNews() {
      this.isRedirect = false;
      let payload = {
        name: this.detailNews.name,
        slug: this.detailNews.slug,
        photo_url: this.detailNews.photo_url,
        name_en: this.detailNews.name_en,
        short_description: this.detailNews.short_description,
        description:this.detailNews.description,
        seo_name: this.detailNews.seo_name,
        seo_description: this.detailNews.seo_description,
        seo_key: this.detailNews.seo_key,
        seo_name_en: this.detailNews.seo_name_en,
        seo_slug_en: this.detailNews.seo_slug_en,
        seo_description_en: this.detailNews.seo_description_en,
        seo_key_en: this.detailNews.seo_key_en,
        content_en: this.detailNews.content_en,
        status: this.detailNews.status,
        author: this.detailNews.author,
        highlight: this.detailNews.highlight?1:0,
      };
      this.$store.dispatch(SET_LOADING, true);
      this.$store.dispatch(SAVE_NEWS, payload).then(data => {
        if (data.status) {
          this.$store.dispatch(SUCCESS, "Thêm bài viết thành công!", {root: true});
          this.$router.push('/news');
        } else {
          this.$store.dispatch(ERROR, data.message, {root: true});
        }
        this.$store.dispatch(SET_LOADING, false);
      }).catch(() => {
        this.$store.dispatch(ERROR, "Thêm bài viết thất bại!", {root: true});
        this.$store.dispatch(SET_LOADING, false);
      });
    },
    updateLesson() {
      this.isRedirect = false;
      let payload = {
        id: this.detailNews.id,
        name: this.detailNews.name,
        slug: this.detailNews.slug,
        photo_url: this.detailNews.photo_url,
        name_en: this.detailNews.name_en,
        short_description: this.detailNews.short_description,
        description:this.detailNews.description,
        seo_name: this.detailNews.seo_name,
        seo_description: this.detailNews.seo_description,
        seo_key: this.detailNews.seo_key,
        seo_name_en: this.detailNews.seo_name_en,
        seo_slug_en: this.detailNews.seo_slug_en,
        seo_description_en: this.detailNews.seo_description_en,
        seo_key_en: this.detailNews.seo_key_en,
        content_en: this.detailNews.content_en,
        status: this.detailNews.status,
        author: this.detailNews.author,
        highlight: this.detailNews.highlight?1:0,
      };
      this.$store.dispatch(SET_LOADING, true);
      this.$store.dispatch(UPDATE_NEWS, payload).then(data => {
        if (data.status) {
          this.$store.dispatch(SUCCESS, "Cập nhật bài viết thành công!", {root: true});
          // this.$router.push('/news');
        } else {
          if (data.errorCode == 424) {
            this.$store.dispatch(ERROR, data.message, {
              root: true,
            });
          } else {
            this.$store.dispatch(ERROR, data.message, {
              root: true,
            });
          }
        }
        this.$store.dispatch(SET_LOADING, false);
      }).catch(() => {
        this.$store.dispatch(ERROR, "Thêm bài viết thất bại!", {root: true});
        this.$store.dispatch(SET_LOADING, false);
      });
    },
    updateImageUrl(content) {
      if (!content) {
        return null;
      }
      // content = content.replace(
      //     /<img([\w\W]+?)>/gi,
      //     function (match) {
      //       match = match.replace(
      //           /src="([^"]+)"/g,
      //           function (match_child) {
      //             let base_url = process.env.VUE_APP_BASE_URL
      //             var regex = new RegExp(base_url + "/(.*)", "");
      //             let relative_path = "";
      //             console.log(base_url + "/(.*)");
      //             let relative_path_arr = match_child.match(regex)
      //             if (relative_path_arr && relative_path_arr.length > 0) {
      //               relative_path = relative_path_arr[1];
      //               return 'src="' + relative_path;
      //             }
      //           }
      //       );
      //       return match;
      //     }
      // );
      return content;
    },

  },
  beforeRouteLeave (to, from, next) {
    if(this.isRedirect){
      if (confirm("Bạn chưa lưu bài viết.Bạn vẫn muốn rời đi chứ?")) {
        next()
      }else{
        next(false)
      }
    }else{
      next();
    }
  },
};
</script>


<style lang="scss">
.tab-detail{
  margin-top:15px;
}
</style>
