<template>
  <v-container>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Tiêu đề seo </span>
      </v-col>
      <v-col cols="10" class="pa-1">
          <hnr-input
              :text="detailAsk.title"
              v-model="title"
          ></hnr-input>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Slug </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input
            :text="detailAsk.slug"
            @input="changeSlug"
            v-model="detailAsk.slug"
        ></hnr-input>
        <span class="validate-error text-danger text-danger">
            {{ slug_message }}
          </span>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Nội dung </span>
      </v-col>
      <v-col cols="12" class="pa-1">
        <ValidationProvider
            name="content"
            rules="required"
            v-slot="{ errors }"
        >
          <ck-content
              :content="detailAsk.content"
              @getData="updateContent"
              v-model="detailAsk.content"
          ></ck-content>
         <math-jax-editor
             :formula_prop="formula"
             @addFormula="addFormula"
         ></math-jax-editor>
          <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
        </ValidationProvider>

      </v-col>
    </v-row>

    <v-row v-if="isNew" class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Tài khoản </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider
            name="email"
            rules="required"
            v-slot="{ errors }"
        >
          <hnr-input
              :text="detailAsk.email"
              v-model="detailAsk.email"
          ></hnr-input>
          <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
        </ValidationProvider>

      </v-col>
    </v-row>
    <v-row v-if="!isNew" class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Đăng bởi </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <span v-if="detailAsk.user">{{ detailAsk.user.email }}</span>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Kích hoạt </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <v-switch v-model="detailAsk.status"></v-switch>
      </v-col>
    </v-row>

    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Sắp xếp </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input
            :text="convertNumberToString(detailAsk.order)"
            @input="update"
            v-model="detailAsk.order"
        ></hnr-input>
      </v-col>
    </v-row>

    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Số điểm trừ </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input
            :text="convertNumberToString(detailAsk.score)"
            @input="update"
            v-model="detailAsk.score"
        ></hnr-input>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HnrInput from "@/components/elements/hnr-input";
import MathJaxEditor from "@/components/math/MathJaxEditor";
import CkContent from "@/components/ck-content";
import mixin from "@/store/mixin";

export default {
  mixins:[mixin],
  data() {
    return {
      title:"",
      formula:"",
      dialog: false,
      detailAsk: {},
      selected: [],
      value: 0,
      search: null,
      singleSelect: true,
      suggestNewCategories: [],
      headers: [
        {
          text: "id",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
      ],
    };
  },
  props: {
    ask: Object,
    subjects: Array,
    grades: Array,
    isNew: {type: Boolean, default: false},
    slug_message: {type: String},
  },
  watch: {
    title: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.detailAsk.title = newVal;
      },
    },
    ask: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.detailAsk = newVal;
      },
    },
    detailAsk: {
      deep: true,
      immediate: true,
      handler() {
        // if (this.isNew) {
        //   this.detailAsk.slug = this.sanitizeTitle(newVal.title);
        //   console.log(this.detailAsk.slug,"slug")
        // }
      },
    },
    nameOfNewsCategories: {
      deep: true,
      handler(newVal) {
        this.suggestNewCategories = newVal;
      },
    },
  },
  components: {
    HnrInput,
    MathJaxEditor,
    CkContent
  },
  methods: {
    changeSlug(){
      this.detailAsk.has_change_slug = true;
    },
    addFormula(value){
      this.detailAsk.content += value;
    },
    // searchCategoriesByName() {
    //   if (this.search) {
    //     this.$store.dispatch(SET_LOADING, true);
    //     this.$store
    //         .dispatch(SEARCH_TOPIC_BY_NAME, {name: this.search})
    //         .then(() => {
    //           this.$store.dispatch(SET_LOADING, false);
    //         })
    //         .catch(() => {
    //           this.$store.dispatch(SET_LOADING, false);
    //         });
    //   }
    // },
    saveNewsCategory() {
      if (this.selected && this.selected.length > 0) {
        this.detailAsk.topics = {
          id: this.selected[0].id,
          name: this.selected[0].name,
        };
      } else {
        this.detailAsk.topics = {
          id: 0,
          name: null,
        };
      }
      this.dialog = false;
    },
    getPicker(val) {
      this.detailAsk.created_at = val
    },
    // updateName() {
    //   //generate slug
    //   this.$emit("input");
    // },
    updateSlug() {
      //generate slug
      this.$emit("input");
    },
    updateContent(val) {
      this.detailAsk.content = val;
    },
    updateParent() {
      this.dialog = true;
    },
    update() {
      this.$emit("input");
    },
    isRealValue(obj) {
      return obj && obj !== 'null' && obj !== 'undefined';
    }
    ,
    convertNumberToString(number) {
      if (this.isRealValue(number)) {
        return number.toString();
      }
      return "0";
    }
    ,
    stringToSlug(str) {
      str = str.replace(/^\s+|\s+$/g, ""); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      var from = "ăâđêơôư·/_,:;";
      var to = "aadeoou------";
      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      str = str
          .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
          .replace(/\s+/g, "-") // collapse whitespace and replace by -
          .replace(/-+/g, "-"); // collapse dashes

      return str;
    }
    ,
  }
  ,
}
;
</script>

<style lang="scss">
</style>
