<template>
  <div data-app>
    <template>
      <v-data-table
          :headers="headers"
          :items="news"
          class="elevation-1"
          :page.sync="page"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-card-title class="text-h5 pa-0">
              Danh sách bài viết
              <v-spacer></v-spacer>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-btn color="primary" width="100" dark class="mb-2" to="/news/add" target="_blank"
            >Thêm mới
            </v-btn
            >
          </v-toolbar>
        </template>
        <template v-slot:item.short_description="{ item }">
         <span class="text-short">{{item.short_description}}</span>
        </template>

        <template v-slot:item.status="{ item }">
          <v-switch v-model="item.status" :disabled="true"></v-switch>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete</v-icon>
        </template>

      </v-data-table>
    </template>
    <!-- DIALOG DELETE  -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline"
        >Bạn có chắc muốn xóa không?
        </v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn width="70" color="blue darken-1" text @click="closeDelete"
          >Thoát
          </v-btn
          >
          <v-btn width="70" color="blue darken-1" text @click="deleteItemConfirm"
          >Xóa
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {LIST_NEWS,DELETE_NEWS} from "@/store/news.module";
import {validationMixin} from "vuelidate";
import HnrInput from "@/components/elements/hnr-input";
import {ERROR} from "@/store/alert.module";

export default {
  mixins: [validationMixin],
  components: {
    HnrInput,
  },
  data: () => ({
    page: 1,
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id",
      },
      {text: "Tên bài viết", value: "name", width :"30%"},
      {text: "Slug", value: "slug", width :"20%"},
      {text: "Nội dung ngắn", value: "short_description", width :"25%"},
      {text: "Active", value: "status"},
      {text: "Ngày tạo", value: "created_at"},
      {text: "Actions", value: "actions", sortable: false},
    ],

    listStatus:[
      {value : 0,text : 'Chưa kích hoạt'},
      {value : 1,text : 'kích hoạt'},
    ],
    dialog: false,
    dialogDelete: false,
    status_parent: null,
  }),

  computed: {
    ...mapGetters({
      lessons: "listLessons",
      news: "listNews",
      users: "users",
      subjects: "listSubjects",
    }),
    total() {
      return this.$store.getters.lessonCount;
    },
  },
  mounted() {
    this.$store.dispatch(LIST_NEWS)
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Bài viết", route: "Bài viết"},
    ]);
  },
  methods: {
    editItem(item) {
      let routeData = this.$router.resolve({
        name: "news-detail",
        params: {slug: item.slug},
      });
      window.open(routeData.href, '_blank');
    },

    deleteItem(item) {
      this.editedIndex = this.lessons.indexOf(item);
      this.editedId = item.id;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.$store.dispatch(DELETE_NEWS, this.editedId).then((data) => {
        if (data.status) {
          this.$store.dispatch(LIST_NEWS)
        } else {
          if(data.errorCode == 424){
            this.$store.dispatch(ERROR, data.message, {
              root: true,
            });
          }else {
            this.$store.dispatch(ERROR, "Xóa thất bại!", {
              root: true,
            });
          }
        }
      });
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
    },
  },
};
</script>
<style lang="scss">
.text-short {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp:2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
</style>