<template>
          <v-col :cols=col id="box_content_seo" :class="['content_seo mt-3 rounded-lg', 'col-' + col]">
            <v-row class="mx-3">
              <h4>Hỗ trợ SEO</h4>
              <v-col cols="12" class="box-keyword">
                <textarea defaultValue="a,b,c" @change="onChange" ref="keyword" id="value_seo" placeholder="Focus Keyword" />
              </v-col>
              <v-col
                cols="12"
                style="border: 1px solid #dadada"
                class="rounded-lg"
              >
                <div class="d-flex">
                  <h5 class="pt-2 mr-3">Basic SEO</h5>
                  <div
                    style="background: #f39d96;width: 100px;height: 35px;line-height: 32px;"
                    class="rounded-xl white--text text-center"
                    v-if="errors_ > 0"
                  >
                    <v-icon color="white">mdi-close</v-icon>{{errors_}}
                  </div>
                </div>
                <div
                  class="mt-3"
                  v-for="(item, index) in SEO"
                  v-bind:key="index"
                >
                  <v-icon
                    size="20"
                    style="background: #f39d96; border-radius: 50%"
                    color="white"
                    class="mr-2"
                    >mdi-close</v-icon
                  >
                  {{ item.title }} <v-icon color="#B6BFC9">mdi-help-circle</v-icon>
                </div>
              </v-col>
            </v-row>
          </v-col>
</template>
<script>

import Tagify from "@yaireo/tagify/dist/tagify.min.js";
import "@yaireo/tagify/dist/tagify.css";
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

export default {
  name: "Seo",
  props: {
    getKeywords: Function,
    meta_title: String,
    content: String,
    slug: String,
    meta_description: String,
    trigger_seo: Number,
    keywords: {
      type: String,
      default: ""
    },
    col: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      errors_: 0,
      text_seo: '',
      value: '',
      isWatchingKeyword: true,
      settings: {
        onChangeAfterBlur: false,
        dropdown: {
          enabled: 0
        },
        editTags: false,
        callbacks: {
          add(e) {
            if(e.detail.tagify && e.detail.tagify.value && e.detail.tagify.value.length == 1){
                e.detail.tag.classList.add('active');
            }
          },
          remove(e){
            let currentTag = e.detail.tag;
            if(e.detail.tag.classList.contains("active")){
                currentTag.classList.remove('active');
                if(e.detail.tagify && e.detail.tagify.value && e.detail.tagify.value.length > 0){
                    let tags = document.querySelectorAll('#box_content_seo tags tag');
                    tags[0].classList.add('active');
                }
            }
          },
          click(e){
            let currentTag = e.detail.tag;
            if(!currentTag.classList.contains("active")){
                let tags = document.querySelectorAll('#box_content_seo tags tag');
                tags.forEach(tag => {
                    tag.classList.remove('active');
                });
                currentTag.classList.add('active');
                document.getElementById('value_seo').dispatchEvent(new Event('click'));
            }
          }
        }
      },
      tagify: null,
      SEO: [],
    };
  },
  methods: {
    getseo() {
      let formData = new FormData();
      formData.append("keyword", this.text_seo);
      formData.append("title", this.meta_title);
      formData.append("data_content", this.content);
      formData.append("slug", this.slug);
      formData.append("meta_description", this.meta_description);

      // this.$store.dispatch(`${GET_LIST_SEO}`, formData).then((data) => {
      Vue.axios.post(`https://tools.eglifesoftware.com/api/checkSEO`,formData).then((data) => {
        if (data.data.status == 0) {
          this.SEO = [];
          data.data.errors.forEach((element) => {
            let data = {
              title: element,
            };
            this.SEO.push(data);
          });
          this.errors_ = this.SEO.length
        } else {
          // alert(data.data.errors)
        }
      });
    },
    onChange() {
        let that = this;
        let keywords = '';
        setTimeout(function(){
          if(that.tagify.value.length > 0){
            that.tagify.value.forEach(function(keyword){
              keywords += ', ' + keyword.value;
              let element = that.tagify.getTagElmByValue(keyword.value);
              if(element.classList.contains("active") && that.text_seo != keyword.value){
                  that.text_seo = keyword.value;
                  that.getseo();
              }
            });
          }else{
            that.SEO = [];
            that.errors_ = 0;
            that.text_seo = '';
          }
          that.$emit("getKeywords", keywords.substr(2));
        }, 1000);
    },
    onClickChild() {
        let element = document.querySelector('tags tag.active span');
        if(element != null && this.text_seo != element.innerHTML){
            this.text_seo = element.innerHTML;
            this.getseo();
        }
    }
  },
  watch: {
    value(newVal, old) {
        console.log(newVal, old);
    },
    trigger_seo(){
      console.log(this.text_seo);
        if(this.text_seo != ''){
            this.getseo();
        }
    },
    keywords(val){
      if(val && this.isWatchingKeyword){
        this.tagify.removeAllTags()
        this.tagify.addTags(val)
        this.isWatchingKeyword = false;
      }
    }
  },
  mounted() {
    let that = this;
    this.tagify = new Tagify(this.$refs['keyword'], this.settings);// if(this.keywords){
    if(this.keywords){
      this.tagify.addTags(this.keywords)
    }
    document.getElementById('value_seo').addEventListener('click', function () {
        that.onClickChild();
    });
  }
};
</script>
<style lang="scss">
.content_seo {
  padding:30px 10px;
  background: #ffffff !important;
  .content-textarea .v-input__control {
    height: 165px !important;
  }
  .v-input--checkbox .v-input__control {
    height: 22px !important;
  }
  .box-keyword{
    padding:10px 0;
    tags{
        background-color: rgb(237, 242, 247);
        border:0px;
        border-bottom:1px solid rgba(0, 0, 0, 0.42);
        border-radius: 3px;
        height: 55px!important;
        padding:5px 5px;
        &.tagify.tagify--focus{
            border-bottom-width: 2px;
            border-bottom-color:var(--v-primary-base) !important;
        }
        tag{
            &.active{
                --tag-bg:var(--v-primary-base) !important;
                span.tagify__tag-text{
                    color:#fff;
                    pointer-events: none;
                }
                .tagify__tag__removeBtn{
                    color:#fff;
                }
                &:hover{
                    span.tagify__tag-text, .tagify__tag__removeBtn{
                        color:black;
                    }
                }
            }
            height: 35px;
        }
        .tagify__input{
            height: 35px;
            top:5px;
        }
      .tagify .tagify__tag {
        background-color: #00aff0;
      }
    }
  }
}
.ck-news .ck-editor__main .ck-content {
  height: 140px;
}
</style>