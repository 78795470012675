<template>
  <div data-app>
    <template>
      <v-data-table
          :headers="headers"
          :items="download"
          class="elevation-1"
          :page.sync="page"
          :items-per-page="10"
          @page-count="pageCount = $event"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-spacer></v-spacer>
            <v-btn width="100" color="primary" dark class="mb-2" to="/download-document/add" target="_blank"
            >Thêm mới
            </v-btn
            >
          </v-toolbar>
        </template>
        <template v-slot:item.status="{ item }">
          <v-switch v-model="item.status" :disabled="true"></v-switch>
        </template>
        <template v-slot:item.act="{ item }">
          <v-switch v-model="item.act" :disabled="true"></v-switch>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete</v-icon>
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="7"
        ></v-pagination>
      </div>
    </template>
    <!-- DIALOG DELETE  -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline"
        >Bạn có chắc muốn xóa không?
        </v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn width="70" color="blue darken-1" text @click="closeDelete"
          >Thoát
          </v-btn
          >
          <v-btn width="70" color="blue darken-1" text @click="deleteItemConfirm"
          >Xóa
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {COUNT_LESSON, GET_LIST_LESSONS, DELETE_LESSON} from "@/store/lesson.module";
import {GET_LIST_DOCUMENT,DELETE_DOCUMENT} from "@/store/download.module";
import {GET_LIST_USERS} from "@/store/user.module";
import {validationMixin} from "vuelidate";
import HnrInput from "@/components/elements/hnr-input";
import {SET_LOADING} from "@/store/loading.module";
import {ERROR} from "@/store/alert.module";

export default {
  mixins: [validationMixin],
  components: {
    HnrInput,
  },
  data: () => ({
    page: 1,
    itemsPerPage: 10,
    pageCount: 0,
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id",
      },
      {text: "Tên tài liệu", value: "name", width :"30%"},
      {text: "Slug", value: "slug", width :"20%"},
      {text: "Active", value: "status"},
      {text: "Ngày tạo", value: "created_at"},
      {text: "Actions", value: "actions", sortable: false},
    ],
    publish_by: null,
    lesson_name: "",
    category: "",
    startDate: "",
    endDate: "",
    startDialog: "",
    endDialog: "",
    status: null,
    listStatus:[
      {value : 1,text : 'kích hoạt'},
      {value : 0,text : 'Chưa kích hoạt'}
    ],
    hasNotYetCategorySearch: false,
    dialog: false,
    dialogDelete: false,
    listStatusParent:[
      {value : 1,text : 'Đã nhận thư mục cha'},
      {value : 0,text : 'chưa nhận thư mục cha'}
    ],
    status_parent: null,
  }),

  computed: {
    ...mapGetters({
      lessons: "listLessons",
      users: "users",
      download: "listDownload"
    }),
    total() {
      return this.$store.getters.lessonCount;
    },
  },
  async  created() {
    await this.getDocuments();
    await this.count();
    await this.$store.dispatch(GET_LIST_USERS)
    await this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Tải tài liệu", route: "Tải tài liệu"},
    ]);
  },
  watch: {},
  methods: {
    count() {
      var payload = {
        publish_by: this.publish_by,
        category: this.category,
        start_date: this.startDate,
        end_date: this.endDate,
        status_parent: this.status_parent,
        has_category: this.hasNotYetCategorySearch,
        status: this.status,
      };
      this.$store.dispatch(COUNT_LESSON, payload);
    },
    search() {
      this.count();
      this.getDocuments();
    },

    getDocuments() {
      var payload = {
        name: this.name,
        slug: this.slug,
        content: this.content,
        src: this.src,
        status: this.status,
      };
      this.$store.dispatch(SET_LOADING, true);
      this.$store
          .dispatch(GET_LIST_DOCUMENT, payload)
          .then(() => {
            this.$store.dispatch(SET_LOADING, false);
          })
          .catch(() => {
            this.$store.dispatch(SET_LOADING, false);
          });
    },
    updateItemPerPage($event) {
      this.itemsPerPage = $event;
      this.getDocuments();
    },

    updatePage($event) {
      this.page = $event;
      this.getDocuments();
    },

    editItem(item) {
      let routeData = this.$router.resolve({
        name: "download-document-edit",
        query: {slug: item.slug},
      });
      window.open(routeData.href, '_blank');
    },

    deleteItem(item) {
      this.editedIndex = this.download.indexOf(item);
      this.editedId = item.id;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.$store.dispatch(DELETE_DOCUMENT, this.editedId).then((data) => {
        if (data.status) {
          this.download.splice(this.editedIndex, 1);
          this.$toasted.success("Xóa thành công!", {
            position: 'top-right',
            duration:2000
          })
        } else {
            this.$store.dispatch(ERROR, "Xóa thất bại!", {
              root: true,
            });
        }
      });

      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
    },

    addNewItem() {
      this.$router.push({name: "lession-add"});
    },
  },
};
</script>
