<template>
  <v-container>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Từ khóa SEO </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input :text="detailNews.seo_key" :blur="triggerSeo" @input="update" v-model="detailNews.seo_key"></hnr-input>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Tiêu đề SEO</span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input :text="detailNews.seo_name" :blur="triggerSeo" @input="update" v-model="detailNews.seo_name"></hnr-input>
      </v-col>
    </v-row>

    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Mô tả SEO</span>
      </v-col>
      <v-col cols="10" class="pa-1">
<!--        <hnr-area-input :text="detailNews.seo_description" @input="update" v-model="detailNews.seo_description"></hnr-area-input>-->
        <ck-content
            id="lesson_content"
            :content="detailNews.seo_description"
            @getData="updateContent"
            v-model="detailNews.seo_description"
        ></ck-content>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
      </v-col>
      <v-col cols="10" class="pa-1">
        <seo
            @getKeywords="getKeywords"
            :trigger_seo="count_trigger_seo"
            :text_seo="text_seo"
            :meta_title="detailNews.seo_name"
            :content="detailNews.description"
            :slug="detailNews.slug"
            :meta_description="detailNews.seo_description"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HnrInput from '@/components/elements/hnr-input'
import HnrAreaInput from '@/components/elements/hnr-area-input'
import Seo from "@/components/seo";
import CkContent from "@/components/ck-content";
export default {
  props: {
    new: Object,
  },
  watch: {
    new: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.detailNews = newVal;
      }
    }
  },
  data() {
    return {
      detailNews: {},
      count_trigger_seo: 0,
      text_seo: "",
    };
  },
  components: {
    HnrInput,
    HnrAreaInput,
    Seo,
    CkContent
  },
  methods: {
    updateContent(val) {
      this.detailNews.seo_description = val;
    },
    triggerSeo(){
      this.count_trigger_seo++;
    },
    getKeywords(keywords){
      this.detailNews.seo_key = keywords
    },
    onClick() {
    },
    update() {
      this.$emit("input");
    },
  },
};
</script>

<style lang="scss">
</style>
