<template>
  <v-container>
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-tabs v-model="tabIndex" @change="changeTab">
        <v-tab
            v-for="item in items"
            :key="item.id"
            :disabled="isCreate && item.id == 3 ? true : false"
        >{{ item.tab }}
        </v-tab
        >
      </v-tabs>
      <v-tabs-items class="tab-content" v-model="tabIndex">
        <v-tab-item active>
          <t-category-content
              class="tab-detail"
              :category="detailCategory"
              :isNew="isCreate"
              :slug_message="slug_error"
          ></t-category-content>
        </v-tab-item>
        <v-tab-item>
          <t-category-seo
              class="tab-detail"
              :category="detailCategory"
          ></t-category-seo>
        </v-tab-item>
        <v-tab-item>
          <t-category-seo-en
              class="tab-detail"
              :category="detailCategory"
          ></t-category-seo-en>
        </v-tab-item>
        <v-tab-item>
          <v-data-table
              v-if="topics_children"
              class="tab-detail"
              :headers="newsTbheaders"
              :items="topics_children.content"
              disable-pagination
              hide-default-footer
          >
            <template v-slot:item.name="{ item }">
              <span @click="editItem(item)" class="cursor">{{item.name}}</span>
            </template>
            <template v-slot:item.order="{ item }">
              <input style="border:solid 1px black; text-align: center" type="text" size="2" v-model="item.order">
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
      <v-row v-if="isCreate&&tabIndex!=2" class="align-center justify-center">
        <v-btn color="primary" width="100" @click="handleSubmit(saveCategory)"
        >Lưu chủ đề
        </v-btn
        >
      </v-row>
      <v-row v-if="!isCreate&&tabIndex!=3" class="align-center justify-center">
        <v-btn color="primary" @click="handleSubmit(updateCategory)">Cập nhật chủ đề</v-btn>
      </v-row>
      <v-row v-if="tabIndex==3" class="align-center justify-center">
        <v-btn color="primary" @click="updateChildren">Cập nhật danh sách</v-btn>
      </v-row>
    </ValidationObserver>
  </v-container>
</template>
<script>
import {mapState, mapGetters} from "vuex";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {SET_LOADING} from "@/store/loading.module";
import {UPDATE_NEWS_CATEGORY} from "@/store/newscategory.module";
// topic = news_category
import {
  GET_TOPIC_DETAIL,
  SAVE_TOPIC,
  GET_DIRECT_CHILDREN,
  UPDATE_CHILDREN_TOPIC,
  UPDATE_CHILDREN_LESSON
} from "@/store/topic.module";
import {GET_LIST_SUBJECTS} from "@/store/subject.module";
import {GET_LIST_GRADE} from "@/store/grade.module";
import {SUCCESS, ERROR} from "@/store/alert.module";
import TCategoryContent from "@/components/category/TCategoryContent.vue";
import TCategorySeo from "@/components/category/TCategorySeo.vue";
import TCategorySeoEn from "@/components/category/TCategorySeoEn.vue";

// import HnrInput from "@/components/elements/hnr-input";

export default {
  data() {
    return {
      isRedirect :true,
      slug_error: "",
      slug: null,
      isCreate: false,
      tabIndex: 0,
      items: [
        {id: 0, tab: "Thông tin chủ đề"},
        {id: 1, tab: "Cấu hình SEO"},
        {id: 2, tab: "Cấu hình SEO tiếng anh"},
        {id: 3, tab: "Danh sách bài viết"},
      ],
      newsTbheaders: [
        {
          text: "id",
          value: "id",
        },
        {text: "Tên bài viết", value: "name"},
        {text: "Slug", value: "slug"},
        {text: "Thứ tự", value: "order"},
      ],
      detailCategory: {
        slug:"",
        name:""
      },
      lessons: [],
      dialog: false,

      suggestNewCategories: [],
      // selectedCategories: [],
      value: 0,
      search: null,
      singleSelect: true,
      selected: [],
      headers: [
        {
          text: "id",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
      ],

      publish_by: "",
      page: 1,
      itemsPerPage: 100,
    };
  },
  components: {
    // HnrInput,
    TCategoryContent,
    TCategorySeo,
    TCategorySeoEn
  },
  computed: {
    ...mapState({
      subjects: (state) => state.subject.subjects,
    }),
    ...mapGetters({
      grades: "listGrade",
      category: "topicDetail",
      topics_children: "topicListChildren",
    }),
  },
  watch: {
    category: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.detailCategory = newVal;
          this.lessons = this.detailCategory.lessons;
        }
      },
    },

    subjects: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.$set(this.detailCategory, "subjects", newVal);
        }
      },
    },
    grades: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.$set(this.detailCategory, "grades", newVal);
        }
      },
    },
  },
  async mounted() {
    this.slug = await this.$route.params.slug;
    if (this.slug) {
      this.isCreate = false;
      await this.$store.dispatch(SET_BREADCRUMB, [{title: this.slug}]);

      await this.$store.dispatch(SET_LOADING, true);
      await this.$store
          .dispatch(GET_TOPIC_DETAIL, {slug: this.slug})
          .then(() => {
            this.$store.dispatch(SET_LOADING, false);
          })
          .catch(() => {
            this.$store.dispatch(SET_LOADING, false);
          });
      await this.fetchDirectChildren();
    } else {
      this.isCreate = true;
      this.$store.dispatch(SET_BREADCRUMB, [{title: "Thêm chủ đề"}]);
      this.getClasses();
      this.getSubjects();
    }
    this.detailCategory.short_content=await this.regexImageUrl(this.detailCategory.short_content);
  },
  beforeRouteLeave (to, from, next) {
    if(this.isRedirect){
      if (confirm("Bạn chưa lưu bài viết.Bạn vẫn muốn rời đi chứ?")) {
        next()
      }else{
        next(false)
      }
    }else{
      next();
    }
  },
  methods: {
    regexImageUrl(content) {
      if (!content) {
        return null;
      }
      content = content.replace(
          /<img([\w\W]+?)>/gi,
          function (match) {
            match = match.replace(
                /src\s*=\s*"/g,
                function () {
                  return 'src="' + process.env.VUE_APP_BASE_URL + "/";
                }
            );
            return match;
          }
      );
      return content;
    },

    fetchDirectChildren(){
       this.$store
          .dispatch(GET_DIRECT_CHILDREN, {topic_id: this.category.id})
          .then(() => {
            this.$store.dispatch(SET_LOADING, false);
          })
          .catch(() => {
            this.$store.dispatch(SET_LOADING, false);
          });
    },
    changeTab() {
    },

    getClasses() {
      this.$store.dispatch(SET_LOADING, true);
      this.$store
          .dispatch(GET_LIST_GRADE)
          .then(() => {
            this.$store.dispatch(SET_LOADING, false);
          })
          .catch(() => {
            this.$store.dispatch(SET_LOADING, false);
          });
    },
    getSubjects() {
      this.$store.dispatch(SET_LOADING, true);
      this.$store
          .dispatch(GET_LIST_SUBJECTS)
          .then(() => {
            this.$store.dispatch(SET_LOADING, false);
          })
          .catch(() => {
            this.$store.dispatch(SET_LOADING, false);
          });
    },
    saveCategory() {
      this.isRedirect = false;
      if (!this.detailCategory) {
        return;
      }
      let payload = {
        name: this.detailCategory.name,
        name_en: this.detailCategory.name_en,
        slug: this.detailCategory.slug,
        short_content: this.detailCategory.short_content,
        parent_id: this.detailCategory.parent
            ? this.detailCategory.parent.id
            : null,
        subject_id: this.detailCategory.subject
            ? this.detailCategory.subject.id
            : null,
        grade_id: this.detailCategory.grade
            ? this.detailCategory.grade.id
            : null,
        act: this.detailCategory.status ? 1 : 0,
        ord: this.detailCategory.order,
        seo_title: this.detailCategory.seo_title,
        seo_description: this.detailCategory.seo_description,
        seo_key: this.detailCategory.seo_key,
        is_show: 1,
        status: 1,
      };
      this.$store.dispatch(SET_LOADING, true);
      this.$store
          .dispatch(SAVE_TOPIC, payload)
          .then((data) => {
            if (data.status) {
              this.$store.dispatch(SUCCESS, "Thêm chủ đề thành công!", {
                root: true,
              });
              this.$router.push("/news-category");
            } else {
              if (data.errorCode === 423) {
                this.$store.dispatch(ERROR, data.message, {root: true});
              } else {
                this.$store.dispatch(ERROR, data.message, {
                  root: true,
                });
              }
            }
            this.$store.dispatch(SET_LOADING, false);
          })
          .catch(() => {
            this.$store.dispatch(ERROR, "Thêm chủ để thất bại!", {root: true});
            this.$store.dispatch(SET_LOADING, false);
          });
    },
    updateChildren() {
      this.$store.dispatch(SET_LOADING, true);
      let URL, payload = null;
      if (this.topics_children.type == 1) {
        URL = UPDATE_CHILDREN_LESSON
        payload = {
          "lessons": this.topics_children.content
        }
      }
      if (this.topics_children.type == 2) {
        URL = UPDATE_CHILDREN_TOPIC
        payload = {
          "topics": this.topics_children.content
        }
      }
      this.$store
          .dispatch(URL, payload)
          .then((data) => {
            if (data.status) {
              this.$store.dispatch(SUCCESS, "Cập nhất danh sách thành công!", {
                root: true,
              });
              this.fetchDirectChildren();
            } else {
              this.$store.dispatch(ERROR, "Cập nhất danh sách thất bại!", {
                root: true,
              });
            }
            this.$store.dispatch(SET_LOADING, false);
          })
          .catch(() => {
            this.$store.dispatch(ERROR, "Cập nhất danh sách thất bại!", {root: true});
            this.$store.dispatch(SET_LOADING, false);
          });
    },
    updateCategory() {
      this.isRedirect = false;

      if (!this.detailCategory) {
        return;
      }
      else {
        let payload = {
          id: this.detailCategory.id,
          name: this.detailCategory.name,
          name_en: this.detailCategory.name_en,
          slug: this.detailCategory.slug,
          short_content: this.detailCategory.short_content,
          parent_id: this.detailCategory.parent
              ? this.detailCategory.parent.id
              : null,
          subject_id: this.detailCategory.subject
              ? this.detailCategory.subject.id
              : null,
          grade_id: this.detailCategory.grade
              ? this.detailCategory.grade.id
              : null,
          act: this.detailCategory.status,
          ord: this.detailCategory.order,
          seo_title: this.detailCategory.seo_title,
          seo_description: this.detailCategory.seo_description,
          seo_key: this.detailCategory.seo_key,
          has_change_slug: this.detailCategory.slugHasChanged
        };
        this.$store.dispatch(SET_LOADING, true);
        this.$store
            .dispatch(UPDATE_NEWS_CATEGORY, payload)
            .then((data) => {
              if (data.status) {
                this.$store.dispatch(SUCCESS, "Cập nhật chủ đề thành công!", {
                  root: true,
                });
                this.$router.push("/news-category");
              } else {
                this.$store.dispatch(ERROR, data.message, {
                  root: true,
                });
              }
              this.$store.dispatch(SET_LOADING, false);
            });
            // .catch(() => {
            //   this.$store.dispatch(ERROR, "Cập nhật chủ để thất bại!", {
            //     root: true,
            //   });
            //   this.$store.dispatch(SET_LOADING, false);
            // });
      }

    },
    addRow() {
      this.dialog = true;
    },
    updateItemTable(payload) {
      var index = this.lessons.findIndex((item) => item.id == payload.id);
      this.lessons[index] = payload;
    },
    editItem(item) {
      let routeData = this.$router.resolve({
        name: "lession-detail",
        params: {slug: item.slug},
      });
      window.open(routeData.href, '_blank');
    },
    // savelessonsCategory() {
    //   if (this.selected && this.selected.length > 0) {
    //     this.value = this.selected[0].id;
    //   }
    //   if (this.value) {
    //     let payload = { lessons_id: this.detailLesson.id, lessons_category_id: this.value };
    //     this.$store.dispatch(SET_LOADING, true);
    //     this.$store.dispatch(ADD_CATEGORY_LESSON, payload).then(data => {
    //       if (data.status) {
    //         this.$store.dispatch(SUCCESS, "Thêm chủ đề thành công!", { root: true });
    //       }
    //       else {
    //         this.$store.dispatch(ERROR, "Thêm chủ đề thất bại!", { root: true });
    //       }
    //       this.$store.dispatch(SET_LOADING, false);
    //       this.dialog = false;
    //     }).catch(() => {
    //       this.$store.dispatch(ERROR, "Thêm chủ đề thất bại!", { root: true });
    //       this.$store.dispatch(SET_LOADING, false);
    //     });
    //   }
    // }

  },
};
</script>

<style lang="scss">
.tab-detail {
  margin-top: 15px;
}
</style>
