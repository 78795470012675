<template>
  <v-container class="order-detail px-14 pa-14">
    <v-row class="pa-0 ma-0">
      <v-col cols="3">Tên khách hàng:</v-col>
      <v-col cols="9">{{order.user_name}}</v-col>
      <v-col cols="3">Số điện thoại:</v-col>
      <v-col cols="9">{{order.phone}}</v-col>
      <v-col cols="3">Email:</v-col>
      <v-col cols="9">{{order.email}}</v-col>
      <v-col cols="3">Quốc gia:</v-col>
      <v-col cols="9">{{order.country == 0 ? 'Việt Nam' : 'Hàn Quốc'}}</v-col>
      <v-col cols="3">Thành phố:</v-col>
      <v-col cols="9">{{order.city ? order.city : ''}}</v-col>
      <v-col cols="3">Quận/Huyện:</v-col>
      <v-col cols="9">{{order.district ? order.district : ''}}</v-col>
      <v-col cols="3">Phường/Xã:</v-col>
      <v-col cols="9">{{order.ward ? order.ward : ''}}</v-col>
      <v-col cols="3">Địa chỉ:</v-col>
      <v-col cols="9">{{order.address ? order.address : ''}}</v-col>
      <v-col cols="3">Ngày tạo:</v-col>
      <v-col cols="9">{{formatDate(order.created_at)}}</v-col>
      <v-col cols="3">Phương thức thanh toán:</v-col>
      <v-col cols="9"> {{order.payment == 0 ? 'Bank' : 'COD'}}</v-col>
      <v-col cols="3">Tổng tiền:</v-col>
      <v-col cols="9" class="font-weight-bold" style="color: #D50000"> {{order.country == 0 ? formatCurrency(order.total_price) : formatCurrencyKr(order.total_price)}}</v-col>
      <v-col cols="3">Trạng thái:</v-col>
      <v-col cols="9">
        <v-select
            v-model="order.status"
            :items="items"
            placeholder="Trạng thái"
            outlined
            dense
            item-text="value"
            item-value="id"
            clearable
            style="width: 220px"
        >
        </v-select>
      </v-col>
      <v-col cols="12">Sách:</v-col>
      <v-col cols="12">
        <v-data-table
            :headers="headers"
            :items="order.order"
            class="elevation-1"
            :footer-props="{ itemsPerPageOptions: [5, 10, 15, 20] }"
        >
          <template v-slot:item.title="{ item }">
            <span @click="getDetail(item.book_id)" class="cursor">{{item.title}}</span>
          </template>
          <template v-slot:item.thumbnail="{ item }">
            <img :src="item.thumbnail" style="width: 100px"/>
          </template>
          <template v-slot:item.price="{ item }">
            {{order.country == 0 ? formatCurrency(item.price) : formatCurrencyKr(item.price)}}
          </template>
<!--          <template v-slot:item.book_ids="{ item }">-->
<!--            <div v-for="book in item.book_ids">-->
<!--              <div class="box-book">-->
<!--                <img :src="book.thumbnail" style="width: 50px"/>-->
<!--                <span @click="getDetail(book.id)" class="ml-2 cursor">{{book.title}}</span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </template>-->
        </v-data-table>
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <v-btn width="200px" color="primary" @click="saveData">Cập nhật</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HnrInput from "@/components/elements/hnr-input";
import HnrButton from "@/components/elements/hnr-button";
import { mapGetters } from "vuex";

import HnrAreaInput from "@/components/elements/hnr-area-input";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { DETAIL_ORDER, UPDATE_ORDER} from "@/store/order.module";
export default {
  // name: "form",
  //form to create
  data: () => {
    return {
      items: [
        {id: 0, value: 'Chưa thanh toán'},
        {id: 1, value: 'Đã thanh toán'},
      ],
      headers: [
        {
          text: "id",
          align: "start",
          sortable: false,
          value: "book_id",
        },
        { text: "Tên sách", value: "title" },
        { text: "Ảnh bìa", value: "thumbnail" },
        { text: "Số lượng", value: "quantity" },
        { text: "Giá", value: "price" },
        // { text: "Combo sách", value: "book_ids" },
      ]
    };
  },

  components: {
    HnrInput,
    HnrButton,
    HnrAreaInput
  },
  computed: {
    ...mapGetters({
      order: "detailOrder"
    }),
  },
  async mounted() {
    await this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Đơn hàng", route: "/order-book" },
      { title: "Cập nhật đơn hàng", route: `/update-order/${this.$route.params.id}` },
    ]);
    await this.$store.dispatch(DETAIL_ORDER, this.$route.params.id)

    this.list_img = JSON.parse(JSON.stringify(this.book.list_img))
  },

  methods: {
    formatDate(date) {
      if (!date) return null
      const newDate = new Date(date)
      const [year, month, day] = [newDate.getFullYear(), newDate.getMonth() + 1, newDate.getDate()]
      return `${day}/${month}/${year}`
    },
    formatCurrency(price) {
      const formatter = new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
      return formatter.format(parseInt(price));
    },
    formatCurrencyKr(price) {
      const formatter = new Intl.NumberFormat('ko-Kr', {
        style: 'currency',
        currency: 'KRW',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
      return formatter.format(parseInt(price));
    },
    async saveData() {
      let payload = {
        id: this.$route.params.id,
        data: {
          status: this.order.status
        }
      }
      await this.$store.dispatch(UPDATE_ORDER, payload).then((res) => {
        if (res && res.status) {
          this.$toasted.success("Cập nhật thành công!", {
            position:'top-right',
            duration:3000
          });
          this.$router.push('/order-book')
        }
        else {
          this.$toasted.error(res.message, {
            position:'top-right',
            duration:3000
          });
        }
      });
    },
    getDetail(id) {
      this.$router.push('/edit-book/' + id)
    }
  },

};
</script>

<style lang="scss">
.order-detail {
  background: white!important;
}
.box-book {
  display: flex;
  align-items: center;
  border: 1px solid lightgray;
  padding: 4px;
  margin: 8px;
  border-radius: 4px;
  background-color: lightgoldenrodyellow;
}
</style>
