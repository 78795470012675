<template>
  <v-container>
    <ValidationObserver v-slot="{ handleSubmit }">
    <v-tabs v-model="tabIndex">
      <v-tab v-for="item in items" :key="item.id" :disabled="isCreate && item.id == 5 ? true : false">{{ item.tab }}</v-tab>
    </v-tabs>
    <v-tabs-items class="tab-content " v-model="tabIndex">
      <v-tab-item active>
        <t-lesson-content class="tab-detail" :lesson="detailLesson" :isNew="isCreate"></t-lesson-content>
      </v-tab-item>
      <v-tab-item>
        <t-lesson-seo class="tab-detail" :lesson="detailLesson"></t-lesson-seo>
      </v-tab-item>
      <v-tab-item>
        <t-lesson-seo-en class="tab-detail" :lesson="detailLesson"></t-lesson-seo-en>
      </v-tab-item>
       <v-tab-item>
         <hnr-edit-table class="tab-detail" :content="list_lesson_related"
          @addRow="addRow"
          @updateData="updateItemTable"
          @deleteItem="deleteItem"></hnr-edit-table>
      </v-tab-item>
      <v-tab-item>
        <ExamRelated :lesson="detailLesson" @examDialog="examDialog"></ExamRelated>
      </v-tab-item>
    </v-tabs-items>
    <v-row v-if="isCreate" class="align-center justify-center">
      <v-btn v-if="tabIndex != 3 && tabIndex != 4" color="primary" width="100" @click="handleSubmit(saveLesson)">Lưu bài học</v-btn>
    </v-row>
    <v-row v-else class="align-center justify-center">
      <v-btn v-if="tabIndex != 3 && tabIndex != 4" color="primary" width="130" @click="handleSubmit(updateLesson)">Cập nhật bài học</v-btn>
    </v-row>

    <v-dialog
      v-model="dialog"
      max-width="600px"
      min-height="600px"
    >
      <v-card>
        <v-card-title class="text-center">
          <span class="headline">Thêm bài học liên quan</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="align-start justify-center ma-0">
              <v-col cols="8">
                <hnr-input :text="search" v-model="search"></hnr-input>
              </v-col>
              <v-col cols="4">
                <v-btn width="100" color="primary" @click="searchCategoriesByName">Tìm kiếm</v-btn>
              </v-col>
            </v-row>
            <v-row class="align-start justify-center ma-0">
              <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :items="suggestNewCategories"
                  :single-select="singleSelect"
                  item-key="id"
                  show-select
                  class="elevation-1"
                >
                </v-data-table>
            </v-row>
            <v-row class="align-center justify-center ma-0 pt-5">
              <v-btn color="primary" @click="handleSubmit(saveNewsCategory)">Lưu bài học liên quan</v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

      <v-dialog
          v-model="dialogExam"
          max-width="600px"
          min-height="600px"
      >
        <v-card>
          <v-card-title class="text-center">
            <span class="headline">Thêm bài thực hành liên quan</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row class="align-start justify-center ma-0">
                <v-col cols="8">
                  <hnr-input :text="searchText" v-model="searchText"></hnr-input>
                </v-col>
                <v-col cols="4">
                  <v-btn width="100" color="primary" @click="searchExam">Tìm kiếm</v-btn>
                </v-col>
              </v-row>
              <v-row class="align-start justify-center ma-0">
                <v-data-table
                    v-model="selected"
                    :headers="headerExams"
                    :items="listExam"
                    :single-select="singleSelect"
                    item-key="id"
                    show-select
                    class="elevation-1"
                >
                </v-data-table>
              </v-row>
              <v-row class="align-center justify-center ma-0 pt-5">
                <v-btn color="primary" @click="handleSubmit(saveExam)">Lưu bài thực hành liên quan</v-btn>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </ValidationObserver>
  </v-container>
</template>
<script>
import { mapState } from "vuex";

// stores
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { SET_LOADING } from "@/store/loading.module";
import {
  SAVE_LESSON, UPDATE_LESSON, DETAIL_LESSON,
  ADD_CATEGORY_LESSON, DELETE_CATEGORY_LESSON,
  SEARCH_LESSON_BY_NAME,SAVE_EXAM_RELATED,
  GET_LIST_EXAM_RELATED
} from "@/store/lesson.module";
import { SEARCH_NEWS_CATEGORY_BY_NAME } from "@/store/newscategory.module";
import { SUCCESS, ERROR } from "@/store/alert.module";
// components
import TLessonContent from "@/components/lesson/TLessonContent";
import TLessonSeoEn from "@/components/lesson/TLessonSeoEn";
import TLessonSeo from "@/components/lesson/TLessonSeo";
import HnrInput from '@/components/elements/hnr-input'
import HnrEditTable from '@/components/elements/hnr-edit-table'
import ExamRelated from '@/components/lesson/ExamRelated'
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);
export default {
  data() {
    return {
      detailLesson: {
        id: '',
        name: "",
        slug: "",
        file_audio: {},
        link_yt:"",
        audio:"",
        img:"",
        short_content: "",
        content: "",
        count_view:"",
        name_en:"",
        summary: "",
        act: "",
        order: "",
        seo_title: "",
        seo_description: "",
        seo_key_en: "",
        seo_title_en: "",
        seo_description_en: "",
        seo_slug_en: "",
        seo_key: "",
        content_en:"",
        seo_image:"",
      },
      slug: null,
      isCreate: false,
      isRedirect:true,
      tabIndex: 0,
      items: [
        { id: 0, tab: "Thông tin" },
        { id: 1, tab: "Cấu hình SEO" },
        { id: 2, tab: "Cấu hình SEO tiếng anh" },
        { id: 3, tab: "Bài học liên quan" },
        { id: 4, tab: "Bài thực hành liên quan" },
      ],
      categories: [],
      dialog: false,
      dialogExam: false,
      list_lesson_related:[],
      lesson_related:[],
      suggestNewCategories: [],
      listExam: [],
      value: 0,
      search: null,
      searchText: null,
      singleSelect: true,
      selected: [],
      headers: [
        {
          text: 'id',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name' }
      ],
      headerExams: [
        {
          text: 'id',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'title' }
      ],

    };
  },
  components: {
    TLessonSeoEn,
    HnrInput,
    TLessonContent,
    TLessonSeo,
    HnrEditTable,
    ExamRelated
  },
  computed: {
    ...mapState({
      lesson_state: (state) => state.lesson.detailLesson,
      searchLesson: `listNameLesson`,
    }),
    lesson:function (){
      let data= Object.assign({},this.lesson_state);
      // data.content= this.regexImageUrl(data.content);
      return data;
    }
  },
  watch: {
    lesson: {
      deep: true,
      handler(newVal) {
        this.detailLesson = newVal;
        this.getListOfNewsCategory(this.detailLesson.lesson_related);
      },
    },
    lesson_related: {
      deep: true,
      handler(newVal) {
        if (newVal && newVal.length > 20) {
          this.suggestNewCategories = newVal.slice(0, 30);
        }
        else {
          this.suggestNewCategories = newVal;
        }
      },
    }
  },
  created() {
    this.slug = this.$route.params.slug;
    if (this.slug) {
      this.isCreate = false;
      this.$store.dispatch(SET_BREADCRUMB, [{ title: this.slug }]);
      this.$store.dispatch(SET_LOADING, true);
      this.$store.dispatch(DETAIL_LESSON, { slug: this.slug }).then((data) => {
        if(data){
          this.list_lesson_related = data.data.lesson_related;
        }
        this.$store.dispatch(SET_LOADING, false);
      }).catch(() => {
        this.$store.dispatch(SET_LOADING, false);
      });
    }
    else {
      this.isCreate = true;
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Thêm bài học" }]);
    }

  },
  methods: {
    regexImageUrl(content) {
      if (!content) {
        return null;
      }
      // content = content.replace(
      //     /<img([\w\W]+?)>/gi,
      //     function (match) {
      //       match = match.replace(
      //           /src\s*=\s*"/g,
      //           function () {
      //             return 'src="' + process.env.VUE_APP_BASE_URL + "/";
      //           }
      //       );
      //       return match;
      //     }
      // );
      return content;
    },
    searchCategoriesByName() {
      if (this.search) {
        this.$store.dispatch(SET_LOADING, true);
        this.$store.dispatch(SEARCH_LESSON_BY_NAME, {name: this.search}).then((data) => {
          this.suggestNewCategories = data
          this.$store.dispatch(SET_LOADING, false);
        }).catch(() => {
          this.$store.dispatch(SET_LOADING, false);
        });
      }
    },
    searchExam() {
      if (this.searchText) {
        this.$store.dispatch(SET_LOADING, true);
        Vue.axios.get(`${process.env.VUE_APP_URL_API_EXAM}/exams?key=${process.env.VUE_APP_KEY_EXAM}&title=${this.searchText}`, {transformRequest: (data, headers) => {
            delete headers.common['Authorization'];
            return data;
          }
        }).then((data) => {
          if(data && data.data && data.data.status)
          this.listExam = data.data.data
          this.$store.dispatch(SET_LOADING, false);
        }).catch(() => {
          this.$store.dispatch(SET_LOADING, false);
        });
      }
    },
    saveLesson() {
      if(this.detailLesson.order ==="" ){
        this.detailLesson.order = 0
      }
      this.isRedirect = false;
      if(!this.detailLesson.topics) {
        this.$store.dispatch(ERROR, "Vui lòng chọn chủ đề cha !", { root: true });
      }
      else {
        let payload = {
          name: this.detailLesson.name,
          slug: this.detailLesson.slug,
          parent_id: this.detailLesson.topics
              ? this.detailLesson.topics.id
              : null,
          src: this.detailLesson.src,
          img: this.detailLesson.img,
          audio: this.detailLesson.audio,
          name_en: this.detailLesson.name_en,
          short_content: this.detailLesson.short_content,
          content: this.detailLesson.content,
          summary: this.detailLesson.summary,
          act: this.detailLesson.status ? 1 : 0,
          ord: this.detailLesson.order,
          seo_title: this.detailLesson.seo_title,
          seo_description: this.detailLesson.seo_description,
          seo_key: this.detailLesson.seo_key,
          created_at: this.detailLesson.created_at,
          link_yt:this.detailLesson.link_yt,
          seo_title_en: this.detailLesson.seo_title_en,
          seo_slug_en: this.detailLesson.seo_slug_en,
          seo_description_en: this.detailLesson.seo_description_en,
          seo_key_en: this.detailLesson.seo_key_en,
          seo_image: this.detailLesson.seo_image,
          content_en: this.detailLesson.content_en,
        };
        this.$store.dispatch(SET_LOADING, true);
        this.$store.dispatch(SAVE_LESSON, payload).then(data => {
          if (data.status) {
            this.$store.dispatch(SUCCESS, "Thêm bài viết thành công!", { root: true });
            // this.$router.push('/lesson');
          }
          else {
            this.$store.dispatch(ERROR, data.message, { root: true });
          }
          this.$store.dispatch(SET_LOADING, false);
        }).catch(() => {
          this.$store.dispatch(ERROR, "Thêm bài viết thất bại!", { root: true });
          this.$store.dispatch(SET_LOADING, false);
        });
      }

    },
    updateLesson() {
      this.isRedirect = false;
      // this.detailLesson.content=this.updateImageUrl(this.detailLesson.content);
      if(!this.detailLesson.topics ) {
        this.$store.dispatch(ERROR, "Vui lòng chọn chủ đề cha !", { root: true });
      }
      else {
        let payload = {
          src: this.detailLesson.src,
          name_en: this.detailLesson.name_en,
          id: this.detailLesson.id,
          name: this.detailLesson.name,
          slug: this.detailLesson.slug,
          img: this.detailLesson.img,
          audio: this.detailLesson.audio,
          parent_id: this.detailLesson.topics
              ? this.detailLesson.topics.id
              : null,
          short_content: this.detailLesson.short_content,
          content: this.detailLesson.content,
          summary: this.detailLesson.summary,
          act: this.detailLesson.status ? 1 : 0,
          ord: this.detailLesson.order,
          seo_title: this.detailLesson.seo_title,
          seo_description: this.detailLesson.seo_description,
          seo_key: this.detailLesson.seo_key,
          created_at: this.detailLesson.created_at,
          has_change_slug: this.detailLesson.slug !== this.lesson_state.slug,
          link_yt: this.detailLesson.link_yt,
          seo_title_en: this.detailLesson.seo_title_en,
          seo_slug_en: this.detailLesson.seo_slug_en,
          seo_description_en: this.detailLesson.seo_description_en,
          seo_key_en: this.detailLesson.seo_key_en,
          content_en: this.detailLesson.content_en,
          seo_image: this.detailLesson.seo_image,
        };
        this.$store.dispatch(SET_LOADING, true);
          console.log(payload)
        this.$store.dispatch(UPDATE_LESSON, payload).then(data => {
          if (data.status) {
            this.$store.dispatch(SUCCESS, "Cập nhật bài viết thành công!", {root: true});
            // this.$router.push('/lesson');
          } else {
            if (data.errorCode == 424) {
              this.$store.dispatch(ERROR, data.message, {
                root: true,
              });
            } else {
              this.$store.dispatch(ERROR, data.message, {
                root: true,
              });
            }
          }
          this.$store.dispatch(SET_LOADING, false);
        }).catch(() => {
          this.$store.dispatch(ERROR, "Thêm bài viết thất bại!", {root: true});
          this.$store.dispatch(SET_LOADING, false);
        });
      }
    },
    updateImageUrl(content){
      if(!content){
        return null;
      }
      // content = content.replace(
      //     /<img([\w\W]+?)>/gi,
      //     function (match) {
      //       match = match.replace(
      //           /src="([^"]+)"/g,
      //           function (match_child) {
      //             let base_url= process.env.VUE_APP_BASE_URL
      //             var regex = new RegExp(base_url+"/(.*)", "");
      //             let relative_path = "";
      //             console.log(base_url+"/(.*)");
      //             let relative_path_arr =match_child.match(regex)
      //             if(relative_path_arr && relative_path_arr.length>0){
      //               relative_path=relative_path_arr[1];
      //               return 'src="'+relative_path;
      //             }
      //           }
      //       );
      //       return match;
      //     }
      // );
      return content;
    },

    addRow() {
      this.dialog = true;
    },
    examDialog() {
      this.dialogExam = true;
    },
    updateItemTable(payload) {
      var index = this.detailLesson.lesson_related.findIndex(item => item.id == payload.id);
      this.detailLesson.lesson_related[index] = payload;
    },
    deleteItem(item) {
      if (confirm("Bạn có chắc muốn xóa không?")) {
        let payload = { lesson_id: this.detailLesson.id, lesson_related_id: item.id };

        this.$store.dispatch(SET_LOADING, true);
        this.$store.dispatch(DELETE_CATEGORY_LESSON, payload).then(data => {
          if (data.status) {
            this.$store.dispatch(SUCCESS, "Xóa chủ đề thành công!", { root: true });
            this.$store.dispatch(DETAIL_LESSON, { slug: this.slug }).then((data) => {
              if(data){
                this.list_lesson_related = data.data.lesson_related;
              }
            })
          }
          else {
            this.$store.dispatch(ERROR, "Xóa chủ đề thất bại!", { root: true });
          }
          this.$store.dispatch(SET_LOADING, false);
        }).catch(() => {
          this.$store.dispatch(ERROR, "Xóa chủ đề thất bại!", { root: true });
          this.$store.dispatch(SET_LOADING, false);
        });
      }
    },

    getListOfNewsCategory(news2Categories) {
      let newCategories = [];
        this.categories = newCategories;
    },

    saveExam() {
      if (this.selected && this.selected.length > 0) {
        this.value = this.selected[0];
      }
      if (this.value) {
        let payload = {
          lesson_id: this.detailLesson.id,
          exam_id: this.value.id,
          exam_slug: this.value.slug,
          exam_name: this.value.title,
        };
        this.$store.dispatch(SET_LOADING, true);
        this.$store.dispatch(SAVE_EXAM_RELATED, payload).then(data => {
          if (data) {
            console.log(data,'data')
            this.$store.dispatch(GET_LIST_EXAM_RELATED, {lesson_id: this.detailLesson.id})
            this.$store.dispatch(SET_LOADING, false);
            this.$store.dispatch(SUCCESS, "Thêm chủ đề thành công!", { root: true });
          }
          else {
            this.$store.dispatch(ERROR, data.message, {
              root: true,
            });
          }
          this.$store.dispatch(SET_LOADING, false);
          this.dialogExam = false;
        }).catch(() => {
          this.$store.dispatch(ERROR, "Thêm chủ đề thất bại!", { root: true });
          this.$store.dispatch(SET_LOADING, false);
        });
      }
    },

    saveNewsCategory() {
      if (this.selected && this.selected.length > 0) {
        this.value = this.selected[0].id;
      }
      if (this.value) {
        let payload = { lesson_id: this.detailLesson.id, lesson_related_id: this.value };
        this.$store.dispatch(SET_LOADING, true);
        this.$store.dispatch(ADD_CATEGORY_LESSON, payload).then(data => {
          if (data.status) {
            this.slug = this.$route.params.slug;
            this.$store.dispatch(DETAIL_LESSON, { slug: this.slug }).then((data) => {
              if(data){
                this.list_lesson_related = data.data.lesson_related;
              }
              this.$store.dispatch(SET_LOADING, false);
            })
            this.$store.dispatch(SUCCESS, "Thêm chủ đề thành công!", { root: true });
          }
          else {
            this.$store.dispatch(ERROR, data.message, {
              root: true,
            });
          }
          this.$store.dispatch(SET_LOADING, false);
          this.dialog = false;
        }).catch(() => {
          this.$store.dispatch(ERROR, "Thêm chủ đề thất bại!", { root: true });
          this.$store.dispatch(SET_LOADING, false);
        });
      }
    },

  },
  beforeRouteLeave (to, from, next) {
    if(this.isRedirect){
      if (confirm("Bạn chưa lưu bài viết.Bạn vẫn muốn rời đi chứ?")) {
        next()
      }else{
        next(false)
      }
    }else{
      next();
    }
  },
};
</script>


<style lang="scss">
  .tab-detail{
    margin-top:15px;
  }
</style>
