import ApiService from "@/common/api.service";

// action types
export const GET_LIST_ORDER = "/order-book/list";
export const DETAIL_ORDER = "/order-book/detail";
export const UPDATE_ORDER = "/order-book/update";
export const DELETE_ORDER_BOOK = "/order-book/delete";
export const EXPORT_ORDER = "/export-order-excel";

// mutation types
export const SET_ORDER = "setOrder";
export const SET_DETAIL_ORDER = "setDetailOrder";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  orders: [],
  order: {},
};

const getters = {
  listOrder(state) {
    return state.orders;
  },
  detailOrder(state) {
    return state.order;
  },
};

const actions = {
  [GET_LIST_ORDER](context, payload) {
    return new Promise(resolve => {
      ApiService.query(GET_LIST_ORDER, payload)
        .then((data) => {
          if (data.status) {
            context.commit(SET_ORDER, data);
          } else {
            context.commit(SET_ERROR, data.message);
          }
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  [DETAIL_ORDER](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(DETAIL_ORDER, payload)
          .then(( data ) => {
            context.commit(SET_DETAIL_ORDER, data);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response);
            reject(response)
          });
    });
  },

  [UPDATE_ORDER](context,payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`${UPDATE_ORDER}/${payload.id}`, payload.data)
          .then(data => {
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response.data.errors);
            reject(response);
          });
    });
  },
    [EXPORT_ORDER](context,payload) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${EXPORT_ORDER}`, payload)
          .then(data => {
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response.data.errors);
            reject(response);
          });
    });
  },
  [DELETE_ORDER_BOOK](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(DELETE_ORDER_BOOK, payload)
          .then((data) => {
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response.data.errors);
            reject(response);
          });
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_ORDER](state, data) {
    state.orders = data.data;
    state.errors = {};
  },
  [SET_DETAIL_ORDER](state, data) {
    state.order = data.data;
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
