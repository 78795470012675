import ApiService from "@/common/api.service";

// action types
export const SAVE_DOCUMENT_DOWNLOAD = "/download-document/create";
export const GET_LIST_DOCUMENT = "/download-document/list";
export const SAVE_UPLOAD_DOCUMENT = "/download-document/upload";
export const DETAIL_DOCUMENT = "/download-document/detail";
export const UPDATE_DOCUMENT = "/download-document/update";
export const DELETE_DOCUMENT = "/download-document/delete";
// mutation types
export const SET_DOCUMENT = "setDocument";
export const SET_DETAIL_DOCUMENT = "setDetailDocument";
export const SET_COUNT_LESSON = "setCountLesson";
export const SET_ERROR = "setError";

const state = {
    errors: null,
    documents: [],
    src: [],
    detail_document: {}
};

const getters = {
    listDownload(state) {
        return state.documents;
    },
    detailDownload(state) {
        return state.detail_document;
    },
};

const actions = {
    [GET_LIST_DOCUMENT](context, payload) {
        return new Promise((resolve,reject) => {
            ApiService.query(GET_LIST_DOCUMENT, payload).then(({ data }) => {
                if (data) {
                    context.commit(SET_DOCUMENT, data);
                }
                resolve(data);
            })
        .catch(({ response }) => {
                context.commit(SET_ERROR, response.data.errors);
                reject(response);
            });
        });
    },
    [DETAIL_DOCUMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(DETAIL_DOCUMENT, payload)
                .then(({ data }) => {
                    context.commit(SET_DETAIL_DOCUMENT, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response);
                    reject(response)
                });
        });
    },
    [SAVE_DOCUMENT_DOWNLOAD](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(SAVE_DOCUMENT_DOWNLOAD, payload)
                .then(data => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                    reject(response);
                });
        });
    },
    [SAVE_UPLOAD_DOCUMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(SAVE_UPLOAD_DOCUMENT, payload)
                .then(( data ) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [UPDATE_DOCUMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(UPDATE_DOCUMENT, payload)
                .then(data => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [DELETE_DOCUMENT](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(DELETE_DOCUMENT, id)
                .then(data => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                    reject(response);
                });
        });
    },
};

const mutations = {
    [SET_DOCUMENT](state, data) {
        state.documents = data
        state.errors = {};
    },
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_DETAIL_DOCUMENT](state, data) {
        state.detail_document = data;
        state.errors = {};
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
