<template>
  <v-container>
    <v-row class="justify-center my-3">
      <v-col cols="3" class="pa-1 mt-5">
        <h5> Upload ảnh</h5>
      </v-col>
      <v-col cols="9" class="pa-1">
        <v-file-input
            v-model="img"
            label="Tải file ảnh"
            @change="onChangeImageUpload"
            clearable
        ></v-file-input>
        <div >
          <img :src="image ? image : ''" class="upload-img"/>
        </div>

        <div class="mt-6" v-if="image">
          <input type="text" class="input-upload" :value="image" ref="input"/>
          <v-btn
              class=" ml-3"
              color="primary"
              dark
              @click="copy"
          >
            Copy link
          </v-btn>
        </div>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import HnrInput from '@/components/elements/hnr-input'
import HnrAreaInput from '@/components/elements/hnr-area-input'
import {
  SAVE_UPLOAD_LESSON
} from "@/store/lesson.module";

export default {
  data() {
    return {
      img: {},
      detailLesson: {},
      image:null,
    };
  },
  components: {
    HnrInput,
    HnrAreaInput
  },
  methods: {
    copy() {
      this.text = this.image
      let copyText = this.$refs.input;
      copyText.value=this.text
      copyText.select();
      document.execCommand('copy');
      this.$toasted.success("Copy thành công!", {
        position:'top-right',
        duration:3000
      });
    },
    onChangeImageUpload() {
      let formData = new FormData();
      formData.append("upload", this.img);
      this.$store.dispatch(SAVE_UPLOAD_LESSON, formData).then((data) => {
        if (data) {
          this.image = data;
          this.$toasted.success("Tải lên thành công!", {
            position:'top-right',
            duration:3000
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.upload-img {
  max-width: 300px; max-height: 300px;object-fit: contain
}
.input-upload {
  min-width: 300px;
  min-height: 35px;
  border: 1px solid black;
  border-radius: 5px;
  padding-left: 8px;
}
</style>
