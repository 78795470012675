<template>
  <v-container class="test-form px-14 pa-14">
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-form
          @submit.prevent="handleSubmit(saveData)"
          method="post"
          id="check-login-form"
      >
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            Tên sách<span class="red--text font-weight-normal">(*)</span>
          </v-col>
          <v-col cols="10">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <HnrInput v-model="book.title" :text="book.title" @onKeyUp="onKeyUp"></HnrInput>
              <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            Slug
          </v-col>
          <v-col cols="10">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <HnrInput v-model="book.slug" :text="book.slug"></HnrInput>
              <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            Ảnh bìa
            <span class="red--text font-weight-normal">(*)</span>
          </v-col>
          <v-col cols="10">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <v-file-input
                  v-model="src1"
                  label="Tải ảnh"
                  @change="onChangeFileUpload"
                  @click:clear="clearImage"
              ></v-file-input>
              <div style="max-width: 200px; object-fit: contain">
                <img :src="book.thumbnail" style="width: 200px"/>
              </div>
              <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </v-col>
        </v-row>
<!--        <v-row>-->
<!--          <v-col cols="2" class="h4 font-weight-black">-->
<!--            File sách-->
<!--          </v-col>-->
<!--          <v-col cols="10">-->
<!--              <v-file-input-->
<!--                  v-model="src2"-->
<!--                  label="Tải file"-->
<!--                  @change="onChangeFileUpload2"-->
<!--                  @click:clear="clearImage"-->
<!--              ></v-file-input>-->
<!--          </v-col>-->
<!--        </v-row>-->
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            Giá sách VN<span class="red--text font-weight-normal">(*)</span>
          </v-col>
          <v-col cols="10">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <HnrInput v-model="book.price" type="number"></HnrInput>
              <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            Giá sách KR<span class="red--text font-weight-normal">(*)</span>
          </v-col>
          <v-col cols="10">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <HnrInput v-model="book.price_kr"></HnrInput>
              <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
           Ship<span class="red--text font-weight-normal">(*)</span>
          </v-col>
          <v-col cols="10">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <HnrInput v-model="book.ship"></HnrInput>
              <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            Loại sách<span class="red--text font-weight-normal">(*)</span>
          </v-col>
          <v-col cols="3">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <v-select
                  v-model="book.type"
                  :items="book_type"
                  placeholder="Chọn loại sách"
                  outlined
                  item-value="value"
                  item-text="text"
                  dense
                  @change="getTypeBook($event)"
              ></v-select>
              <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </v-col>
          <v-col cols="2" class="h4 font-weight-black" v-if="is_show_combo">
            Chọn sách
          </v-col>
          <v-col cols="5" v-if="is_show_combo">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <v-select
                  v-model="book_ids"
                  :items="listBooks"
                  placeholder="Chọn loại sách"
                  outlined
                  item-value="id"
                  item-text="title"
                  dense
                  multiple
                  chips
                  persistent-hint
              ></v-select>
              <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </v-col>
<!--          <v-col cols="2" class="h4 font-weight-black" v-if="is_show_combo">-->
<!--           Chọn combo-->
<!--          </v-col>-->
<!--          <v-col cols="2" v-if="is_show_combo">-->
<!--            <ValidationProvider rules="required" v-slot="{ errors }">-->
<!--              <v-select-->
<!--                  v-model="book.quantity"-->
<!--                  :items="book_combo"-->
<!--                  placeholder="Chọn combo"-->
<!--                  outlined-->
<!--                  item-value="value"-->
<!--                  item-text="text"-->
<!--                  dense-->
<!--              ></v-select>-->
<!--              <span class="validate-error text-danger text-danger">-->
<!--                {{ errors[0] }}-->
<!--              </span>-->
<!--            </ValidationProvider>-->
<!--          </v-col>-->
<!--          <v-col cols="2" class="h4 font-weight-black" v-if="is_show_combo">-->
<!--            Số lượng-->
<!--          </v-col>-->
<!--          <v-col cols="1" v-if="is_show_combo">-->
<!--            <ValidationProvider rules="required" v-slot="{ errors }">-->
<!--              <v-text-field dense outlined v-model="book.total_book" type="number" min="1"/>-->
<!--              <span class="validate-error text-danger text-danger">-->
<!--                {{ errors[0] }}-->
<!--              </span>-->
<!--            </ValidationProvider>-->
<!--          </v-col>-->
        </v-row>
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            Ảnh sách
            <span class="red--text font-weight-normal">(*)</span>
          </v-col>
          <v-col cols="10">
              <v-file-input
                  v-model="list_img"
                  placeholder="Tải ảnh (Nhập ít nhất 1 ảnh)"
                  multiple
                  outlined
                  dense
                  @change="multiSelectImage"
              ></v-file-input>
          <v-row v-if="list_img && list_img.length > 0">
            <v-col cols="4" v-for="(item, index) in list_img" :key="index">
              <img :src="item" style="width: 200px"/>
            </v-col>
          </v-row>
          </v-col>
        </v-row>
        <v-row class="align-center justify-center">
          <v-col cols="2" class="h4 font-weight-black">
            Nội dung ngắn
          </v-col>
          <v-col cols="10" class="pa-1">
            <hnr-area-input
                :text="book.short_description"
                @input="update"
                v-model="book.short_description"
            ></hnr-area-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            Nội dung sách
          </v-col>
          <v-col cols="10">
            <HnrInput
                v-model="book.description"
                :text="book.description"
                class="d-none"
            ></HnrInput>
            <CkContent :content="book.description" @getData="getContent"></CkContent>
            <span class="mt-4 validate-error text-danger text-danger">
              {{ contentErrorMessage }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            SEO tiêu đề
          </v-col>
          <v-col cols="10">
              <HnrInput v-model="book.seo_title" :text="book.seo_title"></HnrInput>

          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            SEO từ khóa
          </v-col>
          <v-col cols="10">
              <HnrInput v-model="book.seo_keyword" :text="book.seo_keyword"></HnrInput>
          </v-col>
        </v-row>
        <v-row class="align-center justify-center">
          <v-col cols="2" class="h4 font-weight-black">
            SEO nội dung
          </v-col>
          <v-col cols="10" class="pa-1">
            <hnr-area-input
                :text="book.seo_description"
                @input="update"
                v-model="book.seo_description"
            ></hnr-area-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12 text-center">
            <span class="validate-error text-danger text-danger float-left">
              (*) : Mục cần nhập
            </span>
            <v-btn
                color="primary"
                rounded
                outlined
                width="100"
                type="submit"
                form="check-login-form"
            >
              Lưu
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>

<script>
import CkContent from "@/components/ck-content";
import {SAVE_UPLOAD_DOCUMENT} from "@/store/download.module";
import HnrInput from "@/components/elements/hnr-input";
import HnrButton from "@/components/elements/hnr-button";
import { mapGetters } from "vuex";
import mixin from "@/store/mixin";
import HnrAreaInput from "@/components/elements/hnr-area-input";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { SAVE_BOOK } from "@/store/book.module";
import { SET_LOADING } from "@/store/loading.module";
import { GET_LIST_BOOK } from "@/store/book.module";
export default {
  // name: "form",
  mixins:[mixin],
  //form to create
  data: () => {
    return {
      alerts: true,
      src1: {},
      src2: {},
      list_img:[],
      fileInputValue: [],
      file: null,
      cardImg: "",
      cardImg2: "",
      check: "",
      image: {},
      categories: 0,
      contentErrorMessage: "",
      img_error_msg:"",
      book: {
        title: "",
        slug: "",
        price: "",
        description:"",
        short_description:"",
        thumbnail:"",
        price_kr:"",
        type:null,
        quantity:0,
        ship:0,
        file:"",
        seo_title:"",
        seo_keyword:"",
        seo_description:"",
        total_book: 1,
        list_img: [],
      },
      is_show_combo:false,
      book_type: [
        {value: 0, text: 'Sách đơn'},
        {value: 1, text: 'Combo sách'},
      ],
      book_combo: [1,2,3,4],
      book_ids: [],
    };
  },
  computed: {
    ...mapGetters({
      listBooks: "listBooks",
    }),
  },
  components: {
    CkContent,
    HnrInput,
    HnrButton,
    HnrAreaInput
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Sách", route: "/list-book" },
      { title: "Thêm sách", route: "/add-book" },
    ]);
    this.getBooks();
  },

  methods: {
    getBooks() {
      this.$store.dispatch(SET_LOADING, true);
      this.$store
          .dispatch(GET_LIST_BOOK, {type:0})
          .then(() => {
            this.$store.dispatch(SET_LOADING, false);
          })
          .catch(() => {
            this.$store.dispatch(SET_LOADING, false);
          });
    },
    getTypeBook(e) {
      this.is_show_combo = e === 1 ? true : false
    },
    update() {
      this.$emit("input");
    },
    onKeyUp(value){
      this.book.slug = this.sanitizeTitle(value);
    },
    onChangeFileUpload() {
      this.cardImg = URL.createObjectURL(this.src1);
      this.imageError = "";
      let formData = new FormData();
      formData.append("upload", this.src1);
      this.$store.dispatch(SAVE_UPLOAD_DOCUMENT, formData).then((data) => {
        if (data && data.status == 1) {
          this.book.thumbnail = data.data;
          this.$toasted.success("Tải lên thành công!", {
            position:'top-right',
            duration:3000
          });
        }
        else {
          this.$toasted.error("Tải lên thất bại!", {
            position:'top-right',
            duration:3000
          });
        }
      });
    },
    onChangeFileUpload2() {
      this.cardImg2 = URL.createObjectURL(this.src2);
      this.imageError = "";
      let formData = new FormData();
      formData.append("upload", this.src2);
      this.$store.dispatch(SAVE_UPLOAD_DOCUMENT, formData).then((data) => {
        if (data && data.status == 1) {
          this.book.thumbnail = data.data;
          this.$toasted.success("Tải lên thành công!", {
            position:'top-right',
            duration:3000
          });
        }
        else {
          this.$toasted.error("Tải lên thất bại!", {
            position:'top-right',
            duration:3000
          });
        }
      });
    },
    clearImage() {
      this.book.file = "";
      this.book.thumbnail = "";
      this.show = false;
    },
    multiSelectImage(event) {
      this.fileInputValue = []
      if (event.length > 0) {
        const tempArray = [];
        const listSrc = [];
        event.map((item) => {
          listSrc.push(item)
          const src = item ? URL.createObjectURL(item) : null;
          if (src && typeof src == "string") {
            tempArray.push(src);
          }
        });
        this.list_img = this.fileInputValue.concat(tempArray);
        this.book.list_img = this.fileInputValue.concat(listSrc);
      }
    },
    getContent(value) {
      this.book.description = value;
    },

    //save book
    async saveData() {
      if (this.book.description.length == 0) {
        this.contentErrorMessage = "Vui lòng nhập nội dung của bài viết";
      } else {
        this.contentErrorMessage = "";
        let formData = new FormData();
        let book_ids = JSON.stringify(this.book_ids)
        formData.append('title', this.book.title);
        formData.append('slug', this.book.slug);
        formData.append('description', this.book.description);
        formData.append('short_description', this.book.short_description);
        formData.append('price', this.book.price);
        formData.append('price_kr', this.book.price_kr);
        formData.append('thumbnail', this.book.thumbnail);
        formData.append('file', this.book.file);
        formData.append('total_book', this.book.total_book);
        formData.append('type', this.book.type);
        formData.append('ship', this.book.ship);
        formData.append('book_ids', book_ids ?? "");
        formData.append('quantity',this.book.type === 1 ? this.book.quantity : 0);
        formData.append('seo_title', this.book.seo_title);
        formData.append('seo_keyword', this.book.seo_keyword);
        formData.append('seo_description', this.book.seo_description);
        if(this.book.list_img.length > 0) {
          this.book.list_img.forEach((item, index) => {
            formData.append(`list_img[${index}]`, item);
          })
        }
        else {
          formData.append(`list_img`, []);
        }
        await this.$store.dispatch(SAVE_BOOK, formData).then((res) => {
          if (res && res.status) {
            this.$toasted.success("Thêm mới thành công!", {
              position:'top-right',
              duration:3000
            });
            this.$router.push('/list-book')
          }
          else {
            this.$toasted.error(res.message, {
              position:'top-right',
              duration:3000
            });
          }
        });
      }
    }
  },
  getCurrentDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + "-" + mm + "-" + yyyy;

    return today;
  }

};
</script>

<style></style>
