<template>
  <v-container>
    <v-row class="align-center justify-center">
        <v-col cols="2" class="pa-1">
            <span> Tiêu đề SEO</span>
        </v-col>
        <v-col cols="10" class="pa-1">
            <hnr-input :text="detailCategory.seo_title" :blur="triggerSeo" @input="update" v-model="detailCategory.seo_title"></hnr-input>
        </v-col>
    </v-row>

    <v-row class="align-center justify-center">
        <v-col cols="2" class="pa-1">
            <span> Từ khóa SEO </span>
        </v-col>
        <v-col cols="10" class="pa-1">
            <hnr-area-input :text="detailCategory.seo_key" :blur="triggerSeo" @input="update" v-model="detailCategory.seo_key"></hnr-area-input>
        </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Mô tả SEO</span>
      </v-col>
      <v-col cols="10" class="pa-1">
<!--        <hnr-area-input :text="detailCategory.seo_description" @input="update" v-model="detailCategory.seo_description"></hnr-area-input>-->
        <ck-content
            id="lesson_content"
            :content="detailCategory.seo_description"
            @getData="updateContent"
            v-model="detailCategory.seo_description"
        ></ck-content>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
      </v-col>
      <v-col cols="10" class="pa-1">
        <seo
            @getKeywords="getKeywords"
            :trigger_seo="count_trigger_seo"
            :text_seo="text_seo"
            :meta_title="detailCategory.seo_title"
            :content="detailCategory.seo_description"
            :slug="detailCategory.slug"
            :meta_description="detailCategory.seo_description"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HnrInput from '@/components/elements/hnr-input'
import HnrAreaInput from '@/components/elements/hnr-area-input'
import CkContent from "@/components/ck-content";
import Seo from "@/components/seo";
export default {
  props: {
    category: Object,
  },
  watch: {
      category: {
          deep: true,
          immediate: true,
          handler(newVal) {
              this.detailCategory = newVal;
          }
      }
  },
  data() {
    return {
        detailCategory: {},
      count_trigger_seo: 0,
      text_seo: "",
    };
  },
  components: {
      HnrInput,
      HnrAreaInput,
    CkContent,
    Seo
  },
  methods: {
    triggerSeo(){
      this.count_trigger_seo++;
    },
    getKeywords(keywords){
      this.detailCategory.seo_key = keywords
    },
    updateContent(val) {
      this.detailCategory.seo_description = val;
    },
    onClick() {
    },
    update() {
        this.$emit("input");
    },
  },
};
</script>

<style lang="scss">
</style>
