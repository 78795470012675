<template>
  <v-container>
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-tabs v-model="tabIndex">
        <v-tab v-for="item in items" :key="item.id" :disabled="isCreate && item.id == 3 ? true : false">{{
            item.tab
          }}
        </v-tab>
      </v-tabs>
      <v-tabs-items class="tab-content " v-model="tabIndex">
        <v-tab-item active>
          <v-btn
              color="primary"
              class="mt-4"
              @click="deleteDetailAnswer"
          >
            Xoá
          </v-btn>
          <t-answer-content class="tab-detail" :answer="detailAnswer" :isNew="isCreate"></t-answer-content>
        </v-tab-item>
        <v-tab-item>
          <t-answer-seo class="tab-detail" :answer="detailAnswer"></t-answer-seo>
        </v-tab-item>
        <v-tab-item>
          <t-answer-seo-en class="tab-detail" :answer="detailAnswer"></t-answer-seo-en>
        </v-tab-item>
      </v-tabs-items>
      <v-row v-if="isCreate" class="align-center justify-center">
        <v-btn color="primary" @click="handleSubmit(saveLesson)">Lưu bài viết</v-btn>
      </v-row>
      <v-row v-else class="align-center justify-center">
        <v-btn color="primary" @click="handleSubmit(updateLesson)">Cập nhật bài viết</v-btn>
      </v-row>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline"
          >Bạn có chắc muốn xóa không?
          </v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete"
            >Thoát
            </v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >Xóa
            </v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </ValidationObserver>
  </v-container>
</template>
<script>
import {mapGetters} from "vuex";

// stores
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {SET_LOADING} from "@/store/loading.module";
import {SAVE_ANSWER, UPDATE_ANSWER,DETAIL_ANSWER,DELETE_ANSWER} from "@/store/answer.module";
import {SUCCESS, ERROR} from "@/store/alert.module";
// components
import TAnswerContent from "@/components/answer/TAnswerContent";
import TAnswerSeo from "@/components/answer/TAnswerSeo";
import TAnswerSeoEn from "@/components/answer/TAnswerSeoEn";


export default {
  data() {
    return {
      dialogDelete:false,
      detailAnswer: {
        id: '',
        title: "",
        slug: "",
        content: "",
        content_en: "",
        seo_content: "",
        seo_content_en: "",
        seo_key: "",
        seo_key_en: "",
      },
      slug: null,
      isCreate: false,
      isRedirect: true,
      tabIndex: 0,
      items: [
        {id: 0, tab: "Thông tin"},
        {id: 1, tab: "Thông tin SEO"},
        {id: 2, tab: "Thông tin SEO tiếng anh"},
      ],
      categories: [],
      dialog: false,

      suggestNewCategories: [],
      // selectedCategories: [],
      value: 0,
      search: null,
      singleSelect: true,
      selected: [],
      headers: [
        {
          text: 'id',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name'
        }
      ],

    };
  },
  components: {
    TAnswerContent,
    TAnswerSeo,
    TAnswerSeoEn
  },
  computed: {
    ...mapGetters({
      answer: "detailAnswer",
    }),
  },
  watch: {
    answer: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.detailAnswer = newVal;
      },
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.resetAnswer();
    if (this.id) {
      this.isCreate = false;
      this.$store.dispatch(SET_BREADCRUMB, [{title: this.slug}]);
      this.$store.dispatch(SET_LOADING, true);
      this.$store.dispatch(DETAIL_ANSWER, {id: this.id}).then(() => {
        this.$store.dispatch(SET_LOADING, false);
        if(!this.detailAnswer.vote_summary){
          this.detailAnswer.vote_summary = {
            score:0,
            number:0
          }
        }
      }).catch(() => {
        this.$store.dispatch(SET_LOADING, false);
      });
    } else {
      this.isCreate = true;
      this.detailAnswer.vote_summary = {
        score:0,
        number:0
      }
      this.$store.dispatch(SET_BREADCRUMB, [{title: "Thêm câu trả lời"}]);
    }
  },
  methods: {
    deleteDetailAnswer() {
      this.dialogDelete = true
    },
    closeDelete() {
      this.dialogDelete = false
    },
    deleteItemConfirm() {
      this.$store.dispatch(DELETE_ANSWER,this.answer.id).then(data =>{
        if(data && data.status == 1) {
          this.$router.push('/answer')
        }
      })
    },
    resetAnswer(){
      this.detailAnswer = {}
    },
    regexImageUrl(content) {
      if (!content) {
        return null;
      }
      content = content.replace(
          /<img([\w\W]+?)>/gi,
          function (match) {
            match = match.replace(
                /src\s*=\s*"/g,
                function () {
                  return 'src="' + process.env.VUE_APP_BASE_URL + "/";
                }
            );
            return match;
          }
      );
      return content;
    },

    saveLesson() {
      this.isRedirect = false;
      this.detailAnswer.status = 1;
      // this.detailAnswer.ask.is_reply = 1

      this.$store.dispatch(SET_LOADING, true);
      this.$store.dispatch(SAVE_ANSWER, this.detailAnswer).then(data => {
        if (data.status) {
          this.$store.dispatch(SUCCESS, "Thêm bài viết thành công!", {root: true});
          this.$router.push('/answer');
        } else {
          this.$store.dispatch(ERROR, data.message, {root: true});
        }
        this.$store.dispatch(SET_LOADING, false);
      }).catch(() => {
        this.$store.dispatch(ERROR, "Thêm bài viết thất bại!", {root: true});
        this.$store.dispatch(SET_LOADING, false);
      });
    },
    updateLesson() {
      this.isRedirect = false;
      this.$store.dispatch(SET_LOADING, true);
      this.detailAnswer.status = 1;
      this.detailAnswer.ask.is_reply = 1
      this.$store.dispatch(UPDATE_ANSWER, this.detailAnswer).then(data => {
        if (data.status) {
          this.$store.dispatch(SUCCESS, "Cập nhật bài viết thành công!", {root: true});
          this.$router.push('/answer');
        } else {
          if (data.errorCode == 424) {
            this.$store.dispatch(ERROR, data.message, {
              root: true,
            });
          } else {
            this.$store.dispatch(ERROR, "Cập nhật bài viết thất bại!", {
              root: true,
            });
          }
        }
        this.$store.dispatch(SET_LOADING, false);
      }).catch(() => {
        this.$store.dispatch(ERROR, "Thêm bài viết thất bại!", {root: true});
        this.$store.dispatch(SET_LOADING, false);
      });
    },
    updateImageUrl(content) {
      if (!content) {
        return null;
      }
      content = content.replace(
          /<img([\w\W]+?)>/gi,
          function (match) {
            match = match.replace(
                /src="([^"]+)"/g,
                function (match_child) {
                  let base_url = process.env.VUE_APP_BASE_URL
                  var regex = new RegExp(base_url + "/(.*)", "");
                  let relative_path = "";
                  let relative_path_arr = match_child.match(regex)
                  if (relative_path_arr.length > 0) {
                    relative_path = relative_path_arr[1];
                    return 'src="' + relative_path;
                  }
                }
            );
            return match;
          }
      );
      return content;
    },

    addRow() {
      this.dialog = true;
    },
    updateItemTable(payload) {
      var index = this.categories.findIndex(item => item.id == payload.id);
      this.categories[index] = payload;
    },
    // deleteItem(item) {
    //   if (confirm("Are you sure you want to delete this item?")) {
    //     let payload = { news_id: this.detailAnswer.id, news_category_id: item.id };
    //
    //     this.$store.dispatch(SET_LOADING, true);
    //     this.$store.dispatch(DELETE_CATEGORY_LESSON, payload).then(data => {
    //       if (data.status) {
    //         this.$store.dispatch(SUCCESS, "Xóa chủ đề thành công!", { root: true });
    //       }
    //       else {
    //         this.$store.dispatch(ERROR, "Xóa chủ đề thất bại!", { root: true });
    //       }
    //       this.$store.dispatch(SET_LOADING, false);
    //     }).catch(() => {
    //       this.$store.dispatch(ERROR, "Xóa chủ đề thất bại!", { root: true });
    //       this.$store.dispatch(SET_LOADING, false);
    //     });
    //   }
    // },

  },
  beforeRouteLeave(to, from, next) {
    if (this.isRedirect) {
      if (confirm("Bạn chưa lưu bài viết.Bạn vẫn muốn rời đi chứ?")) {
        next()
      } else {
        next(false)
      }
    } else {
      next();
    }
  },
};
</script>


<style lang="scss">
.tab-detail {
  margin-top: 15px;
}
</style>
