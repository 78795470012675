import ApiService from "@/common/api.service";

// action types
export const DETAIL_LESSON = "/lesson/detail";
export const SAVE_LESSON = "/lesson/save";
export const ADD_CATEGORY_LESSON = "/lesson/save-lesson-related";
export const DELETE_CATEGORY_LESSON = "/lesson/delete-lesson";
export const UPDATE_LESSON = "/lesson/update";
export const COUNT_LESSON = "/lesson/count";
export const GET_LIST_LESSONS = "/lesson/list";
export const GET_LIST_LESSONS_RELATED = "/lesson/list-lesson-related";
export const DELETE_LESSON = "/lesson/delete";
export const SAVE_UPLOAD_LESSON= "/lesson/upload";
export const SEARCH_LESSON_BY_NAME= "/lesson/search-lesson";
export const GET_LIST_EXAM_RELATED= "/exam-related/list";
export const SAVE_EXAM_RELATED= "/exam-related/create";
export const DELETE_EXAM_RELATED= "/exam-related/delete";

// mutation types
export const SET_LESSON = "setLesson";
export const SET_LESSON_RELATED = "setLessonRelated";
export const SET_EXAM_RELATED = "setExamRelated";
export const SET_NAME_LESSON = "setNameLesson";
export const SET_DETAIL_LESSON = "setDetailLesson";
export const SET_COUNT_LESSON = "setCountLesson";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  lessons: [],
  lesson_relateds: [],
  exam_relateds: [],
  detailLesson: {},
  lesson_count: 0,
  images: [],
  src: [],
  name_of_lessons: [],
};

const getters = {
  listLessons(state) {
    return state.lessons;
  },
  listExam(state) {
    return state.exam_relateds;
  },
  listLessonRelateds(state) {
    return state.lesson_relateds;
  },
  // listImages(state) {
  //   return state.images;
  // },
  listNameLesson(state) {
    return state.name_of_lessons;
  },
  lessonCount(state) {
    return state.lesson_count;
  },
  detailLesson(state) {
    return state.detailLesson;
  },
};

const actions = {
  [SAVE_UPLOAD_LESSON](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(SAVE_UPLOAD_LESSON, payload)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
    });
  },
  [SAVE_EXAM_RELATED](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(SAVE_EXAM_RELATED, payload)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
    });
  },
  [GET_LIST_LESSONS_RELATED](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.query(GET_LIST_LESSONS_RELATED, payload)
          .then(data => {
            if (data.status) {
              context.commit(SET_LESSON_RELATED, data);
            } else {
              context.commit(SET_ERROR, data.message);
            }
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
            context.commit(SET_ERROR, response.data.errors);
          });
    });
  },
  [GET_LIST_EXAM_RELATED](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.query(GET_LIST_EXAM_RELATED, payload)
          .then(data => {
            if (data.status) {
              context.commit(SET_EXAM_RELATED, data);
            } else {
              context.commit(SET_ERROR, data.message);
            }
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
            context.commit(SET_ERROR, response.data.errors);
          });
    });
  },
  [GET_LIST_LESSONS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.query(GET_LIST_LESSONS, payload)
        .then(data => {
          if (data.status) {
            context.commit(SET_LESSON, data);
          } else {
            context.commit(SET_ERROR, data.message);
          }
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [COUNT_LESSON](context, payload) {
    return new Promise(resolve => {
      ApiService.query(COUNT_LESSON, payload).then(data => {
        if (data.status) {
          context.commit(SET_COUNT_LESSON, data);
        }
        resolve(data);
      });
    });
  },
  [SEARCH_LESSON_BY_NAME](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.query(SEARCH_LESSON_BY_NAME, payload)
          .then(({ data }) => {
            context.commit(SET_NAME_LESSON, data);
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
    });
  },
  [ADD_CATEGORY_LESSON](context,payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(ADD_CATEGORY_LESSON, payload)
        .then(data => {
          if (data.status) {
            context.commit(SET_DETAIL_LESSON, data);
          } else {
            context.commit(SET_ERROR, data.message);
          }
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [DELETE_CATEGORY_LESSON](context,payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(DELETE_CATEGORY_LESSON, payload)
        .then(data => {
          if (data.status) {
            context.commit(SET_DETAIL_LESSON, data);
          } else {
            context.commit(SET_ERROR, data.message);
          }
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [DETAIL_LESSON](context,payload) {
    return new Promise((resolve, reject) => {
      ApiService.query(DETAIL_LESSON, payload)
        .then(data => {
          if (data.status) {
            context.commit(SET_DETAIL_LESSON, data);
          } else {
            context.commit(SET_ERROR, data.message);
          }
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [SAVE_LESSON](context,payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(SAVE_LESSON, payload)
        .then(data => {
          // if (data.status) {
          //   context.commit(SET_LESSON, data);
          // } else {
          //   context.commit(SET_ERROR, data.message);
          // }
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [UPDATE_LESSON](context,payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(UPDATE_LESSON, payload)
        .then( data => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [DELETE_LESSON](context, id) {
    return new Promise((resolve, reject) => {
      ApiService.delete(DELETE_LESSON, id)
        .then(data => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [DELETE_EXAM_RELATED](context, id) {
    return new Promise((resolve, reject) => {
      ApiService.delete(DELETE_EXAM_RELATED, id)
        .then(data => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_LESSON](state, data) {
    state.lessons = data.data;
    state.errors = {};
  },
  [SET_LESSON_RELATED](state, data) {
    state.lesson_relateds = data.data;
    state.errors = {};
  },
  [SET_EXAM_RELATED](state, data) {
    state.exam_relateds = data.data;
    state.errors = {};
  },
  [SET_DETAIL_LESSON](state, data) {
    state.detailLesson = data.data;
    state.errors = {};
  },
  [SET_COUNT_LESSON](state, data) {
    state.lesson_count = data.data;
  },
  [UPDATE_LESSON](state, data) {
    state.lessons.push(data.data);
    state.errors = {};
  },
  [SET_NAME_LESSON](state, data) {
    state.name_of_lessons = data;
    state.errors = {};
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
