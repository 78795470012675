<template>
  <v-container>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Tên môn học </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="name" rules="required" v-slot="{ errors }">
          <hnr-input :text="subject.name" v-model="subject.name"></hnr-input>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </v-col>
    </v-row>

    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Slug </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="slug" rules="required" v-slot="{ errors }">
          <hnr-input :text="subject.slug" v-model="subject.slug"></hnr-input>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </v-col>
    </v-row>

    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Kích hoạt </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="status" rules="required" v-slot="{ errors }">
          <v-switch v-model="subject.status"></v-switch>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>

      </v-col>
    </v-row>

    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Sắp xếp </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input
            :text="convertNumberToString(subject.order)"
            v-model="subject.order"
        ></hnr-input>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Banner ảnh </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <v-file-input
            class="pl-1"
            prepend-icon="mdi-camera"
            v-model="image"
            label="Tải ảnh"
            @change="onChangeFileUpload"
            @click:clear="clearImage"
        ></v-file-input>
        <div id="preview">
          <v-img :src="replace_image"></v-img>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HnrInput from "@/components/elements/hnr-input";
import {SAVE_IMAGE} from "@/store/image.module";

// import { mapState } from "vuex";
// import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
// import { SET_LOADING } from "@/store/loading.module";
// import {
//   GET_DETAIL_SUBJECT,
//   UPDATE_SUBJECT,
//   SAVE_SUBJECT,
// } from "@/store/subject.module";
// import { SUCCESS, ERROR } from "@/store/alert.module";
export default {
  props: {
    subject: Object,
  },
  components: {
    HnrInput,
  },
  data() {
    return {
      image: {},
      show: true,
    };
  },
  computed: {
    replace_image() {
      if (this.subject) {
        return process.env.VUE_APP_BASE_URL + "/" + this.subject.img;
      }
      return "";
    },
  },
  methods: {
    convertNumberToString(number) {
      if (number !== undefined) {
        return number.toString();
      }
      return "0";
    },
    onChangeFileUpload() {
      this.imageError = "";
      let formData = new FormData();
      formData.append("upload", this.image);
      this.$store.dispatch(SAVE_IMAGE, formData).then((data) => {
        if (data) {
          this.subject.img = data;
        }
      });
    },
    clearImage() {
      this.subject.img = "";
      this.show = false;
    },
  },
};
</script>