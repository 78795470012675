<template>
    <v-container class="ml-0 mr-0 pl-0 pr-0">
        <v-btn :color="color" :class="rounded ? 'rounded-pill' : 'rounded-lg'"
            v-on:click="onClick" elevation="0" v-text="text" :outlined="outlined" 
            :type="type" :name="name" :form="form" :width="width"></v-btn>
   </v-container>
</template>

<script>

export default {
     props: {
        type: {type: String, default: ''},
        name: {type: String, default: ''},
        form: {type: String, default: ''},
        color: {type: String, default: ''},
        text: {type: String, default: ''},
        width: {type: Number, default: ''},
        rounded: {type: Boolean, default: false},
        outlined: {type: Boolean, default: false},
    },
    data () {
        return {
           
        }
    },
    components: {
       
    },
    methods: {
        onClick() {
            this.$emit('hnrClickEvent');
        }
    }
}
</script>

<style lang="scss">
    .v-btn {
        width: 100%;
        text-transform:none !important;
    }
</style>
