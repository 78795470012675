<template>
  <v-container>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Từ khóa SEO tiếng anh</span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input :text="title" @input="update" v-model="detailAnswer.seo_key_en"></hnr-input>
      </v-col>
    </v-row>

    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Mô tả SEO tiếng anh</span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ck-content
            :content="detailAnswer.seo_content_en"
            @getData="updateContent"
            v-model="detailAnswer.seo_content_en"
        ></ck-content>
        <math-jax-editor
            :formula_prop="formula"
            @addFormula="addFormula"
        ></math-jax-editor>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Mô tả tiếng anh</span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ck-content
            :content="detailAnswer.content_en"
            @getData="updateContentEn"
            v-model="detailAnswer.content_en"
        ></ck-content>
        <math-jax-editor
            :formula_prop="formula"
            @addFormula="addFormulaEn"
        ></math-jax-editor>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HnrInput from '@/components/elements/hnr-input'
import HnrAreaInput from '@/components/elements/hnr-area-input'
import MathJaxEditor from "@/components/math/MathJaxEditor";
import CkContent from "@/components/ck-content";
export default {
  props: {
    answer: Object,
  },
  watch: {
    answer: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.detailAnswer = newVal;
      },
    },
  },
  data() {
    return {
      detailAnswer: {},
      formula:"",
      title:""
    };
  },
  mounted() {
    this.title = this.detailAnswer.seo_key_en.replace(/(<([^>]+)>)/g, "");
  },
  components: {
    HnrInput,
    HnrAreaInput,
    MathJaxEditor,
    CkContent
  },
  methods: {
    addFormula(value){
      this.detailAnswer.seo_content_en += value;
    },
    updateContent(val) {
      this.detailAnswer.seo_content_en = val;
    },
    addFormulaEn(value){
      this.detailAnswer.content_en += value;
    },
    updateContentEn(val) {
      this.detailAnswer.content_en = val;
    },
    onClick() {
    },
    update() {
      this.$emit("input");
    },
  },
};
</script>

<style lang="scss">
</style>
