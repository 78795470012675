<template>
  <v-container>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span>Tên tiếng anh</span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input :text="grade.name_en" @input="update" v-model="grade.name_en"></hnr-input>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span>Tiêu đề SEO tiếng anh</span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input :text="grade.seo_title_en" @input="update" v-model="grade.seo_title_en"></hnr-input>
      </v-col>
    </v-row>

    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span>Mô tả SEO tiếng anh</span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-area-input :text="grade.seo_description_en" @input="update" v-model="grade.seo_description_en"></hnr-area-input>
      </v-col>
    </v-row>

    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span>Từ khóa SEO tiếng anh</span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-area-input :text="grade.seo_key_en" @input="update" v-model="grade.seo_key_en"></hnr-area-input>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import HnrInput from '@/components/elements/hnr-input'
import HnrAreaInput from '@/components/elements/hnr-area-input'
export default {
  props: {
    grade: Object,
  },
  watch: {
    grade: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.grade = newVal;
      }
    }
  },
  components: {
    HnrInput,
    HnrAreaInput
  },
  methods: {
    onClick() {
    },
    update() {
      this.$emit("input");
    },
  },
};
</script>

<style lang="scss">
</style>
