<template>
  <v-container>
      <ValidationObserver v-slot="{ handleSubmit }">
    <v-tabs v-model="tabIndex">
      <v-tab
        v-for="item in items"
        :key="item.id"
        :disabled="isCreate && item.id == 3 ? true : false"
        >{{ item.tab }}</v-tab
      >
    </v-tabs>
    <v-tabs-items class="tab-content" v-model="tabIndex">
      <v-tab-item active>
        <grade-content
          class="tab-detail"
          :grade="detailGrade"
          :isNew="isCreate"
        ></grade-content>
      </v-tab-item>
      <v-tab-item>
        <grade-seo class="tab-detail" :grade="detailGrade"></grade-seo>
      </v-tab-item>
      <v-tab-item>
        <grade-seo-en class="tab-detail" :grade="detailGrade"></grade-seo-en>
      </v-tab-item>
      <v-tab-item>
        <v-row class="my-10" align="center" justify="space-around">
          <v-dialog v-model="dialog" max-width="1000px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn tile color="success" v-bind="attrs" v-on="on">
                <v-icon left> mdi-plus </v-icon>
                Thêm danh mục
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedTopic.name"
                        label="Tên chuyên mục"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select
                        v-model="editedTopic.subject_id"
                        :items="subjects_item"
                        label="Chọn môn học"
                        solo
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="saveTopic">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline"
              >Bạn có chắc muốn xóa không?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Thoát</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Xóa</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-data-table
          :headers="headers"
          :items="topics"
          item-key="id"
          class="elevation-1"
          single-select
          hide-default-footer
          disable-pagination
        >
          <template v-slot:item.is_show="{ item }">
            <v-checkbox
              v-model="item.is_show"
              @change="updateTopic(item)"
            ></v-checkbox>
          </template>
          <template v-slot:item.name="{ item }">
            <span @click="editItem(item)" class="cursor">{{item.name}}</span>
          </template>
          <template v-slot:item.active_menu="{ item }">
            <v-switch v-model="item.active_menu" @change="changeActive(item)"></v-switch>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small @click="deleteTopic(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
    <v-row v-if="isCreate" class="align-center justify-center ma-0 pt-5">
      <v-btn color="primary" width="130" @click="handleSubmit(saveGrade)">Tạo môn học</v-btn>
    </v-row>
    <v-row v-else class="align-center justify-center ma-0 pt-5">
      <v-btn color="primary" width="130" @click="handleSubmit(updateGrade)">Cập nhật môn học</v-btn>
    </v-row>
      </ValidationObserver>
  </v-container>
</template>

<script>
import GradeContent from "@/components/grade/GradeContent.vue";
import GradeSeo from "@/components/grade/GradeSeo.vue";
import GradeSeoEn from "@/components/grade/GradeSeoEn.vue";

import { mapGetters } from "vuex";

import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { SET_LOADING } from "@/store/loading.module";
import {
  GET_DETAIL_GRADE,
  UPDATE_GRADE,
  SAVE_GRADE,
  SET_DEFAULT_GRADE
} from "@/store/grade.module";
import { GET_LIST_SUBJECTS } from "@/store/subject.module";
import {
  GET_BY_GRADE,
  UPDATE_TOPIC,
  SAVE_TOPIC,
  DELETE_TOPIC,
  UPDATE_TOPIC_ACTIVE_MENU
} from "@/store/topic.module";
import { SUCCESS, ERROR } from "@/store/alert.module";

export default {
  data() {
    return {
      slug: "",
      isCreate: true,
      tabIndex: 0,
      grade: {},
      items: [
        { id: 0, tab: "Thông tin chủ đề" },
        { id: 1, tab: "Cấu hình SEO" },
        { id: 2, tab: "Cấu hình SEO tiếng anh" },
        { id: 3, tab: "Danh sách chuyên mục" },
      ],
      headers: [
        {
          text: "id",
          value: "id",
        },
        { text: "Tên Chuyên mục", value: "name" },
        { text: "Hiện thị bài học con", value: "is_show" },
        { text: "Hiện thị ở menu", value: "active_menu" },
        { text: "sắp xếp", value: "order" },
        { text: "Xóa", value: "actions" },
      ],
      dialog: false,
      dialogDelete: false,
      formTitle: "Thêm chuyên mục",
      editedTopic: {
        name: "",
        grade_id: null,
        subject_id: null,
        status: 1,
        is_show: 1,
      },
      editedIndex: -1,
      editedId: -1,
    };
  },
  components: {
    GradeContent,
    GradeSeo,
    GradeSeoEn,
  },
  computed: {
    ...mapGetters({
      topics: "topicListByGrade",
      detailGrade: "detailGrade",
      subjects: "listSubjects",
    }),
    grades_item() {
      let new_item = [];
      this.grades.forEach((el) => {
        new_item.push({
          text: el.name,
          value: el.id,
        });
      });
      return new_item;
    },
    subjects_item() {
      let new_item = [];
      this.subjects.forEach((el) => {
        new_item.push({
          text: el.name,
          value: el.id,
        });
      });
      return new_item;
    },
  },
  watch: {
    detailGrade: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.grade = newVal;
      },
    },
  },
  created() {
    this.slug = this.$route.query.slug;
  },
  async mounted() {
     await this.$store.dispatch(SET_DEFAULT_GRADE);
    await this.$store
      .dispatch(GET_LIST_SUBJECTS, {})
      .then(() => {
        this.$store.dispatch(SET_LOADING, false);
      })
      .catch(() => {
        this.$store.dispatch(SET_LOADING, false);
      });
    if (this.slug) {
      this.isCreate = false;

      await this.$store.dispatch(SET_BREADCRUMB, [{ title: "Sửa lớp" }]);

      await this.$store.dispatch(SET_LOADING, true);
      await this.$store
        .dispatch(GET_DETAIL_GRADE, this.slug)
        .then(() => {
          this.$store.dispatch(SET_LOADING, false);
        })
        .catch(() => {
          this.$store.dispatch(SET_LOADING, false);
        });
      if (!this.detailGrade) {
        return null;
      }
      await this.$store
        .dispatch(GET_BY_GRADE, { grade_id: this.detailGrade.id })
        .then(() => {
          this.$store.dispatch(SET_LOADING, false);
        })
        .catch(() => {
          this.$store.dispatch(SET_LOADING, false);
        });
    } else {
      this.isCreate = true;
      await this.$store.dispatch(SET_BREADCRUMB, [{ title: "Thêm Lớp" }]);
    }
  },
  methods: {
    updateGrade() {
      if (!this.grade) {
        return null;
      }
      this.$store.dispatch(UPDATE_GRADE, this.grade).then((data) => {
        if (data.status) {
          this.$store.dispatch(SUCCESS, "Cập nhật lớp học thành công!", {
            root: true,
          });
          this.$router.push('/grade/list');
        } else {
          if(data.errorCode == 424){
            this.$store.dispatch(ERROR, data.message, {
              root: true,
            });
          }else {
            this.$store.dispatch(ERROR, "Cập nhật lớp học thất bại!", {
              root: true,
            });
          }
        }
      });
    },
    saveGrade() {
      this.$store.dispatch(SET_LOADING, true);
      this.$store
        .dispatch(SAVE_GRADE, this.grade)
        .then((data) => {
          if (data.status) {
            this.$store.dispatch(SUCCESS, "Thêm môn học thành công!", {
              root: true,
            });
            this.$router.push('/grade/list');
          } else {
            this.$store.dispatch(ERROR, "Thêm môn học thất bại!", {
              root: true,
            });
          }
          this.$store.dispatch(SET_LOADING, false);
        })
        .catch(() => {
          this.$store.dispatch(ERROR, "Thêm môn học thất bại!", { root: true });
          this.$store.dispatch(SET_LOADING, false);
        });
    },
    saveTopic() {
      this.editedTopic.grade_id = this.detailGrade ? this.detailGrade.id : null;
      this.$store.dispatch(SAVE_TOPIC, this.editedTopic).then((data) => {
        if (data.status) {
          this.close();
          this.$store.dispatch(SUCCESS, "Tạo mới chuyên mục thành công!", {
            root: true,
          });
          this.topics.push(data.data);
        } else {
          this.$store.dispatch(ERROR, "Tạo mới chuyên mục thất bại!", {
            root: true,
          });
        }
      });
    },
    updateTopic(topic) {
      let payload = {
        id: topic.id,
        is_show: topic.is_show,
      };
      this.$store.dispatch(UPDATE_TOPIC, payload).then((data) => {
        if (data.status) {
          this.$store.dispatch(SUCCESS, "Cập nhật chuyên mục thành công!", {
            root: true,
          });
        } else {
          this.$store.dispatch(ERROR, "Thêm môn học thất bại!", {
            root: true,
          });
        }
      });
    },
    close() {
      this.dialog = false;
    },
    deleteTopic(item) {
      this.editedIndex = this.topics.indexOf(item);
      this.editedId = item.id;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.$store.dispatch(DELETE_TOPIC, this.editedId).then((data) => {
        if (data.status) {
          this.topics.splice(this.editedIndex, 1);
        } else {
          this.$store.dispatch(ERROR, "Xóa thất bại!", {
            root: true,
          });
        }
      });

      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },
    changeActive(item){
      this.$store.dispatch(SET_LOADING, true);
      this.$store.dispatch(UPDATE_TOPIC_ACTIVE_MENU, item).then(() => {
        this.$store.dispatch(SET_LOADING, false);
        this.$toasted.success('Chuyển trạng thái thành công',{
          position: "top-right",
          duration : 3000
        })
      }).catch(() => {
        this.$store.dispatch(SET_LOADING, false);
      });
    },
    editItem(item) {
      let routeData = this.$router.resolve({
        name: "category-detail",
        params: {slug: item.slug},
      });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>

<style lang="scss">
.tab-detail {
  margin-top: 15px;
}
</style>
