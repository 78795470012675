<template>
  <div data-app>
    <v-row class="align-center justify-center">
      <v-col cols="12" class="">
        <v-row class="ma-0 pt-3">
          <v-col cols="9" class="">
            <v-radio-group
                v-model="index"
                row
            >
              <v-radio
                  label="Chưa index"
                  value="0"
              ></v-radio>
              <v-radio
                  label="Đã index"
                  value="1"
              ></v-radio>
              <v-radio
                  label="Tất cả"
                  value="2"
                  checked
              ></v-radio>
            </v-radio-group>
            <v-btn color="primary" @click="search">Tìm kiếm</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <template>
      <v-data-table
          v-model="selected"
          :headers="headers"
          :items="followAction"
          class="elevation-1"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          item-key="id"
          show-select
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-card-title class="text-h5 pa-0">
              Danh sách theo dõi
              <v-spacer></v-spacer>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                dark
                class="mb-2"
                @click="update"
            >
             Cập nhật index
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.name="{ item }">
          <span v-if="item.lesson"> {{item.lesson.name}}</span>
        </template>
        <template v-slot:item.slug="{ item }">
          <span v-if="item.lesson"> {{item.lesson.slug}}</span>
        </template>
        <template v-slot:item.open_in_new="{ item }">
          <v-icon small class="mr-2" @click="openInNew(item.lesson)">
            mdi-open-in-new
          </v-icon>
        </template>
        <template v-slot:item.index="{ item }">
          <v-btn v-if="item.index == 2" color="grey">
            Chưa index
          </v-btn>
          <v-btn v-if="item.index == 1" color="primary">
            Đã index
          </v-btn>
        </template>
<!--        <template v-slot:item.actions="{ item }">-->
<!--          <v-icon small @click="deleteItem(item)">-->
<!--            mdi-delete-->
<!--          </v-icon>-->
<!--        </template>-->
        <template v-slot:no-results>
          <v-alert :value="true" color="error" icon="warning">
            Your search found no results.
          </v-alert>
        </template>
      </v-data-table>
    </template>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { validationMixin } from "vuelidate";
import { SET_LOADING } from "@/store/loading.module";
import { GET_LIST_FOLLOW_ACTION } from "@/store/follow-action.module";
import { GOOGLE_SEARCH_INDEXING } from "@/store/google.module";
import HnrInput from "@/components/elements/hnr-input";
import {SUCCESS, ERROR} from "@/store/alert.module";

export default {
  mixins: [validationMixin],
  components: {
    HnrInput
  },
  data: () => ({
    page: 1,
    itemsPerPage: 10,
    selected:[],
    idSelected:[],
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id"
      },
      {text: "Tên Bài học", value: "name"},
      {text: "Slug", value: "slug"},
      {text: "", value: "open_in_new"},
      {text: "Index", value: "index"},
      // {text: "Xóa", value: "actions"},
    ],
    total: 0,
    index : null
  }),

  computed: {
    ...mapGetters({
      followAction: 'listFollow'
    }),
  },
  watch: {
    selected: {
      deep: true,
      immediate: true,
      handler(newVal) {
        console.log(newVal)
      }

    }
  },
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Theo dõi hoạt động", route: "follow-action"}]);
    this.search();
    
  },
  methods: {
    update() {
      if (this.selected && this.selected.length > 0) { 
        let lessonIds = []
        this.selected.forEach(lesson => {
          lessonIds.push(lesson.lesson_id)
        })
        this.$store.dispatch(GOOGLE_SEARCH_INDEXING, { ids: lessonIds });
      }
    },
    search() {
      let payload = {
        index: this.index ?? 2,
      }
      this.$store.dispatch(SET_LOADING, true);
      this.$store.dispatch(GET_LIST_FOLLOW_ACTION, payload).then(() => {
        this.$store.dispatch(SET_LOADING, false);
      }).catch(() => {
        this.$store.dispatch(SET_LOADING, false);
      });
    },
    openInNew(lesson) {
      if (lesson) {
        window.open(process.env.VUE_APP_FRONT_URL + "/" + lesson.slug, '_blank')
      }
    }
  }
};
</script>
