<template>
  <v-container>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Tiêu đề tiếng anh </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input :text="detailLesson.name_en" @input="update" v-model="detailLesson.name_en"></hnr-input>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Slug SEO Tiếng anh </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input :text="detailLesson.seo_slug_en" @input="update" v-model="detailLesson.seo_slug_en"></hnr-input>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Từ khóa SEO Tiếng anh </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input :text="detailLesson.seo_key_en" @input="update" v-model="detailLesson.seo_key_en"></hnr-input>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Tiêu đề SEO Tiếng anh </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input :text="detailLesson.seo_title_en" @input="update" v-model="detailLesson.seo_title_en"></hnr-input>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Nội dung SEO Tiếng anh </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-area-input :text="detailLesson.seo_description_en" @input="update" v-model="detailLesson.seo_description_en"></hnr-area-input>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Nội dung tiếng anh</span>
      </v-col>
      <v-col cols="10" class="pa-1 mb-5">
          <ck-content
              id="lesson_content"
              :content="detailLesson.content_en"
              @getData="updateContent"
              v-model="detailLesson.content_en"
          ></ck-content>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HnrInput from '@/components/elements/hnr-input';
import HnrAreaInput from '@/components/elements/hnr-area-input';
import CkContent from "@/components/ck-content";
export default {
  props: {
    lesson: Object,
  },
  watch: {
    lesson: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.detailLesson = newVal;
      }
    }
  },
  data() {
    return {
      detailLesson: {}
    };
  },
  components: {
    HnrInput,
    HnrAreaInput,
    CkContent
  },
  methods: {
    updateContent(val) {
      this.detailLesson.content_en = val;
    },
    onClick() {
    },
    update() {
      this.$emit("input");
    },
  },
};
</script>

<style lang="scss">
</style>
