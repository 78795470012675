<template>
  <div data-app>
    <template>
      <v-data-table
          :headers="headers"
          :items="books"
          class="elevation-1"
          :footer-props="{ itemsPerPageOptions: [5, 10, 15, 20] }"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-card-title class="text-h5 pa-0">
              Danh sách Sách
              <v-spacer></v-spacer>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-btn color="primary" width="100" dark class="mb-2" @click="addNewItem"
            >Thêm mới</v-btn
            >
          </v-toolbar>
        </template>
        <template v-slot:item.thumbnail="{ item }">
          <img :src="item.thumbnail" style="width: 100px"/>
        </template>
        <template v-slot:item.type="{ item }">
         <v-checkbox v-model="item.type" disabled></v-checkbox>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </template>
      </v-data-table>
      <!-- DIALOG DELETE  -->
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline"
          >Bạn có chắc muốn xóa không?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn width="70" color="blue darken-1" text @click="closeDelete"
            >Thoát</v-btn
            >
            <v-btn width="70" color="blue darken-1" text @click="deleteItemConfirm"
            >Xóa</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { SET_LOADING } from "@/store/loading.module";
import { GET_LIST_BOOK,DELETE_BOOK } from "@/store/book.module";
import { ERROR } from "@/store/alert.module";


export default {
  data: () => ({
    headers: [
      {
        text: "id",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Tên sách", value: "title" },
      { text: "Ảnh", value: "thumbnail" },
      { text: "Giá VN", value: "price" },
      { text: "Giá KR", value: "price_kr" },
      { text: "Combo", value: "type" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedId: -1,
    dialogDelete: false,
  }),

  computed: {
    ...mapGetters({
      books: "listBooks",
    }),
  },
  mounted() {
    this.getBooks();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Sách", route: "/list-book" },
    ]);
  },
  methods: {
    getBooks() {
      this.$store.dispatch(SET_LOADING, true);
      this.$store
          .dispatch(GET_LIST_BOOK)
          .then(() => {
            this.$store.dispatch(SET_LOADING, false);
          })
          .catch(() => {
            this.$store.dispatch(SET_LOADING, false);
          });
    },
    addNewItem() {
      this.$router.push({ name: "add-book" });
    },

    editItem(item) {
      this.$router.push({ name: "edit-book", params: { id: item.id }});
    },

    deleteItem(item) {
      this.editedIndex = this.books.indexOf(item);
      this.editedId = item.id;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.$store.dispatch(DELETE_BOOK, this.editedId).then((data) => {
        if (data.status) {
          this.$store.dispatch(GET_LIST_BOOK)
        } else {
          if(data.errorCode == 424){
            this.$store.dispatch(ERROR, data.message, {root: true});
          } else{
            this.$store.dispatch(ERROR, "Xóa thất bại!", {
              root: true,
            });
          }
        }
      });

      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },
  },
};
</script>
<style>
#my-strictly-unique-vue-upload-multiple-image {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
